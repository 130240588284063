const initialState ={
    list:null,
    tabs:[
        {
              title:'关注',
              isActive:true,
              id:'1'
        },
        {
              title:'动态',
              isActive:false,
              id:'2'
        },
    ],
    currentTab:1,
    totalCount:0,
    loading:false,
    status:{scrollTop:0,currentTab:0}
}
export default function(state=initialState,action){
    switch(action.type){
        case 'ACTIVITY_LIST_RELOAD_END':
            return Object.assign({},state,{loading:false});

        case 'ACTIVITY_LIST_RELOAD_START':
            return Object.assign({},state,{loading:true,list:[]});

        case 'ACTIVITY_SCROLL_POSITION':
            state.status.scrollTop=action.payload;
            return state;

        case 'ACTIVITY_LIST_RESET':
            return Object.assign({},state,{list:[]});
        case 'ACTIVITY_LIST_LOAD':
            // console.log('%c REDUX ACTIVITY_LOAD:%o','color:blue',action);
            state.status.scrollTop=0;
            return Object.assign({},state,{list:action.payload.list,totalCount:action.payload.totalCount});

        case 'ACTIVITY_SET_TAB':
            return Object.assign({},state,{currentTab:action.payload,list:null});

        case 'ACTIVITY_LOAD_MORE':
            // console.log('%c REDUX ACTIVITY_LOAD_MORE:%o','color:blue',action);
            return Object.assign({},state,{list:[...state.list,...action.payload]});
        case 'ACTIVITY_OTO_TAB':
            if(action.payload)
                return Object.assign({},state,{tabs:[...initialState.tabs,...action.payload]});
            else 
                return state;
        default:
            return state;
    }
}