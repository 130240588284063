import AgoraRTC from 'agora-rtc-sdk-ng';
import {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Navigation from '../../components/navigation/navigation';
import { CONST_TOP_ADJUST_HEIGHT } from '../../service/config';
import "./test.css";

window.futrue=window.futrue||{};


const TestPageRaw = (props)=>{
    const [xiInstallParams,setXiInstallParams] = useState(null);
    const [userCaptured,setUserCaptured] = useState(null);
    const [deviceId,setDeviceId] = useState(null);
    const [userDidTakeScreenShot,setUserDidTakeScreenShot] = useState(null);
    const [userDidChangeCaptured,setUserDidChangeCaptured] = useState(false);
    const [geoLocation,setGeoLocation]  = useState(null);
    const [locationAuthorization,setLocationAuthorization] = useState('');
    const [screenHeight] =useState(window.screen.height);
    const [screenAvailHeight] = useState(window.screen.availHeight);
    useEffect(()=>{
        window.futrue.onUserTakeScreenShotCallback = (ret)=>{
            setUserDidTakeScreenShot(ret);
        }
        window.futrue.onUserChangeCapture=(ret)=>{
            setUserDidChangeCaptured(ret);
        }
    }
    ,[setUserDidChangeCaptured,setUserDidTakeScreenShot]
    );

    return(
        <div className="fx-box fx-1 fx-fd-c font-size13 test-page">
            <Navigation title='测试' style={{top:CONST_TOP_ADJUST_HEIGHT,padding:20}}/>
            <div className='fx-box fx-1 fx-fd-c test'>
            <div style={{marginTop:80}} onClick = {()=>{
                props.dispatch({type:'show_recharge',payload:{nickName:'iiii',amount:1435}})
            }}>
                Test Recharge Pag 
            </div>
            <div onClick={()=>{
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('openURL:https://www.sina.com.cn');
            }}>
                Test openURL
            </div>
            <div onClick={()=>{
                window.futrue.getDeviceIdCallback=(ret)=>{
                    setDeviceId(ret)
                };
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getDeviceID');
            }}>
                Test getDeviceID:{deviceId}
            </div>
            <div onClick={()=>{
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('xiReportRegister');
            }}>
                *Test xi Report Register
            </div>
            <div onClick={()=>{
                if(window?.webkit?.messageHandlers?.callbackHandler){
                    window.webkit.messageHandlers.callbackHandler.xiGetInstallParamsCallback=(ret)=>{
                        setXiInstallParams(ret)
                        window?.webkit?.messageHandlers?.callbackHandler?.postMessage('xiGetInstallParamsgotresult:'+ret);    
                    };
                }
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('xiGetInstallParams');
            }}>
                *Test xi GetInstallParams: {xiInstallParams}
            </div>
            <div >
                *Test userDidTakeScreenShot:{userDidTakeScreenShot}
            </div>
            <div>
                *Test userDidChangeCaptured:{userDidChangeCaptured}
            </div>
            <div onClick={()=>{
                    window.futrue.getUserCapturedCallback=(ret)=>{
                        setUserCaptured(ret);
                    };
                    window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getUserCaptured');
            }}>
                *Test getUserCaptured:{userCaptured}
            </div>
            <div onClick={()=>{
                // window.screen.availWidth 返回当前屏幕宽度(空白空间)
                // window.screen.availHeight 返回当前屏幕高度(空白空间)
                // window.screen.width 返回当前屏幕宽度(分辨率值)
                // window.screen.height 返回当前屏幕高度(分辨率值)
                // window.document.body.offsetHeight 返回当前网页高度
                // window.document.body.offsetWidth 返回当前网页宽度
                console.log(window.screen.availHeight,window.screen.availWidth,window.screen.height,window.screen.width)
                console.log('document:',window.document.body.offsetHeight,window.document.body.offsetWidth);
            }}>
                Test size
            </div>
            <div onClick={()=>{
                    window.futrue.getLocationCallback=(ret)=>{
                        console.log(ret);
                        setGeoLocation(JSON.stringify(ret));
                    };
                    window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocation');
            }}>
                *Test getLocation:{geoLocation}
            </div>
            <div onClick={()=>{
                    window.futrue.getLocationAuthorizationCallback=(ret)=>{
                        console.log(ret);
                        setLocationAuthorization(ret);
                    };
                    window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocationAuthorization');
            }}>
                *Test getLocationAuthorization:{locationAuthorization}
            </div>
            <div onClick={()=>{

                    window.futrue.openLocationAuthCallback=(ret)=>{
                        console.log(ret);
                        // setLocationAuthorization(ret);
                    };
                    window?.webkit?.messageHandlers?.callbackHandler?.postMessage('requestLocationPermission');
                    
            }}>
                *Test requestLocationPermission
            </div>

            <div onClick={()=>{
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('testEvalJS');
            }}>
                *Test evalJS
            </div>
            <div onClick={()=>{
                console.log('click me?');
                console.log('window.webkit:',window.webkit);
                console.log('window.webkit.messageHandlers:',window.webkit.messageHandlers);
                console.log('window.webkit.messageHandlers.callbackHandler:',window.webkit.messageHandlers.callbackHandler);
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('Hello from fuh5 test');
            }}>
                *Test postMessage
            </div>

            <div onClick={()=>{
                window.futrue.getSystemInfoHandler=(obj)=>{
                    console.log(obj);
                }
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getSystemInfo');
            }}>
                *Test getSystemInfo
            </div>

            <div onClick={()=>{
            }}>
                *Test getScreenSize:{screenHeight}--{screenAvailHeight}
            </div>


            <div onClick={()=>{
                AgoraRTC.getPlaybackDevices().then(data=>{
                    console.log('\n-------------------[DEBUG]-------------------\n');
                    console.log('playbackdevices:',data);
                })
            
            }}>
                Test Agora Audio Device....
            </div>
            </div>
        </div>
    )
}

export const TestPage = connect(null,(dispatch)=>{return {dispatch}})(TestPageRaw);

