import React, { useEffect ,useState,useRef} from 'react';
import {Image, Toast} from 'react-vant';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import './AccountList.css';
const AccountList=()=>{
    
    const listRef = useRef(null);
    const [blist,setList] = useState(null);
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        let timer;
        const list = listRef.current;
        const handleScroll = ev => {
        
            const scrollTop = document.getElementById('account-page').scrollTop;
            const screenHeight = window.screen.height;
            const offsetHeight = document.getElementById('account-list').offsetHeight;
            
            // console.log(scrollTop,screenHeight,offsetHeight,'blist:',blist?.length);

            if (scrollTop >= (offsetHeight - screenHeight * 1.5)) {
                clearTimeout(timer);

                timer = setTimeout(() => {   
                    
                    console.log('....loading ...:',blist.length);
                    service.accountDetail(blist.length).then(data=>{
                        if(data.budgetList?.length<1){
                            setLoading(false);
                        }
                        setList([...blist,...data.budgetList]);
                    }).finally(()=>{
                        
                    });
                
                }, 10);
            }
        };

        if(!blist){
            service.accountDetail(0).then(data=>{
                console.log('===>',data)
                if(data.code==0)
                setList(data.budgetList);
            })         
        }


        list.addEventListener('touchmove', handleScroll);
        list.addEventListener('wheel', handleScroll);

        return () => {

            clearTimeout(timer);
            list.removeEventListener('touchmove', handleScroll);
            list.removeEventListener('wheel', handleScroll);
        };

    },[setList,blist])
      return (
        <div className="account-list-page hide-x" id="account-page"  ref={listRef}>
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'收支明细'}></Navigation>
           <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt60' id='account-list'>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='blackItem fx-box fx-jc-sb fx-ai-c'>
                        <div className='fx-box'>
                           <Image width={'0.41rem'} height={'0.41rem'} round src={item.headUrl}/>
  
                           <div className='black-userid fx-box fx-fd-c  fx-jc-sb'>
                              <div className='font-size15'>{item.nickName}</div>
                              <div className='font-size12'>ID:{item.userId}</div>
                           </div>
                        </div>
  
                        <div className='font-color3 font-size13'>
                            <div className='font-color9'>{item.budgetNum}</div>
                            <div className='font-color9'>{item.consumeTypeDesc}</div>
                        </div>
                    </div>)
                    })
                 }
                 {
                    loading?<div className='font-size13 font-color7 fx-box fx-ai-c fx-jc-c'>加载中</div>:
                    <div className='font-size13 font-color7 fx-box fx-ai-c fx-jc-c'>到底了</div>
                 }
           </div>
          
        </div>
      );
}

export default AccountList;