import { useEffect, useState } from 'react';
import Navigation from '../../components/navigation/navigation';
import './auth.css';

const AuthStatus = (props)=>{
    if(props.state){
        return <div className='auth-status'>
            <img src={require('../../assets/images/auth/state-active.png')}/>
        </div>
    }
    else return <div  className='auth-status'>
        <img src={require('../../assets/images/auth/state-inactive.png')}/>   
    </div>
}


export const AuthManager = (props)=>{
    const [ isOpenLocation, setIsOpenLocation ] = useState(false);
    const [ isOpenCamera, setIsOpenCamera ] = useState(false);
    const [ isOpenNotification,setIsOpenNotification]  = useState(false);
    const [ isOpenPhotos, setIsOpenPhotos] = useState(false);
    const [ isOpenMicrophone,setIsOpenMicrophone] = useState(false);
    const [ isOpenMedia,setIsOpenMedia]  = useState(false);

    useEffect(()=>{
        console.log('\n\n\n[DEBUG]check handler:',window?.webkit?.messageHandlers?.callbackHandler);
        window.fucircle.checkOpenNotificationHandler=(b)=>{
            console.log('isOpenNotification:',b);
            setIsOpenNotification(b);
        }
        window?.ReactNativeWebView?.postMessage('checkOpenNotification');

        // console.log('\n\n\n[DEBUG]check open photos',window?.webkit?.messageHandlers?.callbackHandler);
        window.fucircle.checkOpenPhotosHandler=(b)=>{
            console.log('isOpenPhotos:',b);
            setIsOpenPhotos(b);

        }
        window?.ReactNativeWebView?.postMessage('checkOpenPhotos');

        // console.log('\n\n\n[DEBUG]check open camera',window?.webkit?.messageHandlers?.callbackHandler);
        window.fucircle.checkOpenCameraHandler=(b)=>{
            console.log('isOpenCamera:',b);
            setIsOpenCamera(b);
        }
        window?.ReactNativeWebView?.postMessage('checkOpenCamera');

        // console.log('\n\n\n[DEBUG]check open microphone',window?.webkit?.messageHandlers?.callbackHandler);
        window.fucircle.checkOpenAudioHandler=(b)=>{
            console.log('isOpenMicrophone:',b);
            setIsOpenMicrophone(b);
        }
        window?.ReactNativeWebView?.postMessage('checkOpenAudio');

        // console.log('\n\n\n[DEBUG]check open Location',window?.webkit?.messageHandlers?.callbackHandler);
        window.fucircle.checkOpenLocationHandler=(b)=>{
            console.log('isOpenLocation:',b);
            setIsOpenLocation(b);
        }
        window?.ReactNativeWebView?.postMessage('checkOpenLocation');
    })

    return (
        <div className="auth-page fx-box fx-fd-c" style={{paddingTop:'0.5rem'}}>
            <Navigation title='权限管理' style={{backgroundColor:'transparent'}}/>
            <img className='auth-head' src={require('../../assets/images/auth-head.png')}/>
            <div className='auth-list font-size15 fx-1'>
                <div>
                一键授权，玩转Fu
                </div>
                <div className='auth-title2'>
                使用本平台需要您授权开启以下权限
                </div>
                <div className='auth-row fx-box'  onClick={()=>{
                    window.fucircle.openNotificationHandler = (isOpen)=>{
                        setIsOpenNotification(!!isOpen);
                    }
                    window?.ReactNativeWebView?.postMessage('openNotification');
                }}>
                    <div className='auth-icon'>
                        <img src={require('../../assets/images/auth/icon-notification.png')}/>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c'>
                        <div>开启消息通知</div>
                        <div className='auth-desc-2'>及时获得精彩内容更新提示</div>
                    </div>
                    
                    <AuthStatus state = {isOpenNotification}/>
                    
                </div>
                <div className='auth-row fx-box' onClick={()=>{
                    window.fucircle.openPhotosHandler = (isOpen)=>{
                        setIsOpenPhotos(!!isOpen);
                    }
                    window?.ReactNativeWebView?.postMessage('openPhotos');
                }}>
                    <div className='auth-icon'>
                        <img src={require('../../assets/images/auth/icon-photo.png')}/>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c'>
                        <div>开启相册权限</div>
                        <div className='auth-desc-2'>访问私密照片、写真视频</div>
                    </div>
                    <AuthStatus state={isOpenPhotos}/>
                </div>
                
                <div className='auth-row fx-box' onClick={()=>{
                    window.fucircle.openCameraHandler = (isOpen)=>{
                        setIsOpenCamera(!!isOpen);
                    }
                    window?.ReactNativeWebView?.postMessage('openCamera');
                }}>
                    <div className='auth-icon'>
                        <img src={require('../../assets/images/auth/icon-camera.png')}/>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c'>
                        <div>开启相机权限</div>
                        <div className='auth-desc-2'>与女神1v1私密视频必备</div>
                    </div>
                    <AuthStatus state={isOpenCamera}/>
                </div>
                <div className='auth-row fx-box'  onClick={()=>{
                    window.fucircle.openAudioHandler = (isOpen)=>{
                        setIsOpenMicrophone(!!isOpen);
                    }
                    window?.ReactNativeWebView?.postMessage('openAudio');
                }}>
                    <div className='auth-icon'>
                        <img src={require('../../assets/images/auth/icon-audio.png')}/>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c'>
                        <div>开启麦克风权限</div>
                        <div className='auth-desc-2'>与女神1v1私密视频必备</div>
                    </div>
                    <AuthStatus state={isOpenMicrophone}/>
                </div>
                <div className='auth-row fx-box' onClick={()=>{
                    window.fucircle.openLocationHandler = (isOpen)=>{
                        setIsOpenLocation(!!isOpen);
                    }
                    window?.ReactNativeWebView?.postMessage('openLocation');
                }}>
                    <div className='auth-icon'>
                        <img src={require('../../assets/images/auth/icon-location.png')}/>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c'>
                        <div>开启地理位置</div>
                        <div className='auth-desc-2'>应用于附近交友，个人位置信息等功能</div>
                    </div>
                    <AuthStatus state={isOpenLocation}/>
                </div>
                
                
            </div>
        </div>
    )
}