import React, {useState,useEffect} from 'react';
import Navigation from "../../components/navigation/navigation";
import { Image} from 'react-vant';
import { useNavigate } from 'react-router-dom'
import './Wallet.css';
import * as service from '../../service';
import { CONST_TOP_ADJUST_HEIGHT } from '../../service/config';

const Wallet=()=>{
    const navigate=useNavigate();
      let theme={
           color:'#FFF',
           bg:'linear-gradient(138.47deg, #FF6161 -2.56%, #FD8F8F 76.52%)'
      }
      let wallets=[
          {
            icon:'/static/images/wallet-icon01.png',
            w:'0.24rem',h:'0.24rem',
            title:'充值',
            path:'/pay'
          },
          {
            icon:'/static/images/wallet-icon02.png',
            w:'0.24rem',h:'0.24rem',
            title:'收支明细',
            path:'/flows'
          },
          {
            icon:'/static/images/wallet-icon03.png',
            w:'0.24rem',h:'0.22rem',
            title:'提现',
            path:'/cashout'
          }
      ]
      const [myInfo,setMyInfo] = useState();
        useEffect(()=>{
            service.queryMyselfInfo().then(data=>{
                if(data.code!=0){
                    navigate('/login');
                }
            setMyInfo(data);
        });
      },[setMyInfo])
      return (
      <div className="wallet-page full-r-w theme-font" style={{paddingTop:CONST_TOP_ADJUST_HEIGHT}}>
        <Navigation style={{top:CONST_TOP_ADJUST_HEIGHT}} theme={theme} height={'0.5rem'} title={'钱包'}></Navigation>
        <div className="full-r-w wallet-header fx-box fx-jc-c">
            <div className="wallet-header-cxt fx-box full-r-w fx-ai-c">
                <div className="wallet-item font-w5  font-color3 fx-1 fx-box fx-fd-c fx-ai-c">
                      <div className="font-size26">{myInfo&&myInfo.userInfo.wealthXz}</div>
                      <div className="font-size20">金币</div>
                </div>
                <div className="wallet-line"></div>
                <div className="wallet-item font-w5  font-color3 fx-1 fx-box fx-fd-c fx-ai-c">
                     <div className="font-size26">{myInfo&&myInfo.userInfo.wealthXp}</div>
                      <div className="font-size20">钻石</div>
                </div>
            </div>
             
        </div>
        <div className="full-r-w">
             <div className="wallet-menu fx-box fx-fd-c fx-ai-c">
                {
                    wallets.map((item,index)=>{
                        return (<div onClick={()=>{
                            navigate(item.path)
                        }} key={'wallet-m-id-'+(index+1)} className="wallet-menu-item fx-box fx-jc-sb fx-ai-c">
                        <div className="fx-box fx-ai-c">
                          <Image width={item.w} height={item.h} src={item.icon}/>
                          <span className="font-size15">{item.title}</span>
                        </div>
                        <i className="iconfont font-w5 font-size17 icon-weibiaoti--"></i>
                    </div>)
                    })
                }
             </div>
        </div>
      </div>)
}
export default Wallet;