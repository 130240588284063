import { useEffect, useState } from "react";
import { Dialog, Popup, Toast } from "react-vant";
import Navigation from "../../components/navigation/navigation";
import { DefaultHeadUrl } from "../../service/images";
import './share.css';
import html2canvas from 'html2canvas';
import { useNavigate } from "react-router-dom";
import * as service from '../../service';

function createBlob(data) {
    return new Blob([data], { type: "text/plain" });
  }
console.log(DefaultHeadUrl);

const Share=()=>{
    const navigate = useNavigate();
    const a = document.createElement("a");
    const [showDialog,setShowDialog]=useState();
    const [ profile,setProfile]  = useState();
    const [shareInfo,setShareInfo]  = useState();
    const [shareStat,setShareStat] = useState();
    const [inviteData,setInviteData] = useState();
    const [qrCode,setQrCode] = useState();
    useEffect(()=>{
        if(!shareStat){
            service.kqueryShareInfo().then(data=>{
                console.log('%c share data:%o','color:green',data);
                setShareStat(data);
            })
        }
        if(!inviteData){
            service.queryMyInviteData().then(data=>{
                console.log('%c my invite data:%o','color:green',data);
                setInviteData(data);
            })
        }
        if(!shareInfo){
            service.share().then(data=>{
                console.log(data);
                setShareInfo(data);
            })
        }
        if(!profile){
            const a= JSON.parse(localStorage.getItem('profile'));
            setProfile(a);
            
        }
    },[profile,setProfile,shareInfo,setShareInfo]);
    // console.log('PROFILE:',profile,profile?.headUrl);
     return (         
            <div className="share full-r-w about-cxt fx-box ">
                <div className="fx-box fx-ai-c fx-fd-c">
                    <div className="share-nav-container">
                        <div className="share-nav" onClick={()=>{
                            navigate(-1);
                        }}>
                            <i className='iconfont icon-weibiaoti--'></i>
                        </div>
                    </div>
                    
                    <img src="/static/images/share/title.png" className='share-title'></img>
                    {/* <img src="/static/images/share/title2.png" style={{width:100,height:100,objectFit:'contain'}}></img> */}
                    <div className="fx-box fx-ai-c fx-jc-c fx-fd-r">
                        <img src='/static/images/share/title_left.png'/>
                        <div className="font-size15 font-color3">我的邀请战绩</div>
                        <img src='/static/images/share/title_left.png'/>
                    </div>
                    <div className="share-container">
                        <div className="share-invite fx-box fx-fd-r">
                            <div className="fx-box fx-fd-c fx-1 fx-ai-c fx-jc-c">
                                <div className="font-size24 font-color3">{inviteData?.inviteNum}</div>
                                <div className="font-size10 font-color5">共邀请好友</div>
                            </div>
                            <div className="fx-box fx-fd-c fx-1 fx-ai-c fx-jc-c">
                                <div className="font-size24 font-color3">{inviteData?.inviteBonusRate}%</div>
                                <div className="font-size10 font-color5">分红比例</div>
                            </div>
                            <div className="fx-box fx-fd-c fx-1 fx-ai-c fx-jc-c">
                                <div className="font-size24 font-color3">{inviteData?.inviteIncome}</div>
                                <div className="font-size10 font-color5">邀请分红(金币)</div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="fx-box fx-ai-c fx-jc-c fx-fd-r">
                        <img src='/static/images/share/title_left.png'/>
                        <div className="font-size15 font-color3">分享规则</div>
                        <img src='/static/images/share/title_left.png'/>
                    </div>
                    <div style={{padding:22}}>
                        <div className='share-rule font-size13'>
                            <p>
                            1.邀请方式：点击下方“分享链接”将生成的链接发送给邀请的用户；</p>
                            <p>
            2.分享规则：被分享用户通过链接下载注册将直接与分享人绑定关系，被分享用户需要是首次注册和使用本app，被分享用户通过链接注册成功的将获得20金币；
            </p>
            <p>
            3.用户通过链接下载并成功注册app后，即视为成功绑定分享关系；
            </p>
            <p>
            4.分红政策：通过您分享的链接注册的用户，成功充值后，您将获得上方显示分红比例对等的充值分红；
            </p>
            <p>
            5.您所得的分红都将自动进入您的钱包中，可在钱包中查看收益；
            </p>
            <p>
            6.邀请明细:您也可以点击右上角“邀请明细”查看具体邀请及充值明细;
                            </p>
                        </div>
                    </div>
                    <div style={{height:100}}></div>
                    <div className="share-btn-container" onClick={
                        ()=>{
                            if(!!shareInfo) setShowDialog(true)
                            
                        }
                    }>
                        <img src="/static/images/share/share-btn.png" className='share-btn'></img>
                    </div>
                </div>
                <Popup visible={showDialog} 
                className='share-dialog'
                onOpened={()=>{
                    if(!qrCode){
                        const m = new window.QRCode(document.getElementById("qrcode"), shareInfo.appDownLoadUrl);
                        setQrCode(m);
                    }
                }}
                onClose={() => setShowDialog(false)}>
                    <div className="fx-box fx-ai-c fx-jc-c" style={{backgroundColor:'white'}}>
                        <div className="share-image-base" id="shareImageBase">
                            <div style={{position:'absolute',left:20,top:20,display:'flex',flexDirection:'row'}}>
                                <img style={{height:20,width:20}} src={profile?.headUrl?profile.headUrl.replace('http:','https:'):DefaultHeadUrl}>
                                </img>
                                <div className="font-size13">{profile?.nickname}</div>
                            </div>
                            <div style={{position:'absolute',left:50,top:50}}>
                                <img style={{height:100,with:100}} src='/logo192.png'></img>
                            </div>
                            <div className='qr-code' id='qrcode'></div>
                        </div>
                    </div>
                    <div className="share-btn-save font-size15 fx-box fx-ai-c fx-jc-c"  onClick={()=>{
                          
                          html2canvas(document.getElementById('shareImageBase')).then(c=>{

                            c.toBlob((f)=>{
                                const url = window.URL.createObjectURL(f);
                                a.href = url;
                                a.download = "myshare.png";
                                a.click();
                                URL.revokeObjectURL(url);
                            });
                            
                          })
                          
                    }}>
                        保存图片
                    </div>
                    <div className="share-btn-copy font-size15  fx-box fx-ai-c fx-jc-c" onClick={async ()=>{
                         await navigator.clipboard.writeText(shareInfo.appDownLoadUrl);
                         Toast.info('邀请链接已复制')
                    }}>
                        复制链接
                    </div>
                </Popup>
            </div>
         
     );        
}
export default Share;