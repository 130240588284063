const initialState={
    loading:false,
    location:null//{"district":"朝阳区","country":"中国","latitude":39.99653483072917,"longitude":116.48206895616319,"provence":"北京市","city":"北京市"} 
}
export default  (state=initialState,action)=>{
    // console.log('GEO reducer:',action);

    switch(action.type){
        case 'GEO_LOAD':
            return Object.assign({},state,{loading:true});
        case 'GEO_LOADFAIL':
            return Object.assign({},state,{loading:false});
        case 'GEO_LOADED':
            console.log('REDUX GEO_LOADED:',action.payload)
            return Object.assign({},state,{loading:false,location:action.payload});
        default:
            return state;
    }
}