import moment from "moment";
import { DefaultHeadUrl } from "../../service/images";

const timeStr = (time)=>{
    const m= moment(time);
    const a = m.valueOf();
    const b= new Date().valueOf();

    if(b-a<100000)return '刚刚';
    if(b-a<1000000)return '几分钟前';
    if(b-a<10000000)return '一小时前';
    return m.format('MM-DD');
}

const OpusDetailCommentList = (props)=>{

    return (
        <>
            <div className="font-size13" style={{paddingLeft:10,paddingBottom:10,paddingTop:10}}>
                全部评论({props.list?.totalRecords})
            </div>
            {
                props.list?.dataList?.map((item,idx)=>{
                    return (<div key={'odc_key_'+idx} className='odc-list-item font-size13'>
                        <div className="fx-box fx-fd-r" style={{marginBottom:12}}>
                            <div style={{marginRight:8}}>
                                <img src={item.headerUrl||DefaultHeadUrl}/>
                            </div>
                            <div>
                                <div className="font-size13 font-w5">{item.nickName}</div>
                                <div>{item.content}</div>
                                <div className="font-size10">
                                    <span style={{color:'#C6C6C6'}}>{timeStr(item.createTime)}</span>
                                    <span style={{marginLeft:10,color:'#999999'}} onClick={
                                        ()=>{
                                            props.onReply&&props.onReply(item);
                                        }
                                    }>回复</span>
                                </div>
                            </div>
                        </div>
                       
                        <div style={{paddingLeft:60}}>
                            {
                                item.subCommentList.map((subitem,idx)=>{
                                    return (
                                        <div key={'odc_si_key_'+idx}>
                                            <div className="fx-box fx-fd-r subcomment">
                                                <div>
                                                    <img src={subitem.headerUrl||DefaultHeadUrl}></img>
                                                </div>
                                                <div style={{marginLeft:4}}>
                                                    <div className="font-size10 font-w5">{subitem.nickName}</div>
                                                    <div>
                                                        {subitem.content}
                                                    </div>
                                                    <div style={{paddingTop:4,color:'#C6C6C6',fontSize:10}}>{timeStr(subitem.createItem)}</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>)
                })
            }
        </>
    )
    
    
    
}

export default OpusDetailCommentList