import React,{useState} from 'react';
import {Image} from 'react-vant';
import { DefaultHeadUrl } from '../../service/images';
import './model-msg-item.css';

const ModelItem=(props)=>{
      let msgs=props.msgs;
      return (<div>{
        msgs.map((item,idx)=>{
            return (
                <div className='msg-item-container fx-box fx-ai-c fx-jc-sb theme-font' key={idx}>
                        <div className='msg-item-info fx-box fx-ai-c' onClick={()=>props.onClick(item)}>
                            <Image width="0.35rem" height="0.35rem" round src={item.headUrl||DefaultHeadUrl} />
                            <div className='layout-ml8'>
                               <div className='msg-item-name font-size10'>{item.modelName}</div>
                               <div className='msg-item-name font-size10 font-color4 '>
                                   {item.career}
                               </div>
                            </div>
                        </div>

                        {/* <div className='fx-box fx-ai-c'>
                            {
                                 item.evaluation.split('|').map((tItem,index)=>{
                                    return (<div key={'umk_'+index} className={'msg-item-tag font-size10 font-color3 msg-item-tag-bg'+(index+1)}>{tItem}</div>)
                                 })
                            }
                        </div> */}
                  </div>
            )
          })
      }
             
        </div>);
}

export default ModelItem;