import React,{useState} from 'react';

import { useNavigate } from 'react-router-dom';

const ConversationList=(props)=>{
    const navigate= useNavigate();
      return (
         <div className='msg-list-container'>
            {
                props.conversations.map((item,idx)=>{
                    return (
                        <div className='msg-list-item fx-box' key={'k_'+idx} onClick={
                            ()=>{
                                navigate('/chat/'+item.name);
                            }
                        }>
                            <img src={item?.image??'/static/images/avatar.png'}/>
                            <div className='msg-user-info fx-box fx-1 fx-jc-sb'>
                                <div className='theme-font fx-box fx-fd-c fx-jc-sb'>
                                    <div className='font-size17 font-w5'>{item.nickname}</div>
                                    <div className='msg-brief font-size13 font-color4'>{item.msg}</div>
                                </div>

                                <div className='fx-box fx-fd-c fx-ai-e'>
                                     <div className='font-size13 font-color4'>{item.time}</div>
                                     {
                                        item?.msgnum?(<div className='msg-no-read font-size13 font-color3'>{item.msgnum}</div>):null
                                     }
                                     
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            <div style={{height:'1rem'}}></div>
         </div>
      );
}

export default ConversationList;