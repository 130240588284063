import { Popup } from "react-vant"
import "./mad.css"

export default  (props)=>{
    return(
        <Popup style={{
            width: 318,background: '#FFFFFF',borderRadius:12
        }} visible={props.visible}>
            <div className="fx-box fx-fd-c font-size13" style={{padding:22}}>
                <div className="fx-box fx-ai-c fx-jc-c" style={{height:35,marginBottom:25}}>
                    <div className="font-size18">温馨提示</div>
                </div>
                <div className="fx-box fx-ai-c fx-jc-c" style={{height:35,marginBottom:25}}>
                    <div>{props.title}</div>
                </div>
                <div className="fx-box fx-ai-c fx-jc-c" onClick={()=>{props.onClose&&props.onClose()}}>
                    <div className="btn mad-confirm font-size13 font-color3 fx-box fx-ai-c fx-jc-c">
                        确认
                    </div>
                </div>
            </div>
        </Popup>
    )
}