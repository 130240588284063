import React,{useState} from 'react';
import {Image} from 'react-vant';
import { DefaultHeadUrl } from '../../service/images';
import * as service from '../../service';
import './user-msg-item.css';

const UserMsgItem=(props)=>{
      let msgs=props.msgs;
      return (<div>{
        msgs.map((item,idx)=>{
            return (
                <div className='msg-item-container fx-box fx-ai-c fx-jc-sb theme-font' key={idx}>
                        <div className='msg-item-info fx-box fx-ai-c'>
                            <Image width="0.35rem" height="0.35rem" round src={item.headUrl||DefaultHeadUrl} />
                            <div className='layout-ml8'>
                               <div className='msg-item-name font-size10'>{item.nickName}</div>
                               <div><div className='msg-item-level font-size10 font-color3 fx-box fx-jc-sb fx-ai-c'>
                                   <Image width='0.08rem' height='0.08rem' src='/static/images/command-icon.png'/>
                                   <div>{item.userLeven}</div>
                               </div></div>
                            </div>
                        </div>

                        <div className='fx-box fx-ai-c'>
                            {
                                 item.evaluation.split('|').slice(0,3).map((tItem,index)=>{
                                    return (<div key={'umk_'+index} className={'msg-item-tag font-size10 font-color3 msg-item-tag-bg'+(index+1)} 
                                    style={{backgroundColor:service.getColorByTag(tItem)}}>{tItem}</div>)
                                 })
                            }
                        </div>
                  </div>
            )
          })
      }
             
        </div>);
}

export default UserMsgItem;