import React from 'react';
import { useNavigate } from 'react-router-dom'
import './navigation.css';
const Navigation = (props) => {
        const navigate = useNavigate();
        return (
                
                <div className='navigation full-r-w layout-fx fx-box fx-ai-c fx-fd-r fx-jc-c' 
                        style={{ 'height': props.height, ...props.style }}>
                        <div className='nav-container theme-font fx-box fx-ai-c fx-jc-c layout-rel'>
                                <div className="navigation-back fx-box fx-ai-c" onClick={() => {
                                        props.onBack ? props.onBack() : navigate(-1)
                                }}>
                                        <i className="font-size17  iconfont icon-weibiaoti-- font-w6"></i>
                                </div>
                                {props.title ? <div className='nav-title fx-1  font-size17 fx-box fx-ai-c fx-jc-c'>
                                        {props.title}
                                </div> : null}
                                {props.children ? props.children : null}
                                <div className='navigation-right'></div>
                                {props.right ? <div>{props.right}</div> : null}

                        </div>
                </div>

        )
}

export default Navigation;