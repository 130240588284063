import * as secure from './secure';
import Axios from 'axios';

const {VERSION,getDeviceNo,requestId,deviceId,TIME_STAMP,HOST} =require('./config');

console.log(deviceId,requestId);

const {key,iv} = secure.generateKeyIv(`${deviceId}${requestId}`);
const lastsig = secure.sign(VERSION,getDeviceNo(),TIME_STAMP);

export const fuPost = (url,param)=>{
    url = HOST+secure.base64safe(secure.encryptAES(url,key,iv));
    const requestParams = Object.assign({},param,{nonce:TIME_STAMP,
                            signature:lastsig,
                            terminal:'h',
                            version:VERSION,
                            timestamp:TIME_STAMP,
                            deviceNo:getDeviceNo()
                        });
    const data = secure.base64safe( secure.encryptAES(JSON.stringify(requestParams),key,iv));
    const payload = {
        data,
        type : "x-www-form-urlencoded"
    }
    console.log('%c [DEBUG]anonymous post:%o %o','color:green',url,param,deviceId,requestId);
    return Axios.post(url,payload,{
        headers:{
            'X-Device-Id':deviceId,
            'X-Device-Request-Id':requestId,
            // 'User-Agent':'FUWebView'
        }
    }).then(ret=>{
        return secure.decryptAESPromise(secure.base64unsafe(ret.data.data),key,iv);
    }).then(d=>{
        return JSON.parse(d);
    }).catch(err=>{
        console.error(err.code);
        return {error:err.message};
    })
}


export const fuUserPost=(url,param)=>{

    console.warn('fuUserPostTrace:',url,param);

    const profileStr = localStorage.getItem('profile');
    const profile = profileStr&&JSON.parse(profileStr);
    let userSig = secure.userSign(VERSION,getDeviceNo(),TIME_STAMP,profile.accessToken);
    url = HOST+secure.base64safe(secure.encryptAES(url,key,iv));
    const requestParams = Object.assign({},param,{
        userId:profile.userId,
        nonce:TIME_STAMP,
        signature:userSig,
        terminal:'1',
        version:VERSION,
        timestamp:TIME_STAMP,
        deviceNo:getDeviceNo(),
        accessToken:secure.md5(profile.accessToken).toUpperCase()
    });
    const data = secure.base64safe( secure.encryptAES(JSON.stringify(requestParams),key,iv));
    const payload = {
        data,
        type : "x-www-form-urlencoded"
    }
    let serverRet = '';

    // console.log('%c [DEBUG]user post:%o %o','color:green',url,param);

    return Axios.post(url,payload,{
        headers:{
            'X-Device-Id':deviceId,
            'X-Device-Request-Id':requestId,
            // 'User-Agent':'FUWebView'
        }
    }).then(ret=>{
        // console.log(ret.data);
        return secure.decryptAESPromise(secure.base64unsafe(ret.data.data),key,iv);
    }).then(d=>{
        //console.log('[TRACE]data:',d);
        serverRet = d;
        return JSON.parse(d);
    }).catch(err=>{
        console.error('return data:',serverRet);
        console.error(err.code);
        return {error:err.message};
    })
}

