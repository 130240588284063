import {fuUserPost} from './base';

export const checkLive = (modelId,viewerId,userType='0')=>{
    const url ='live/checkLive.json';
    
    const param = {        
        modelId,
        viewerId,
        userType
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);

}

export const getLiveToken = (channelName,modelId,viewerId,conversationId)=>
{
    const url = 'live/getLiveToken.json';
    let param;
    if(conversationId)
        param = {
            conversationId,
            channelName,
            userType:'0',
            modelId:modelId+'',
            viewerId:viewerId+'',
        }
    else param ={
        channelName,
        userType:'0',
        modelId:modelId+'',
        viewerId:viewerId+'',
    }

    return fuUserPost(url,param);
}

export const CALLMODEL = {
    avatar: null,
    giftId: 0,
    visiable: 0,
    killId: 0,
    code:0,
    message: null,
    nmbs: 0,
    type: 0
  }