import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Popup } from "react-vant";
import Navigation from "../../components/navigation/navigation";
import moment from "moment";
import * as service from '../../service';
import './Circle.css';
import { DefaultCircleBack } from "../../service/images";
import { DepositDialog } from "../../components/deposit-dialg";

const ModelCircleInfo = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {circleInfo} = location.state;
    console.log('%c circleInfo:%o','color:green',circleInfo);
    const [ showPayConfirmDialog,setShowPayConfirmDialog] = useState(false);
    const [showDepositDialog,setShowDepositDialog]  = useState(false);
    return (
        <div className="circle-purchase-page font-size13">
            
            <Navigation title={circleInfo?.circlename} style={{zIndex:1,    top: 0,
    paddingTop: 50,
    paddingBottom: 10,}}></Navigation>
            
            
            <div className="circle-purchase-banner fx-box fx-fd-c">
                <div>
                    <img src={circleInfo?.background?circleInfo.background:DefaultCircleBack}/>
                </div>
                
            </div>
            <div className="circle-purchase-content">
                <div className="circle-pur-section fx-box">
                    <div>
                        <img src={circleInfo?.headUrl}/>
                    </div>
                    <div className="fx-box fx-fd-c fx-jc-sb" style={{marginLeft:'0.1rem'}}>
                        <div>圈主: {circleInfo?.modelName}</div>
                        <div className="font-color7">创建于{moment(circleInfo?.createTime).format('YYYY-MM-DD')}, {circleInfo?.lastPublishTime}活跃</div>
                    </div>
                </div>
                <div className="circle-pur-section fx-box fx-fd-c fx-jc-sb ">
                    <div className="font-w6">简介</div>
                    <div>{circleInfo?.description}</div>
                </div>
                <div  className="circle-pur-section fx-box fx-fd-c"> 
                    <div className="font-w6">付费须知</div>
                    <div>
                    <p>1.付费成功后即可查看该圈内容，并参与讨论；</p>
<p>2.本圈由圈主自行运营，付费前请确认风险，Fu圈平台不提供相关保证，若发现违规圈子，请勿加入；</p>
<p>3.解锁后圈内会有部分单独付费内容；</p>
<p>4.有任何问题请联系客服</p>
                    </div>
                </div>
            </div>
            <div className="circle-purchase-bottom fx-box">
                <div className="fx-box fx-fd-c fx-1 fx-jc-c">
                    <div className="font-size15 font-color12">990 金币</div>
                    <div className="font-size10 font-color7" style={{marginTop:8}}>有效期: 永久</div>
                </div>
                <div className="fx-box fx-jc-c fx-ai-c">
                    <div className="c-p-submit" onClick={()=>{
                        setShowPayConfirmDialog(true);
                    }}>立即加入</div>
                </div>
            </div>
            <DepositDialog visible={showDepositDialog} onClose={()=>{
                setShowDepositDialog(false);
            }}/>
            <Popup visible={showPayConfirmDialog} 
            style={{borderRadius:12}}
            onClose = {()=>{
                setShowPayConfirmDialog(false);
            }}>
                <div className="c-p-dialog">
                    <div className='c-p-dialog-title'>
                        付费须知
                    </div>
                    <div className="c-p-dialog-content">
                        <p>1.付费成功后即可查看该圈内容，并参与讨论；</p>
                        <p>2.本圈由圈主自行运营，付费前请确认风险，Fu圈平台不提供相关保证，若发现违规圈子，请勿加入； </p>
                        <p>3.解锁后圈内会有部分付费内容；</p>
                        <p className="last-line">4.有任何问题请联系客服</p>
                    </div>
                   
                    
                    <div className="fx-box fx-jc-c fx-ai-c" style={{marginTop:37}}>
                        <div className="c-p-confirm-submit font-size13 fx-box fx-ai-c fx-jc-c font-color3" onClick={()=>{
                        //todo: implement it.
                        //[[PMTool sharedPMTool] pm:0 liveId:0 modelId:self.model.ID.intValue opusId:0 type:100 success:^(id  _Nullable json) {
                        //navigate , /fu/:id, replace:true;       
                        service.joinCircle(circleInfo?.id).then(data=>{
                            console.log(data);
                            if(data.code==0){
                                navigate('/fu/'+circleInfo?.id,{replace:true,state:{circleInfo}})
                            }
                            else if(data.code==701){
                                setShowPayConfirmDialog(false);
                                setShowDepositDialog(true);
                            }
                        })
                    }}>
                        990金币解锁FU圈
                    </div>
                    </div>
                    <div className="fx-box fx-jc-c fx-ai-c font-size13" style={{color:'#C2C2C2',marginTop:17}} onClick={
                        ()=>{
                            setShowPayConfirmDialog(false);
                        }
                    }>
                    忍痛离开 错过福利
                    </div>
                </div>
                
            </Popup>

        </div>
    )
}

export default ModelCircleInfo;