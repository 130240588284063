import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar, Image, Toast, Dialog, Field,Uploader } from "react-vant";


import * as service from "../../../service";
import {uploadS3 } from "../../../service/s3";
import { DefaultHeadUrl } from "../../../service/images";


import "./personData.css";
import Navigation from "../../../components/navigation/navigation";

// const Minio = require('minio');
// require('minio');


const PersonData = (props) => {
    const [headUrl,setHeadUrl] = useState(null);
    const [visible, setVisible] = useState(false); // 昵称弹窗    
    const [nickName, setNickName] = useState(""); // 昵称
    const [profile, setProfile] = useState(null);
    const [visible2, setVisible2] = useState(false); // 个性签名弹窗
    const [personalSign, setValue2] = useState(""); // 个性签名
    const [detail,setDetail] = useState(null);

    
    useEffect(()=>{
        // 获取用户信息
        if(!profile){
            setProfile(JSON.parse(localStorage.getItem('profile')));
            return;
        }
        if (!profile?.accessToken) {
            navigate('/login');
        }
        if(!detail){
            service.queryMyselfInfo().then((data) => {
                console.log('%c[DEBUG]get user info:','color:green',data);
              setDetail(data.user);
            });
        }
    })
  
  const navigate = useNavigate();
  
  
  const getUserDetail = () => {
    
  };
  // 点击弹框修改昵称
  const editNickName = () => {
    setVisible(true);
  };
  // 点击弹框修改个性签名
  const editNickName2 = () => {
    setVisible2(true);
  };
 
  return (
    <div className="personData">
        {/* <NavBar onClickLeft={()=>{
            navigate(-1);
        }} right="准则">
            个人资料
        </NavBar> */}
        <Navigation title='个人资料'/>
        <div style={{marginTop:30}}></div>
        <div className="personDataTr">
            <div className="personDataTrLeft">头像</div>
            <div className="right-menu personDataTrRight">
                <Uploader afterRead={(obj) => {
                    console.log('%c [DEBUG]upload selected:','color:green',obj)
                    uploadS3(obj.file).then(ret=>{
                       setHeadUrl(ret);
                       service.updateUser({headUrl:ret});
                       Toast.info('已成功提交，请等待审核')
                    });
                }
                }>
                <Image
                    className="personDataTrRightImg"
                    src={profile?.headUrl||DefaultHeadUrl}
                    width={46}
                    height={46}
                    fit="cover"
                    style={{ borderRadius: 23 }}
                />
                </Uploader>
                <i  className='iconfont icon-weibiaoti--'></i>
            </div>
        </div>
    
   
      <div className="personDataTr" onClick={editNickName}>
        <div className="personDataTrLeft">昵称</div>
        <div className="right-menu personDataTrRight pt-0">
          <span className="personDataTrRightImg personData999999">
            {profile?.nickname}
          </span>
          <i  className='iconfont icon-weibiaoti--'></i>
        </div>
      </div>
      <div className="personDataTr" onClick={editNickName2}>
        <div className="personDataTrLeft">个人签名</div>
        <div className="right-menu personDataTrRight">
          <span className="personDataTrRightImg personData999999">
            {detail?.personalSign}
          </span>
          <i  className='iconfont icon-weibiaoti--'></i>
        </div>
      </div>
      <div className="personDataTr">
        <div className="personDataTrLeft">上传背景图 </div>
        {/*personalPageImg*/}
        <div className="right-menu personDataTrRight">
            <Uploader afterRead={(obj) => {
                    console.log('%c [DEBUG]upload selected:','color:green',obj)
                    uploadS3(obj.file).then(ret=>{
                       setHeadUrl(ret);
                       service.updateUser({personalPageImg:ret});
                       Toast.info('已成功提交，请等待审核')
                    });
                }
                }>  
            <Image
                className="personDataTrRightImg"
                src={detail?.personalPageBackImg}
                width={32}
                height={24}
                fit="cover"
                style={{ borderRadius: 4 }}
            />
            </Uploader>
            <i  className='iconfont icon-weibiaoti--'></i>
        </div>
      </div>

      <div className="personDataTr">
        <div className="personDataTrLeft">FU ID</div>
        <div className="personDataTrRight personData999999">
          {profile?.userId}
        </div>
      </div>
      {/* 修改昵称 */}
      <Dialog
        visible={visible}
        title="修改昵称"
        showCancelButton
        onCancel={() => setVisible(false)}
        onConfirm={ 
            async () => {
                const data = await service.updateUser({ nickName }); 
                console.log('%c[DEBUG]update user info:','color:green',data);
            
                if (data.code === "0") {
                    Toast.info('已成功提交，请等待审核')
                    await getUserDetail();
                } else {
                    Toast.info(data.errMsg);
                }
                setVisible(false);
           }
        }
        >
        
          <Field
            placeholder="最多支持8个字"
            value={nickName}
            onChange={(val) => {
              if (val.length <= 8) {
                setNickName(val);
              }
            }}
          ></Field>
      </Dialog>
      {/* 修改个性签名 */}
      <Dialog
        visible={visible2}
        title='修改签名'
        showCancelButton
        onCancel={()=>{
            setVisible2(false);
        }}
        onConfirm={()=>{
            service.updateUser({personalSign});
            Toast.info('已成功提交，请等待审核')
            getUserDetail();
            setVisible2(false);
        }}
        >
          <Field
            placeholder="请输入新的签名(25字内)"
            value={personalSign}
            onChange={(val) => {
              if (val.length <= 25) {
                setValue2(val);
              }
            }}
          />
        </Dialog>
        
    </div>
  );
};

export default PersonData;
