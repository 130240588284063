import { useRef } from "react";
import VideoPlayBtnImage from '../../assets/images/video-play.png';

export const CircleVideo = (props)=>{
    const videoRef = useRef();
    return (<div className='circle-item-1'>
        <video controls ref={videoRef} poster={props.imgUrl.replace('http:','https:')} style={{width:'100%',height:'100%'}}>
            <source  src={props.videoUrl.replace('http://','https://')}
                type="video/mp4"/>
        </video>    
        
        <div className='fx-box fx-jc-c fx-ai-c video-cover' style={{position:'absolute',left:0,right:0,top:0,bottom:0}}>
                <img className='circle-video-cover-b' src={props.imgUrl}/>
                        <img  className='video-play-btn' style={{zIndex:1,width:60,height:60}} src={VideoPlayBtnImage} 
                        onClick = {()=>{
                            console.log('clicked.');
                            if(props.canPlay){
                                if(videoRef.current.requestFullscreen){
                                    videoRef.current.requestFullscreen({navigationUI:'show'})    
                                }
                                else if (videoRef.current.mozRequestFullScreen) {
                                    videoRef.current.mozRequestFullScreen();
                                } else if (videoRef.current.webkitRequestFullscreen) {
                                    videoRef.current.webkitRequestFullscreen();
                                } else if (videoRef.current.msRequestFullscreen) {
                                    videoRef.current.msRequestFullscreen();
                                } else console.log('no native request full screen'); 
    
                                //videoRef.current.webkitRequestFullscreen({navigationUI:'show'});
                                videoRef.current.play();
                            }
                            else props.onRequestPlay();
                            
                        }}></img>
        </div>
    </div>)
}