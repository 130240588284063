import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from "../../components/navigation/navigation";
import './Feedback.css';
import {Toast} from 'react-vant'
import * as service from '../../service';
const Feedback=()=>{
    const [suggestion, setSuggestion] = useState(null);
    const navigate = useNavigate();
      return (
        <div className="feedback-page hide-x">
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'意见反馈'} style={{paddingTop:15}}></Navigation>
           <div className='full-r-w fx-box fx-jc-c'>
                    <div className="feedback-box layout-mt70">
                    <textarea 
                    value={suggestion}
                    placeholder='请输入您对我们的建议'
                    className="feedback-textarea font-size15"
                    onChange={(e)=>{
                        setSuggestion(e.target.value);
                    }}></textarea>
                </div>
           </div>
           <div className="fx-btn layout-fx full-r-w"  style={{bottom:'0.2rem'}} onClick={async ()=>{
            await service.suggest(suggestion);
            Toast.info('意见已经反馈');
            setSuggestion('');
            navigate(-1);
           }}>
               <div className="fx-submit btn-bg1 font-size15 font-w6 font-color3">提交</div>
           </div>
        </div>
      );
}

export default Feedback;