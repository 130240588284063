import React from 'react';
import { Navigate } from 'react-router-dom';

import './Launch.css';
import { LaunchImage } from './LaunchImage';

const Launch=()=>{
      console.log('.........');
      // return (<div className='launch-page'>
      //               <LaunchImage/>
      // </div>);

      return <Navigate to='/app'/>
}


export default Launch;