import React, { useEffect,useState } from 'react';
import {Image, Overlay,Toast,Picker,Field,Popup,DatetimePicker, Uploader,Loading} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import './album.css';
import * as service from '../../../service';
import moment from 'moment';
import { uploadS3 } from '../../../service/s3';


const Album=()=>{
    // const navigate = useNavigate();
    const [img,setImg] = useState([]);
    const [loading,setLoading] = useState(false);

    // const oldState = location?.state?.oldState;

    return (
        <div className="star-page hide-x theme-font">
            <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'相册'}></Navigation>
            <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt60' style={{marginTop:80}}>
                <div className='album-notice font-size15 fx-box fx-ai-c fx-jc-c font-color11'>
                    请上传4-9张本人照片
                </div>
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h fx-1'>
                    <Uploader 
                        value={img}
                        onChange={(v)=>setImg(v)}
                        afterRead={(d)=>{
                            console.log('file:',d);
                            d.status='done';
                            const n = img.slice(0);
                            n.push(d)
                            setImg(n);
                            ;
                        }}
                    />
                </div>
                <div className="fx-btn layout-fx full-r-w" >
                    <div className="fx-submit btn-bg1 font-size15 font-w6 font-color3"
                     onClick={async ()=>{
                        setLoading(true);
                        const keys =[];
                        for(let i =0;i<img.length;i++){   
                         const key= await uploadS3(img[i].file);
                         keys.push(key);
                        }
                        console.log(keys);
                        await service.updateUser({vedioHeadImgUrl:keys})    

                        Toast.info('上传完成');
                        setLoading(false);
                        //navigate('/star-certificate',{state:{album:keys,}})
                    }}>
                        {loading?<Loading type="spinner" />:'上传'}
                        </div>
                </div>
               
            </div>
        </div>
      );
}

export default Album;