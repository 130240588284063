import React, { useEffect ,useRef,useState} from 'react';
import {Image, Toast} from 'react-vant';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import './WithdrawList.css';
const WithdrawList=()=>{
    const profile = JSON.parse(localStorage.getItem('profile'));
    const listRef = useRef();

    const [blist,setList] = useState(null);
    let loading = false;
    useEffect(()=>{
        if(!blist){
            service.withdrawRecord(0).then(data=>{
                if(data.code==0)
                {
                    console.log('%c data:%o','color:green',data);
                    setList(data.cashlist);
                }
                
            })
        }

        const list = listRef.current;
        let timer;

        const handleScroll = ev => {
        
            const scrollTop = document.getElementById('root').scrollTop;
            //const screenHeight = window.screen.height;
            const screenHeight = list.scrollHeight;
            const offsetHeight = list.offsetHeight;
            
            if (scrollTop >= (screenHeight -  offsetHeight* 1.5)) {
                clearTimeout(timer);
                if(loading){
                    //is loading more, do not repeat.
                    return;
                }

                timer = setTimeout(() => {
                    loading = true;    
                    
                    service.withdrawRecord(blist.length).then(data=>{
                        console.log(data);
                        if(data.cashlist&&data.cashlist.length){
                            setList(old => [ ...old,
                                ...data.cashlist
                            ]);
                        }
                    }).finally(()=>{
                        console.log('done');
                        loading=false;
                    })
                }, 10);
            }
        };

        list.addEventListener('wheel', handleScroll);
        list.addEventListener('touchmove', handleScroll);
        return () => {
          clearTimeout(timer);
          list.removeEventListener('wheel', handleScroll);
          list.removeEventListener('touchmove', handleScroll);
        };


    },[setList])
      return (
        <div className="withdraw-list-page hide-x">
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'提现明细'}></Navigation>
           <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt60' ref={listRef}>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='blackItem fx-box fx-jc-sb fx-ai-c'>
                        {/* <div className='fx-box fx-fd-r'> */}
                           <Image width={'0.41rem'} height={'0.41rem'} round src={profile.headUrl}/>
  
                           <div className='black-userid fx-box fx-fd-c  fx-jc-sb fx-1'>
                              <div className='font-size15'>{profile.nickname}</div>
                              <div className='font-size12'>{item.createTime}</div>
                           </div>

                            <div className='withdraw-record-right fx-box fx-fd-c' >
                                <div className='font-size12 font-color11 fx-box fx-jc-e'>{item.statusDesp}</div>
                                <div className='font-size13 font-color9 fx-box fx-jc-e'>{item.withdrawXp}钻石</div>
                            </div>
                        {/* </div> */}
  
                       
                    </div>)
                    })
                 }
           </div>
          
        </div>
      );
}

export default WithdrawList;