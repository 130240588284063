import { useEffect,useState } from "react";
import { useNavigate, useParams, Link} from "react-router-dom";
import Navigation from "../../components/navigation/navigation";
import * as service from "../../service";
import { ImagePreview, Toast , Image} from "react-vant";
import OpusDetailCommentList from "../../components/opus-comment-list";
import './Circle.css';

export const OpusDetail = (props)=>{
    const [model,setModel] = useState(null);
    const [updateTimestamp,setUpdateTimestamp] = useState(new Date().valueOf());
    const [item,setItem] = useState(null);

    const [commentType,setCommentType]  = useState('FIRST_COMMENT');
    const [commentId, setCommentId] = useState(null);
    const [commentWriter,setCommentWriter]  = useState('');
    const [comment,setComment] = useState('');

    const params = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        service.opusDetail(params.id).then(data=>{
            console.log('%c opus detail data:%o','color:green',data);
            if(data.code==0)setItem(data.data);
        })
    },[model,setModel]);
    return (
        <div className='circle-opus-detail-page'>
            
            <Navigation title={item?.modelName} style={{height:40,paddingTop:50,backgroundColor:'white'}}/>
            

            <div className='circleListItem' onClick={()=>{
                setCommentId(null);
                setCommentWriter(null);
                setCommentType('FIRST_COMMENT');
            }}>
                        <div className='circleListUser fx-box fx-ai-c fx-jc'>
                            <div className={item?.online===1?'circleListUserAvatar '+'circleListUserOnline':'circleListUserAvatar'}
                            onClick={()=>{
                                navigate('/person/'+item?.modelId,{})
                            }}
                            >
                                <img src={item?.headUrl||props.headUrl}/>
                                {
                                    item?.online?<div className='circleListUserOnlineTip'>{service.statusText(item?.status)}</div>:<span></span>
                                }
                            </div>

                            <div className='theme-font userItemLevel fx-1 fx-box fx-jc-sb fx-ai-s'>
                                

                                <div className='fx-box fx-fd-c  fx-jc-sb'>
                                    <div className='fx-box fx-ai-c'>
                                        <div className='font-size17 font-w5'>{item?.modelName}</div>
                                    </div>
                                    <div className='font-size13 font-color4'>{item?.time}</div>
                                </div>

                                
                                <div className='userItemMore fx-box fx-fd-c fx-ai-s'>
                                    <i className='iconfont icon-more'></i>
                                </div>
                                
                            </div>

                        </div>

                        <div className='userItemMsg theme-font font-size13'>{item?.description}</div>

                        <div className='circleItemAlbums'>
                            {item?.videoUrl&&(<video controls poster={item?.imgUrl.replace('http:','https:')} width="250">
                                <source  src={item?.videoUrl.replace('http://','https://')}
                                        type="video/mp4"/>
                                </video>)
                            }
                            {
                                item?.photos?.map&&item?.photos.map((src,index)=>{
                                    return  (
                                    <div className={'circleItemBack'}>
                                        {
                                            (item?.isReward<=0&&!props.isVip)?
                                            <img className={'circleItemImg '+(index>=(item?.freeCount*1)?'nopay':'free')} key={index} src={index>=(item?.freeCount*1)?'/static/images/fu-fog.jpg':src} 
                                            onClick={
                                                ()=>{
                                                    if(item?.isReward<=0&&!props.isVip){
                                                        if(index>=item?.freeCount){
                                                            props.onShouldPay(item);
                                                        }
                                                        else {
                                                            var destroy=null;
                                                            const photos = item?.photos.slice(0,item?.freeCount);
                                                            photos.push('/static/images/fu-fog.jpg');
                                                            destroy=ImagePreview.open({images:photos,startPosition:index,onChange:(a)=>{
        
                                                                if(a>=item?.freeCount){
                                                                    destroy&&destroy();
                                                                    props.onShouldPay(item);
                                                                }
                                                            }});
                                                        }
                                                    }
                                                    else {
                                                        ImagePreview.open({images:item?.photos,startPosition:index});
                                                    }
                                                }
                                            }
                                            />
                                            :
                                            <img className={'circleItemImg free'} key={index} src={src} 
                                            onClick={
                                                ()=>{
                                                    if(item?.isReward<=0&&!props.isVip){
                                                        if(index>=item?.freeCount){
                                                            props.onShouldPay(item);
                                                        }
                                                        else {
                                                            var destroy=null;
                                                            const photos = item?.photos.slice(0,item?.freeCount);
                                                            photos.push('/static/images/fu-fog.jpg');
                                                            destroy=ImagePreview.open({images:photos,startPosition:index,onChange:(a)=>{
        
                                                                if(a>=item?.freeCount){
                                                                    destroy&&destroy();
                                                                    props.onShouldPay(item);
                                                                }
                                                            }});
                                                        }
                                                    }
                                                    else {
                                                        ImagePreview.open({images:item?.photos,startPosition:index});
                                                    }
                                                }
                                            }
                                            />
                                        }
                                        
                                        
                                    </div>)
                                })
                                
                            }
                           
                        </div>

                        <div className='userItemPreview theme-font fx-box fx-ai-c fx-jc-sb'>
                            <div className='font-size15 font-color2 '>
                                {item?.price>0?
                                ((!props.isVip&&item?.isReward<=0)?
                                    <div className='circle-item-lock  fx-box fx-jc-c fx-ai-c' onClick={()=>{
                                        console.log(item);
                                        props.onShouldPay(item);
                                    }}>
                                        <span className='circle-item-unlock-amount'>{item?.price}金币 </span>
                                        <span className='circle-item-gold'>解锁</span>
                                    </div>
                                    :
                                    <div className='circle-item-lock'>
                                        <span className='circle-item-gold'>已解锁</span>
                                        <span className='circle-item-gold'>{item?.price}金币</span>
                                    </div>
                                )
                                :null}
                            </div>

                            <div className='userItemP fx-box fx-ai-c'>
                            
                                <Link to={'/fuopus/'+item?.id}><div className='fx-box fx-ai-c'>
                                    <i className='iconfont icon-xiaoxi3'></i>
                                    <span className='font-size13 font-color4'>{item?.commentNum}</span>
                                </div></Link>

                                <div className='fx-box fx-ai-c' >
                                    {item?.isCollect==1?
                                    <img style={{height:"0.18rem", width:"0.18rem"}} src={'/static/images/loved.png'}
                                    onClick={()=>{
                                        service.uncollectActivity(item?.id).then(ret=>{
                                            console.log(ret);
                                            item.isCollect=0;
                                            item.collectNum=ret.collectNum;
                                            setUpdateTimestamp(new Date().valueOf());
                                        });
                                    }}
                                    />:<img style={{height:"0.18rem", width:"0.18rem"}} src={'/static/images/love.png'}
                                    onClick={()=>{
                                        service.collectActivity(item?.id+'').then(ret=>{
                                            console.log('collect:',item?.id+'',ret);
                                            item.isCollect=1;
                                            item.collectNum=ret.collectNum;
                                            setUpdateTimestamp(new Date().valueOf());
                                        });
                                    }}
                                    />
                                    
                                    }
                                    <span className='font-size13 font-color4'>{item?.collectNum}</span>
                                </div>
                            </div>
                        </div>
            </div>
            <div className="c-o-d-split">

            </div>
            <OpusDetailCommentList list={item?.commentList} onReply={(comment)=>{
                console.log(comment);
                setCommentId(comment.id);
                setCommentWriter(comment.nickName);
                setCommentType('SUB_COMMENT');
            }}/>
            <div className="opus-comment-blank">

            </div>
            <div className="opus-comment-container">
                <div className="fx-box fx-fd-r fx-ai-c fx-jc-c font-size13">
                    <div>
                        <input value={comment} onChange={(e)=>{
                            setComment(e.target.value);
                        }} placeholder={commentId?'回复'+commentWriter:'请输入评论'}></input>
                    </div>
                    <div>
                        <div className="submit fx-box fx-jc-c fx-ai-c" onClick={()=>{
                            service.circleComment(commentType,params.id,comment,commentId).then(data=>{
                                console.log('%c comment return:%o','color:green',data);
                                if(data.code==0){
                                    setComment('');
                                    Toast({
                                        message: '评论成功',
                                        icon: <Image width={50} src="/static/images/toast-icon.png" />,
                                    });
                                }
                                
                            })
                        }}>发送</div>
                    </div>
                </div>
            </div>
        </div>
    )
}