import React,{useEffect, useState} from 'react';
import * as service from '../../service';
import './Ranking.css';
import RankingNav from './Ranking-Nav';
import RankingHead from './Ranking-Head';
import RankingList from './Ranking-List';

//dy, wk, mh

const Ranking=()=>{
     //两种主题的配置
     let headers=[{
        title:'女神榜',
        key:'1',theme:'linear-gradient(180deg, #FC66FF 0%, #FF5E69 100%)',bg:'/static/images/goddess.png',
        rankFunc:service.rank
     },{
        title:'财富榜',key:'2',theme:'linear-gradient(180deg, #4F4F4F 0%, #000000 67.71%)',bg:'/static/images/money-bg.png',
        rankFunc:service.rich
     }]
     const [activeKey,setactiveKey]=useState('1');             //点击女神榜和财富榜  用于切换的key
     //const [themes,setthemes]=useState('linear-gradient(180deg, #FC66FF 0%, #FF5E69 100%)');//点击女神榜和财富榜，切换的主题不一样
     const [bgimg,setbgimg]=useState('/static/images/goddess.png');   //点击女神榜和财富榜,切换那个作为背景图
     const [leaveTime,setLeaveTime] = useState('');
     //各种排行的配置
     const ranks=[
        {
            key:'1',
            title:'日榜',
            type:'dy'
        },
        {
            key:'2',
            title:'周榜',
            type:'wk'
        },
        {
            key:'3',
            title:'月榜',
            type:'mh'
        }
      ]
      //周/月/季 几个关键key的切换
      const [rankKey,setrankKey]=useState('1');
      
      //排行榜的默认数据
      const mockList=[
            {
                avatar:'/static/images/app-p2.png',
                nickName:'妹子1',
                hot:2880
            },
            {
                avatar:'/static/images/app-p10.png',
                nickName:'妹子2',
                hot:2700
            },
            {
                avatar:'/static/images/app-p10.png',
                nickName:'妹子3',
                hot:2180
            },
            {
                avatar:'/static/images/app-p10.png',
                nickName:'妹子4',
                hot:1980
            },
            {
                avatar:'/static/images/app-p10.png',
                nickName:'妹子5',
                hot:1880
            },
            
            {
                avatar:'/static/images/app-p8.png',
                nickName:'妹子3s',
                hot:180
            },
            {
                avatar:'/static/images/app-p8.png',
                nickName:'妹子4s',
                hot:80
            },
            {
                avatar:'/static/images/app-p8.png',
                nickName:'妹子5s',
                hot:50
            }
      ]


      const [data, setData] = useState(null);
      
      useEffect(()=>{
        console.log('useEffect:',rankKey,activeKey)
        
        const type=['','dy','wk','mh'][rankKey];
        headers[activeKey-1].rankFunc(type).then(ret=>{
            setData(ret);
        });
    
      },[activeKey,rankKey,setData]);

      return (
        <div className='rank-page full-r-w theme-font'>
               {/*setTheme 表示切主题 财富榜/女神榜*/}
               <RankingHead 
               headers={headers}

               activeKey={activeKey}
               setTheme={(item)=>{
                    //setRankFunc(item.rankFunc);
                    setactiveKey(item.key)
                    //setthemes(item.theme) theme={themes}
                    setbgimg(item.bg)
                    
               }}
               />
               {/*setKey 切换 月/周 排行指标*/}
               <RankingNav 
               bgimg={bgimg}
               rankKey={rankKey}
               ranks={ranks}
               themeKey={activeKey}
               last={data?.lastIssueUserList}
               data={data}
               setKey={(e)=>{
                    const type=['','dy','wk','mh'][e];
                    console.log('activeKey:',activeKey);
                    headers[activeKey-1].rankFunc(type).then(data=>{
                        console.log('%c rank data:%o','color:green',data);
                        setData(data);
                        setLeaveTime(data.leaveTime);
                    })
                    console.log(e)
                    setrankKey(e)
               }}/>


               <RankingList
               indicatorIcon = {activeKey==1?'/static/images/heart-icon.png':'/static/images/gold-icon.png'}
               list={data?.userList??[]} period={['','日','周','月'][rankKey]} remain={leaveTime} data={data}/>

      </div>)
}

export default Ranking