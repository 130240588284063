import moment from 'moment';

const albumBucketName = "uglbimg";
//const albumBucketName ='s3u2i20120';
const bucketRegion = "cn-north-1";
const accessKeyId = "pDT2m1i1Ni2o2w9W";
const secretAccessKey = "rW2rXpdT1p1UsHGuiEQGyl2IfW2ri9rW";
const endpoint= "https://s3u2i20120.douquwl88.com";
//const endpoint= "https://douquwl88.com";

const AWS = window.AWS;

console.log(AWS);


AWS.config.update({
  region: bucketRegion,
  endpoint,
  s3BucketEndpoint:true,
  credentials: new AWS.Credentials(accessKeyId,secretAccessKey)
});

// const s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: { Bucket: albumBucketName }
// });

const genKey =(name)=>{
    return moment().format('yyyy/MM/DD')+'/'+name;
}

export function uploadS3(file) {
  
  const key = genKey(file.name);

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: albumBucketName+'/'+key,
      Body: file
    }
  });

    

  return upload.promise().then(
    function(data) {
      return key;
    },
    function(err) {
      console.error("There was an error uploading your photo: ", err.message);
      throw new Error(err);
    }
  );
}
