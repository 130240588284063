import React from 'react';
import {Image} from 'react-vant';
import { Link } from 'react-router-dom';
import { DefaultHeadUrl } from '../../service/images';
const RankListItem=(props)=>{
      const createIndexIcon=(index)=>{
            if(index<=2){
                return (<div>
                    <Image width="0.24rem" height="0.17rem" src={'/static/images/diadema-0'+(index+1)+'.png'}/>
                   </div>)
            }else{
                return (<div className='list-item-index font-size13 font-w5'>{index+1}</div>)
            }

      }
      return (<div className='rank-list-cxt theme-font'>
                {
                    props.list.map((item,index)=>{
                        return (<div key={index}  className='rank-list-item fx-box fx-ai-c'>
                            {
                                createIndexIcon(index)
                            }
                            {
                                <Link to={'/person/'+item.userId}>
                                    <div className='layout-ml12'>
                                        <Image width="0.47rem" height="0.47rem" round src={item.headUrl||DefaultHeadUrl}/>
                                    </div>
                                </Link>
                            }
                           
                           <div className='layout-ml12'>
                              <div className='font-size13 font-w5'>{item.nickname}</div>
                              {/* <div className='fx-box fx-ai-c'>
                                 <Image width={'0.11rem'} height={'0.11rem'} src={props.indicatorIcon}/>
                                 <span className={'font-size13 '+(item.income?'font-w5 font-color2 ':' font-color1')}>{item.income||item.consume}</span>
                              </div> */}
                           </div>
                      </div>)
                    })
                }   
      </div>);
}

export default RankListItem;