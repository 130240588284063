import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavBar from "../../components/nav-bar/nav-bar";
import CircleList from "../../components/circle-list-item/circle-list-item";
import * as service from '../../service';
import './Circle.css';
import Navigation from "../../components/navigation/navigation";
import { Popup, Toast,Image,Loading} from "react-vant";
import { DefaultHeadUrl,DefaultCircleBack } from "../../service/images";
import IntimacyDialog from "../../components/gift-dialog/intimacy-dialog";
import { transform } from "typescript";
import { charge } from "../../service";
import { DepositDialog } from "../../components/deposit-dialg";
import { VipDialog } from "../../components/vip-dialog";
const circleTabs = [
    {title:'最新',
    queryType:0,
    id:1},
    {title:'已付费',
    queryType:3,
    id:2},
    {title:'图片',
    queryType:1,
    id:3},
    {title:'视频',
    queryType:2,
    id:4},
]
const ModelCircleHome = ()=>{
    const listRef = useRef();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const profile = JSON.stringify(localStorage.getItem('profile'));
    const {circleInfo} = location.state;
    // console.log('%c circle info:%o','color:green',circleInfo);
    const [contentList , setContentList] = useState(null);
    const [showPayDialog, setShowPayDialog] = useState(false);
    const [currentItem,setCurrenItem] = useState(null);
    const [nextPage,setNextPage] = useState(1);
    const [totalPage,setTotalPage] = useState(0);
    const [showDialog,setShowDialog] = useState(false);
    const [showDepositDialog,setShowDepositDialog] = useState(false);
    const [showVipDialog,setShowVipDialog] = useState(false);
    const [currentTabIdx,setCurrentTabIdx] = useState(0);
    
    const [detail,setDetail] = useState(null);
    const [remoteLoading,setRemoteLoading] = useState(false);
    let timer,loading=false;


    const buyAnnual = ()=>{
        const param = {
            type:'101',
            modelId:circleInfo.id+''
        }
        console.log('buy anuall:',param);
        charge(param).then(data=>{
            //todo: 永久开通圈内会员
            console.log(data);
            if(data.code==701){
                setShowPayDialog(false);
                setShowDepositDialog(true);
            }
            else if(data.code==0){
                setShowPayDialog(false);
                circleInfo.status=2;
                Toast({
                    message: '购买成功',
                    icon: <Image width={50} src="/static/images/toast-icon.png" />,
                });
            }

        })
    }

    useEffect(()=>{
        console.log('%c next page is:%d','color:green',nextPage);
        if(!detail){
            service.getModelDetail(params.id).then(data=>{
                setDetail(data);
            })
        }
        if(!contentList){
            setRemoteLoading(true);
            const tab = circleTabs[currentTabIdx]
            service.circleContentQuery(params.id,tab.queryType).then(data=>{
                console.log('circle data:',data);
                if(data.code==21){
                    navigate('/login',{replace:true})
                }
                else {
                    setContentList(data.opusList);
                    console.log('%c Set Total Page:%d','color:green',data.totalPage);
                    setTotalPage(data.totalPage);
                    setNextPage(2);
                }
                
            }).finally(()=>{
                setRemoteLoading(false);
            })
        }
        const list = listRef.current;
        const handleScroll=()=>{
            //const scrollTop = document.getElementById('root').scrollTop;
            const scrollTop = document.getElementById('circlepage').scrollTop;
            const screenHeight = window.screen.height;
            const offsetHeight = document.getElementById('circlelist').offsetHeight;

            // console.log('%c scroll debug:%d,%d,%d','color:green',scrollTop,screenHeight,offsetHeight);
            // console.log('%c nextPage, totalPage:%d %d','color:blue',nextPage, totalPage)
            
            if(nextPage>=totalPage)return;
            

            // console.log('%c list.height:%d','color:green',document.getElementById('circlelist').height);
            // console.log(scrollTop,screenHeight,offsetHeight,(offsetHeight - screenHeight * 1.5),'contentList:',contentList?.length);
            if (scrollTop >= (offsetHeight - screenHeight * 1.5)) {
                if(loading){
                    //is loading more, do not repeat.
                    return;
                }
                loading = true;
                clearTimeout(timer);
                // console.log(scrollTop,screenHeight,offsetHeight,(offsetHeight - screenHeight * 1.5),'contentList:',contentList?.length);
                 
                timer = setTimeout(() => {
                    // console.log('%c currentTabIndex:%d','color:blue',currentTabIdx);
                    const tab = circleTabs[currentTabIdx];
                    // console.log('....loading ...:',nextPage,contentList.length);
                    service.circleContentQuery(params.id,tab.queryType,nextPage).then(data=>{
                        // console.log('%c circleContentQuery:%o','color:blue',data);
                        setContentList([...contentList,...data.opusList]);
                        setNextPage(nextPage+1);
                        setTotalPage(data.totalPage);
                    }).finally(()=>{
                        loading=false;
                    });
                
                }, 10);
            }
        }
        list.addEventListener('scroll', handleScroll);
        // list.addEventListener('wheel',handleScroll);
        return(()=>{
            clearTimeout(timer);
            list.removeEventListener('scroll', handleScroll);
        })

    },[contentList,setContentList,nextPage,setNextPage,totalPage,currentTabIdx,setRemoteLoading,setTotalPage])
    return (
        <div className="circle-purchase-page font-size13" id="circlepage" ref={listRef}>
            <Navigation title={circleInfo?.circlename} style={{zIndex:1,paddingTop:'0.5rem',top:0,paddingBottom:'0.1rem'}}
             ></Navigation>
            <div className="circle-purchase-banner fx-box fx-fd-c">
                <div>
                    <img src={circleInfo?.background??DefaultCircleBack}/>
                </div>
                <div className="circle-purchase-head fx-box fx-fd-c fx-jc-e">
                    <div className="fx-box fx-fd-r fx-jc-s">
                        <img src={circleInfo?.headUrl??DefaultHeadUrl}></img>
                        <div className="fx-box fx-fd-c font-color3 fx-jc-sb" style={{paddingLeft:8}}>
                                <div className="font-size15">{circleInfo?.circlename}</div>
                                <div className="font-size10">精选{circleInfo?.contentCount}条|圈成员：{circleInfo?.joinedUserCount}</div>
                        </div>
                    </div>
                    <div className="fx-box fx-fd-r circle-head-btn">
                        {circleInfo?.annualPrice?<div className="fx-box fx-fd-c fx-ai-c" onClick={()=>{
                            circleInfo?.annualPrice&&setShowVipDialog(true);
                        }}>
                            <img src={'/static/images/fu-vip.png'}/>
                            <div className="font-size10 font-color3">圈内VIP</div>
                        </div>:null}
                        { circleInfo?.wechatShowFlag?
                            <div className="fx-box fx-fd-c fx-ai-c" onClick={()=>{
                            setShowDialog(true);
                        }}>
                            <img src={'/static/images/fu-wechat.png'}/>
                            <div className="font-size10 font-color3">微信</div>
                        </div>:null}
                    </div>
                </div>
            </div>
            <div className="circle-content" id="circlelist" >
                <div className="circle-nav-container">
                    <NavBar 
                    activity={circleTabs}
                    showSearch={false}
                     reload={(idx)=>{
                        setCurrentTabIdx(idx);
                        setContentList(null);
                }}/>
                </div>
                {
                    remoteLoading?
                    <div className="fx-box fx-jc-c fx-ai-c" style={{padding:'1rem'}}>
                        <Loading type="spinner" />
                    </div>
                    :null
                }
                {
                    contentList?.length==0&&!remoteLoading?
                    <div className="fx-box fx-jc-c fx-ai-c font-size13 font-color7" style={{padding:'1rem'}}>
                        这里空空如也
                    </div>
                    :null
                }
                <CircleList  circleList={contentList||[]} isVip={circleInfo.status==2} onShouldPay = {(item)=>{
                    // console.log('......on should pay');
                    setCurrenItem(item);
                    setShowPayDialog(true);
                }}/>
            </div>
            
            <DepositDialog visible={showDepositDialog} onClose={()=>{
                setShowDepositDialog(false);
            }}/>

            <Popup 
            style={{backgroundColor:'transparent'}}
            //
            visible={showPayDialog} onClose = {()=>{
                setShowPayDialog(false);

            }}>
                <div className="c-p-dialog-2">
                    <img className='c-p-dialog-img' src='/static/images/fu-dialog-back.png'/>
                    <div style={{position:'absolute',left:0,right:0,top:0,bottom:0}}>
                        <img src={circleInfo.headUrl} className='c-p-dialog-head-2'/>
                        <div className="c-p-dialog-2-desc">
                            <div className="font-size15 fx-box fx-jc-c">
                                打赏 <span style={{color:'#FFC200'}}>{currentItem?.price}</span> 金币 解锁本条内容
                            </div>
                            <div className="c-p-dialog-2-desc2 fx-box fx-jc-c font-size13">
                            已有{circleInfo.joinedUserCount}人解锁查看
                            </div>
                        </div>
                       
                        
                        {circleInfo?.annualPrice>0?<div className="c-p-confirm-submit-container fx-box fx-jc-c fx-ai-c">
                            <div className="c-p-confirm-submit font-size13" onClick={buyAnnual}>
                                {circleInfo?.annualPrice}金币/永久 开通圈内会员
                            </div>
                        </div>:null}
                        <div className="c-p-confirm-submit2-container fx-box fx-jc-c fx-ai-c">
                            <div className="c-p-confirm-submit2 font-size13" onClick={()=>{
                                
                                charge({
                                    type:'102',
                                    opusId:currentItem.id+'',
                                    modelId:circleInfo?.id+''
                                }).then(data=>{
                                    
                                    if(data.code==701){
                                        setShowPayDialog(false);
                                        setShowDepositDialog(true);
                                    }
                                    else if(data.code==0){
                                        currentItem.isReward =1;
                                        setShowPayDialog(false);
                                      
                                        Toast({
                                            message: '已购买成功',
                                            icon: <Image width={50} src="/static/images/toast-icon.png" />,
                                        });
                                        
                                    }
                                    
                                })
                            }}>
                                打赏 {currentItem?.price} 金币查看
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </Popup>
            
            {
                showDialog?<IntimacyDialog 
                intimacy={detail?.user?.userIntimacy}
                intimacyLine={detail?.user?.intimacy}
                modelHead = {detail?.user?.headUrl}
                myHead={profile.headUrl||DefaultHeadUrl}
                wx = {detail?.user?.wechat}
                onClose={()=>{
                     setShowDialog(false)
                }}></IntimacyDialog>:null
            }
            <VipDialog visible={showVipDialog} onClose={()=>{setShowVipDialog(false)}}
             onBuy = {buyAnnual}
             bought={circleInfo?.status==2}
             price={circleInfo?.annualPrice}
             />
        </div>
    )
}
export default ModelCircleHome;