import { Popup } from "react-vant"
import './vip-dialog.css';
const vip_dialog_icon = '/static/images/vip-dialog-icon.png';



export const VipDialog =(props)=>{
    return(
        <Popup visible={props.visible} style={{borderRadius:12}} onClose={()=>props.onClose()}>
            <div className="vip-dialog fx-box fx-fd-c">
                <div className='fx-box fx-ai-c fx-jc-c'>
                    <img className="icon" src={vip_dialog_icon}></img>
                </div>
                <div className='fx-box fx-ai-c fx-jc-c'>
                    <div className="title">圈内会员</div>
                </div>
                
                <div>
                    <div className="small-header">
                    会员权益
                    </div>
                    <div className="content">
                    ·在有效期内可免费查看本圈中圈主的付费内容
                    </div>
                    <div className="small-header">
                    购买须知
                    </div>
                    <div className="content">
                        <p>·购买的圈内会员仅限于当前圈子有效；</p>
                        <p>·付费后，虚拟商品原则上不予退款，如有争 议，请联系平台</p>
                    </div>
                    {
                        props.bought?
                        <>
                        <div className="small-header">
                            到期时间
                        </div>
                        <div className="content">永久</div>
                        </>
                        :null
                        
                    }
                </div>
                {
                    props.bought?
                    <div className='fx-box fx-ai-c fx-jc-c' style={{marginTop:'auto'}} >
                        <div className='submit fx-box fx-ai-c fx-jc-c' onClick = {()=>{props.onClose()}}>
                                <span>我知道了</span>
                        </div>
                    </div>
                    :
                    <div className='fx-box fx-ai-c fx-jc-c' style={{marginTop:'auto'}}>
                        <div className='submit fx-box fx-ai-c fx-jc-c' onClick = {()=>{
                            props.onClose();
                            props.onBuy();}}>
                                <span>{props.price}金币/永久 </span><span>开通圈内会员</span>
                        </div>
                    </div>
                }
                
            </div>
        </Popup>
    )
}