import React, { useEffect ,useState} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import * as service from '../../../service';
import './Hint.css';
const Hint=()=>{
    const profile = JSON.parse(localStorage.getItem('profile'));
    
    const [blist,setList] = useState(null);
    useEffect(()=>{
        if(!blist){
            service.getNoticeList().then(data=>{
                console.log(data);
                setList(data.noticeList);
            })
        }
    })
    const navigate = useNavigate();
    return (
        <div className="hint-comment-page hide-x fx-box fx-fd-c">
           <Navigation style={{paddingTop:30}} theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'通知'}></Navigation>
           <div className='full-r-w fx-box fx-1 fx-fd-c' style={{paddingTop:'0.8rem'}}>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='commentItem fx-box fx-jc-sb fx-ai-c'>
                        
                            <div className='fx-box fx-1 fx-fd-c' onClick={()=>{
                                navigate('/hint/'+item.id,{state:{item}})
                            }}>
                            
                                <div className='black-userid fx-box fx-fd-r  fx-jc-sb'>
                                    <div className='font-size15 fx-1'>{item.title}</div>
                                
                                </div>
                                
                                <div className='font-size13'>
                                        {item.content}
                                </div>
                            
                            </div>
                        
                    </div>)
                    })
                 }
           </div>
           
        </div>
      );
}

export default Hint;