import { fuUserPost,fuPost } from "./base";


export const statusText=(statusCode)=>{
    switch(statusCode){
        case '0':return '离线';
        case '1':return '在线';
        case '2':return '在聊';
        case '3':return '活跃';
        default:return '勿扰'
    }
}

export const personHead = (item)=>{
    
    const a= (item.vedioHeadImgUrl&&item.vedioHeadImgUrl.length&&item.vedioHeadImgUrl[0])||item.headUrl;
    return a.replace('http://','https://');
    
}


export const getCheckCode = (area,mobileNumber)=>{
    const url = 'front/account/getMobileChkCode.json';
    const requestParams = {
        "mobile" : mobileNumber,
        "code" : "86",
    }
    return fuPost(url,requestParams).then(data=>{
        console.log(data);
        return data;
    }).catch(err=>{
        console.error(err);
        throw new Error();
    });
}

export const login = (mobileNumber,verifyCode) =>{
    const url = 'front/account/login.json';
    const requestParams = {
        "mobile" : mobileNumber,
        "code" : "86",
        "mobileChkCode" : verifyCode,
    }
    return fuPost(url,requestParams);
}

export const OneOnOneList = ()=>{
    const tuijianUrl = 'user/onlineList.json';
    
    const requestParams = {

        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10'
    }
    return fuUserPost(tuijianUrl,requestParams).then(data=>{
        return data;
    })
}

export const OneOnOneNewList = ()=>{
    const url = 'user/model/newest.json';
    /*
    params[@"tagId"] = @(self.tagId);
        params[@"start"] = @(max);
        params[@"pageSize"] = @(10);
    */
    const requestParams = {

        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        //tagId:,
        start:'0',
        pageSize:'10'

    }
    return fuUserPost(url,requestParams).then(data=>{
        console.log('\n\n\n');
        console.log(data);
        return data;
    })
}

export const loadOneononeNewMore = (max)=>{
    const url = 'user/model/newest.json';
    /*
    params[@"tagId"] = @(self.tagId);
        params[@"start"] = @(max);
        params[@"pageSize"] = @(10);
    */
    const requestParams = {
        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        //tagId:,
        start:max+'',
        pageSize:'10'

    }
    return fuUserPost(url,requestParams).then(data=>{
        return data;
    })
}
export const loadOneononeMore = (maxTime)=>{
    const tuijianUrl = 'user/onlineList.json';
    const profile = JSON.parse(localStorage.getItem('profile'));
    const requestParams = {
        type:'1',
        maxTime:''+maxTime,
        count:'10'
    }
    return fuUserPost(tuijianUrl,requestParams).then(data=>{
        return data;
    })
}

export const OneOnOneOtherList = (tag)=>{
    const url = 'user/model/tagList.json';
    const requestParams = {
        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        tagId:tag+'',
        start:'0',
        pageSize:'10'
    }
    return fuUserPost(url,requestParams).then(data=>{
        return data;
    })
}

export const loadOneononeOtherMore = (max,tag)=>{
    const url = 'user/model/tagList.json';
    const requestParams = {
        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        tagId:tag+'',
        start:max+'',
        pageSize:'10'
    }
    return fuUserPost(url,requestParams).then(data=>{
        return data;
    })
}

window.fucircle=window.fucircle||{};

const appStartPromise =function(){
    return new Promise((resolve,reject)=>{
        const a=setTimeout(()=>{
            reject(new Error('get device timeout'));
        },2000);
        
        window.fucircle.getDeviceIdCallback=(ret)=>{
            clearTimeout(a);
            resolve(ret);
        };
        window?.ReactNativeWebView?.postMessage('getDeviceID');
    })
}


export const appStart =()=>{
    let deviceId='';
    return appStartPromise().then(ret=>{
        deviceId=ret;
    }).catch(err=>{
        console.error(err);
        deviceId=err?.message||'ee';
    }).then(()=>{
        console.log('%c [DEBUG]appstart:deviceId:%o','color:green',deviceId);
        const url = 'user/appStart.json';
        // const profile = JSON.parse(localStorage.getItem('profile'));
        const param = {
            registrationID:deviceId,
            packageName:'com.futrue.uni'
        }
        return fuUserPost(url,param)
    });
}

export const getPersonDetail = (modelId)=>{
    const url = 'user/queryOneToOnePageInfo.json';
    const param = {
        modelId:modelId+''
    }
    return fuUserPost(url,param);
}

export const getModelDetail = (modelId)=>{
    const url = 'user/queryModelPageInfo.json'
    const param = {
        modelId:modelId+''
    }
    return fuUserPost(url,param);
}

export const getModelPost = (modelId)=>{
    //const url = 'user/queryModelTrend.json'
    const url = 'trend/queryByModelPaging.json'
    const param = {
        modelId:modelId+'',
        start:'0',
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const queryModelComment = (modelId)=>{
    const url = 'user/evaluation/queryEvaluationList.json';
    const param ={
        start:'0',
        pageSize:'10',
        modelId
    }
    return fuUserPost(url,param);
}

export const loadOpusList = (start='0')=>{
    
    
        const url ='index/queryTrendList.json';
        // const profile = JSON.parse(localStorage.getItem('profile'));
        const param = {
            start:'0',
            pageSize:'10'
        }
        return fuUserPost(url,param);
        
}

export const loadFollowOpusList = (start=0)=>{
    //#define queryRelationTrend @"index/queryRelationTrend.json"
    const url = 'index/queryRelationTrend.json';
    const param = {
        start:''+start,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const loadOpusListMore = (count='0') =>{
    
        const url ='index/queryTrendList.json';
        // const profile = JSON.parse(localStorage.getItem('profile'));
        const param = {
            start:''+count,
            pageSize:'10'
        }
        return fuUserPost(url,param);
    
}

export const loadFollowOpusListMore = (count='0') =>{
    const url = 'index/queryRelationTrend.json';
    const param = {
        start:''+count,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const loadOpusListOfModel = (modelId,start='0')=>{
    
    const url = 'user/queryModelTrend.json';
        const param ={
            modelId,
            start,
            pageSize:'10'
        }
    return fuUserPost(url,param);
    
}

export const loadOpusListMoreOfModel = (modelId,count) =>{
    
        const url = 'user/queryModelTrend.json';
        const param ={
            modelId:modelId+'',
            start:count+'',
            pageSize:'10'
        }
        return fuUserPost(url,param);
    
    
}

export const charge = (payload)=>{
    const url = 'wealth/transaction.json';

    const param = Object.assign({
        opusId:'0',
        giftId:'0',
        nmbs:'1',
        im:'0',
        liveId:'0'
    },payload);
    console.log('[TRAE]charge param:',param)
    return fuUserPost(url,param).then(r=>{
        console.warn('[TRACE]fuUserPost return:',r);
        return r;
    });
}

export const giveGift = (modelId,giftId,nmbs=1,liveId='0')=>{
    const url = 'wealth/transaction.json';
    return charge({modelId,giftId,liveId:liveId,nmbs:''+nmbs,type:'4'})
}

export const queryMyselfInfo = ()=>{
    const url ='user/queryMyselfInfo.json';
    const param = {}
    return fuUserPost(url,param);
}

export const follow = (targetId)=>{
    const url = 'relation/buildRelation.json';
    const param = {
        followsUserId:targetId+''
    }
    return fuUserPost(url,param);
}

export const unfollow = (targetId)=>{
    const url = 'relation/delRelation.json';
    const param = {
        followsUserId:targetId+''
    }
    return fuUserPost(url,param);
}

export const updateUser = (param)=>{
    /*
        if (self.isName) {
            params[@"nickName"] = self.nickName.text;
        }else
        {
            params[@"personalSign"] = self.nickName.text;
        }

        [[JxRequestTool sharedHelper] sendAsyncRequest:modifyUserInfo params:params success:^(id json) {
    */
    const url = "user/modifyUserInfo.json";

    console.log("[update user]:",param);
    
    return fuUserPost(url,param).then(data=>{
        console.log('return:',data);
        return data;
    });
}

export const rank = (type) =>{
    const url = 'ranking/anchor.json';
    const param = {type};
    
    return fuUserPost(url,param).then(data=>{
        
        console.log('rand result:',data);
        return data;
    })
}

export const rich = (type) =>{
    const url = 'ranking/rich.json';
    const param = {type};
    return fuUserPost(url,param).then(data=>{
        console.log('rich result:',data);
        return data;
    })
}

export const switchDND =(checked)=>{
    const url = 'user/operateDisturb.json';
    const param = {
        disturb:!!checked?'1':'0'
    };
    return fuUserPost(url,param).then(data=>{
        console.log('rich result:',data);
        return data;
    })
}

export const suggest = ()=>{
    return Promise.resolve();
}

export const cashVerify = (param)=>{
    const url = 'wealth/cashVerify.json';

    return fuUserPost(url,param);
}
export const queryMyWealth = ()=>{
    const url = 'wealth/userWealth.json';
    return fuUserPost(url,{})
}

export const getWealthMobileCheckCode = ()=>{
    const url = 'wealth/getMobileChkCode.json';
    return fuUserPost(url,{});
}

export const banUser = (id)=>{
    const url = 'user/operateBlack/addBlackUser.json';
    const param = {
        blackUserId:id+''
    };
    return fuUserPost(url,param);
}

export const banList = (start)=>{
    const url = 'user/operateBlack/blackList.json';
    const param = {
        start:start+'',
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const unban = (userId)=>{
    const url = 'user/operateBlack/removeBlackUser.json';
    const param = {
        blackUserId:userId+''
    }
    return fuUserPost(url,param);
}

export const accountDetail = (start)=>{
    const url = 'budget/queryBudgetList.json';
    const param = {
        payType:'1',
        start:start+'',
        pageSize:'20'
    }
    return fuUserPost(url,param);
}

//申请成为主播
//用于主播认证功能
export const  applyHost = (param) =>{
    const url ='user/applyAuthenticationByUser.json';
    return fuUserPost(url,param);    
}

//获取分享信息
export const share = ()=>{
    const url = 'user/share.json';
    return fuUserPost(url,{});
}

//搜索
export const search = (searchCriteria)=>{
    const url = 'user/queryModelByNickname.json'
    const param = {
        nickName:searchCriteria
    }

    return fuUserPost(url,param);
}

export const submitReview = (modelId, type, review,liveId) =>{
    const url = 'user/evaluation/addEvaluation.json';
    const param = {
        liveId,
        modelId,
        type,
        evaluation:review 
    }
    return fuUserPost(url,param);
}
//like/dislike 动态
/*
//收藏
#define addCollect @"user/collect/addCollect.json"

//取消收藏
#define delCollect @"user/collect/delCollectByOpusId.json"
*/
export const collectActivity = (opusId)=>{
    const url = 'user/collect/addCollect.json';
    const param = {
        opusId
    }
    return fuUserPost(url,param);
}
export const uncollectActivity = (opusId)=>{
    const url = 'user/collect/delCollectByOpusId.json';
    const param = {
        opusId
    }
    return fuUserPost(url,param);
}

export const commentsByID = (opusId,start=0)=>{
    const url = 'user/comment/queryCommentList.json';
    const param = {
        opusId,
        start,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const addComment = (opusId,content)=>{
    const url = 'user/comment/addComment.json';
    const param = {
        opusId,
        content
    }

    return fuUserPost(url,param);
}

export const queryMessageCenter = ()=>{
    const url = 'user/queryMessageCenter.json';
    const param = {

    }
    return fuUserPost(url,param);
}

export const getNoticeList = (start=0)=>{
    const url = 'im/noticeList.json';
    const param ={
        start,
        pageSize:'50'
    }
    return fuUserPost(url,param);
}

export const getHint = (id)=>{
    const url = "im/noticeInfo.json";
    const param = {
        id:''+id
    }
    return fuUserPost(url,param);
}

export const getNotice = (id)=>{
    const url ='/im/notification.json';
    const param = {
        id:''+id
    }
    return fuUserPost(url,param);
}


export const convList =(start=0)=>{
    const url = 'conversation/queryConversationList.json';
    const param = {
        start,
        pageSize:'25'
    }
    return fuUserPost(url,param);
}

export const withdrawRecord=(start=0)=>{
/*
 params[@"start"] = @(start);
    params[@"pageSize"] = @(10);
    params[@"status"] = @"ALL";
    params[@"userId"] = @(model.userId);

*/
    const url = 'financial/queryWithdrawCash.json';
    const param = {
        start:start+'',
        pageSize:'10',
        status:'ALL',
    }

    return fuUserPost(url,param);
}

export const addressList =(start,kind)=>{
    /*
    //好友列表
    #define userFriendList @"relation/userFriendList.json"

    //关注列表
    #define userAttentionList @"relation/userAttentionList.json"

    //粉丝列表
    #define userFansList @"relation/userFansList.json"
    */
   const urls = ['relation/userFriendList.json','relation/userAttentionList.json','relation/userFansList.json'];
   const url = urls[kind];
   const param = {
    start:start+'',
    pageSize:'10',
   }
   return fuUserPost(url,param);
}

export const customerList = (modelId,start) =>{
    const url = 'rank/consumeForPlayer.json';
    const param = {
        start:start,
        pageSize:'10',
        playerId:modelId+''
    }
    return fuUserPost(url,param);
}

export const giftList = (modelId)=>{
    const url = 'rank/reciveGifts.json';
    const param = {
        simple:'0',
        playerId:modelId+'',
    }

    return fuUserPost(url,param);
}

export const circleQuery = (queryType,start=0) =>{
    const url = 'circle/queryUserCircle.json';
    const param = {
        queryType:queryType+'',
        start:start+'',
        pageSize:'8'
    }
    // console.log('load....querytype:',queryType,' start:',start);
    return fuUserPost(url,param);
}

export const circleContentQuery = (modelId,queryType=0,start=0)=>{
    /*
    //模特-fu圈列表 分页 start  开始页码     pageSize 数量
    #define kqueryTrend @"circle/queryTrendByCondition.json"
    */
   const url = 'circle/queryTrendByCondition.json';
   const param = {
    queryType:queryType+'',
    start:start+'',
    pageSize:8,
    modelId:modelId+''
   }
   console.log('%c circlequery param:%o','color:green',param);
   return fuUserPost(url,param);
}

export const joinCircle = (modelId)=>{
    return charge({
        modelId,type:'100'
    });
}


export const recommendList = (size)=>{
    const url = "user/recommendList.json";

    const param = {
        maxTime:new Date().valueOf()*2+'',
        size : size+''
    }
    return fuUserPost(url,param);
}

export const randomMatch = ()=>{
    const url = 'user/randommatch.json';
    const param = {
        maxTime:new Date().valueOf()+''
    }
    return fuUserPost(url,param);
}

export const switchHiddenMode = (value)=>{
    /*
        params[@"userId"] = @(model.userId);
    params[@"switchType"] = @"STEALTH";
    params[@"switchValue"] = on ? @"ON" : @"OFF";
    
    */

    const url = 'user/set_switcher.json';
    const param = {
        switchType:'STEALTH',
        switchValue:value
    }

    return fuUserPost(url,param);
}

export const getCallUserInfo =(modelId) =>{
    //const url = 'live/getCallStatus.json';
    const url = 'user/getCallUserInfo.json';
    const param = {
        callUserId:modelId+''
    }
    console.log('getCallUserInfo:',param);
    return fuUserPost(url,param);
}

export const finishLive=(liveId,channelName,modelId,viewerId)=>{

    
    // console.warn('finishLive no liveId:',channelName,modelId,viewerId)
    
    const url = 'live/finishLive.json';

    const param = {
        liveId,
        channelName,
        modelId,
        viewerId
    }
    console.log('%c FINISH LIVE IS CALLED.','color:green',param);
    return fuUserPost(url,param);
}

export const liveGetTimes = (modelId,viewerId,channelName,conversationId,expTime)=>{
    /*
        if (self.model.userType == 1) {
        
        params[@"userType"] = @(0);
        params[@"modelId"] = self.model.account;
        params[@"viewerId"] = @(model.userId);
    }else
    {
        params[@"userType"] = @(model.userType);
        params[@"modelId"] = @(model.userId);
        params[@"viewerId"] = self.model.account;
    }
    
    params[@"channelName"] = channel;
    params[@"conversationId"] = self.model.conversationId;
    params[@"expTime"] = @(self.currentExpTime);
    */
   const url = 'live/getTimes.json';
   const param = {
    userType:'0',
    modelId:modelId+'',
    viewerId:viewerId+'',
    channelName:channelName+'',
    conversationId:'',
    expTime:expTime+''
   }
   //console.log('%c liveGetTimes:%o','color:orange',param);
   return fuUserPost(url,param);
}

export const checkVague = (modelId) =>{
    const url = 'live/userSetVague.json';
    const param ={
        modelId,
        vague:'1'
    }
    console.log('%c setVague:%o','color:green',param);
    return fuUserPost(url,param);
}

export const opusDetail = (id)=>{
    const url = 'circle/querytrenddetail.json';
    const param = {
        opusId:""+id
    }
    return fuUserPost(url,param);
}

export const circleComment=(commentType,opusId,content,commentId)=>{
    const url = 'circle/publishTrendComment.json';
    const param = {
        opusId:''+opusId,
        commentType:''+commentType,
        content,
    }
    if(commentType=='SUB_COMMENT')param.commentId=commentId;
    console.log('comment post:',param);
    return fuUserPost(url,param);
}

export const addMessageByUser = (modelId,content)=>{
    const url ='message/addMessageByUser.json';
    const param = {
        currtime:(new Date().valueOf())+'',
        messageUserId:''+modelId,
        content
    }
    return fuUserPost(url,param)
}

export const kqueryChatPrice =(myEMId,targetEMId)=>{
    const url = "message/queryChatPrice.json";
    const param = {
        currentAccount:myEMId,
        receiverAccount:targetEMId,
    }
    return fuUserPost(url,param);
} 

export const kqueryShareInfo = ()=>{
    const url = "circle/queryshareinfo.json";
    const param ={}
    return fuUserPost(url,param);
}

export const queryMyInviteData =()=>{
    const url = 'user/queryMyInviteData.json';
    const param = {};
    return fuUserPost(url,param);
}

export const writeInviteCode=(uo)=>{
    const url ='user/writeInviteCode.json';
    const {userid} =uo;

    const param = {
        inviteCode:userid,
        summaryType:'0'
    }
    return fuUserPost(url,param);
}
/*
//获取普通用户个人信息
#define queryUserPageInfo @"user/queryUserPageInfo.json"
params[@"showHiddenInfo"] = false;//默认false
self.isStealth = [json[@"stealth"] boolValue]; //是否是隐身状态
*/
export const queryUserInfo = (userId)=>{
    const url = 'user/queryUserPageInfo.json';
    const param = {
        queryUserId:''+userId
    }
    return fuUserPost(url,param);
}

export const editNoteName =(userId,name)=>{
    /*
     params[@"userId"] = @(model.userId);
        params[@"noteUserId"] = userId;
        params[@"noteName"] = self.beiZhuName.text;
        
    */
    const url = "user/note/editNoteName.json";
    const param = {
        noteUserId:userId,
        noteName:name
    }
    console.log('%c edit note name param:','color:green',param);
    return fuUserPost(url,param);
}

export const OneOnOneSameCityList=(geoLocation)=>{
    return Promise.resolve().then(()=>{
        if(!geoLocation)throw new Error('没有地址信息')
        /*

        */
       const url = 'user/location/searchNearbyModel.json';
       const param={
        latitude:geoLocation.latitude+'',
        longitude:geoLocation.longitude+'',
        country:geoLocation.country+'',
        city:geoLocation.city+'',
        provence:geoLocation.province+'',
        district:geoLocation.district+'',
        start:'0',
        pageSize:'8',
       }
       return fuUserPost(url,param);
    })
}
export const loadOneononeSameCityMore = (geoLocation,start)=>{
    return Promise.resolve().then(()=>{
        if(!geoLocation)throw new Error('没有地址信息')
        /*

        */
        const url = 'user/location/searchNearbyModel.json';
        const param={
         latitude:geoLocation.latitude+'',
         longitude:geoLocation.longitude+'',
         country:geoLocation.country+'',
         city:geoLocation.city+'',
         provence:geoLocation.province+'',
         district:geoLocation.district+'',
         start:''+start,
         pageSize:'8',
        }
        return fuUserPost(url,param);
    })
    
}

export const queryBanner = ()=>{
    const url = 'system/querybanner.json';
    const param = {position:2}
    return fuUserPost(url,param);
}

export const spam = ({modelId,liveId,callUserId,operate,type,reCode,channelName})=>{
    /*
    params[@"userId"] = @(userId);
    params[@"modelId"] = @(modelId);
    params[@"liveId"] = @(liveId);
    params[@"callUserId"] = @(callUserId);
    params[@"type"] = @(type);
    params[@"operate"] = @(operate);

    if (reCode > 0) {
        params[@"reCode"] = @(reCode);
    }
    
    if (channelName.length > 0) {
        params[@"channelName"] = channelName;
    }
    
    */
   const url = 'live/spam/anchor';
   const param={
    modelId,
    liveId:(liveId||0),
    callUserId,
    type,
    operate,
    reCode,
    channelName
   };
   return fuUserPost(url,param);
}