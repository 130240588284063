import { HashRouter as Router,Routes, Route,Outlet ,Navigate, useNavigate} from 'react-router-dom'

import React, { useEffect,useState } from 'react';
import { Popup, Toast } from 'react-vant';

import  {TabBar,TabBar2} from  '../components/tabbar/tabbar';

import  Home from '../pages/Home/Home';
import  Mine from '../pages/Mine/Mine';

import  Live from '../pages/Live/Live';
import  Activity from '../pages/Activity/Activity';
import ModelActivity from '../pages/Activity/ModelActivity';

import  Msg from '../pages/Msg/Msg';

import  Login from '../pages/Login/Login';

import One2one from '../pages/Live/One2one';

import Person  from '../pages/Person/person';
import PersonEditor from '../pages/Person/edit/personData';

import Launch from '../pages/Launch/Launch';

import UserRate from '../pages/UserRate/UserRate';

import Cashout  from '../pages/Cashout/Cashout';

import Ranking from '../pages/Ranking/Ranking';

import Wallet from '../pages/Wallet/Wallet';

import KYC from '../pages/StarCertificate/KYC'
import Album from '../pages/StarCertificate/Album';

import * as service from '../service';

import {rtm} from '../components/agora/rtm'
import WebIM from '../components/easemob/WebIM';

import Feedback from '../pages/Feedback/Feedback';

import Setting from '../pages/Setting/Setting';
import AboutUs from '../pages/AboutUs/AboutUs';
import Share from '../pages/Share/share';


import BlackList from '../pages/BlackList/BlackList';
import StarCertificate from '../pages/StarCertificate/StarCertificate';
import AccountList from '../pages/Account/AccountList';
import Chat from '../pages/Chat/Chat';
import Pay from '../pages/Pay/Pay';
import Search from '../pages/Search/Search';
import Comment from '../pages/Comment/Comment';
import Hint from '../pages/MsgCenter/Hint/Hint';
import Notice from '../pages/MsgCenter/Notice/Notice';
import Record from '../pages/MsgCenter/Record/Record';
import WithdrawList from '../pages/Withdraw/WithdrawList';
import AddressBook  from '../pages/AddressBook/AddressBook';
import Fans from '../pages/Fans/Fans';

import { StarContext } from '../pages/StarCertificate/StarContext';
import {connect} from 'react-redux';
import Gifts from '../pages/Gifts/Gifts';

import Circle from '../pages/Circle/Circle';
import ModelCirclePurchase from '../pages/Circle/ModelCirclePurchase';
import ModelCircleHome from '../pages/Circle/ModelCircleHome';
import { OpusDetail } from '../pages/Circle/OpusDetail';

import { PersonContext } from '../pages/Person/PersonContext';
import { ChargeContext } from '../pages/Person/ChargeContext';

import { TestPage } from '../pages/unitest/Test';

import Live2 from '../pages/Live/Live2';
import HintOne from '../pages/MsgCenter/Hint/HintOne';
import NoticeOne from '../pages/MsgCenter/Notice/NoticeOne';
import { GlobalContext } from '../GlobalContext';
import MyCircle from '../pages/Circle/MyCircle';
import { ConnectionContext } from '../ConnectionContext';
import WebView from '../pages/WebView/Web';
import MediaAuthDialog from '../components/meida-auth-dialog/MediaAuthDialog';
import { AuthManager } from '../pages/Authman';


import {FUBase} from '../pages/FUBase';


// console.log('[DEBUG]local profile:',localStorage.getItem('profile'));

class HomeLayoutRaw extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={logined:true,showMediaDialog:false};
    }
    
    closeMAD(){
        window?.ReactNativeWebView?.postMessage('openAudio');
        window?.ReactNativeWebView?.postMessage('openCamera');
        this.setState({showMediaDialog:false,showModelNoticeDialog:false});
    }

    componentDidMount(){
        const profile = JSON.parse(localStorage.getItem('profile'));
        // console.log('[DEBUG]2profile:',profile);
        if(!profile||!profile.userId){
            this.setState({logined:false});
            console.log('returned...');
            return;
        }
        
        // if(!this.props.app.WebMediaPermissionAllowed&&!this.props.app.WebMediaPermissionRequested){
        //     setTimeout(()=>{
        //         service.checkWebMediaPermission().then((ret)=>{
        //             ret&&this.props.dispatch({type:'VIDEO_PERMISSION_ALLOW'})
                    
        //         }).catch(err=>{
        //             this.props.dispatch({type:'VIDEO_PERMISSION_NOTALLOW'})
        //             this.setState({showMediaDialog:true})
        //         })
        //     },4000)
        // }
    }
    render(){
        const {logined,showMediaDialog,showModelNoticeDialog,redirect,redirectPayload} = this.state;

        if(redirect){
            this.state={};
            if(redirectPayload){
                return <Navigate to={redirect} state={{url:redirectPayload}}/>
            }
            else 
            return <Navigate to={redirect}/>
        }

        if(!logined)
        {
            (async ()=>{
                localStorage.removeItem('profile');
                localStorage.removeItem('appStart');
                await rtm.logout();
                await WebIM.conn.close();
            })();    

            return (
                <Navigate to='/login' replace={true} />
            )
        }
        const o2ohome = localStorage.getItem('o2ohome');
        return (
            <div className='app-home' >
               <Outlet/>
               {
                o2ohome=='true'?<TabBar2></TabBar2>:<TabBar></TabBar>
               }
               
               
                <Popup visible={this.props.imError}>
                    <div className="fx-box fx-ai-c fx-jc-c fx-fd-c font-size15" style={{backgroundColor:'white'}}>
                        <div style={{padding:20}}>
                            <span>
                                IM连接失败, 请重新登录
                            </span>
                        
                        </div>
                        <div style={{padding:20}}>
                            <button onClick={()=>{
                                this.setState({logined:false});
                            }}>重新登录</button>
                        </div>
                    </div>
                </Popup>
                <MediaAuthDialog title='必须开启相机/麦克风权限才能进行通话' visible={showMediaDialog} onClose={
                    
                    this.closeMAD.bind(this)
                }/>
                <Popup visible={!!this.props.banner} style={{backgroundColor:'transparent',zIndex:3000}}>
                    <div className='banner-dialog'>
                        <img src={this.props.banner?.resourceUri} onClick={
                            ()=>{
                                if(this.props.banner?.linkType==1){
                                    this.setState({redirect:'/web',redirectPayload:this.props.banner?.link?.outerLinkUri})
                                }
                                else {
                                    const cmd = 'openURL:'+this.props.banner?.link?.outerLinkUri;
                                    window?.ReactNativeWebView?.postMessage(cmd);
                                }
                            }
                        }></img>
                        <div className='fx-box fx-jc-c fx-ai-c banner-dialog-close'>
                            <img src='/static/images/close-banner-btn.png' onClick={()=>{
                                this.props.dispatch({type:'BANNER_SHOWED'});
                            }}></img>
                        </div>
                    </div>
                </Popup>               
            </div>
          );
    }
}

const HomeLayout = connect((state)=>{
    return {app:state.app,banner:state.app.banner};
},dispatch=>({dispatch}))(HomeLayoutRaw)



const Layout=(props)=>{
    
    const [state,setState] = useState({});
    const [review,setReview]  = useState(false);
    const [pay,setPay] = useState(false);
    const [live,setLive] = useState(null);
    const [deposit,setDeposit] = useState(null);
    const [rechargePag,setRechargePag] = useState(null);
    //const [consumePagFile,setConsumePagFile] = useState(null);

    const [pagFile1,setPagFile1] = useState(null);
    const [pagFile2,setPagFile2] = useState(null);

    const [userDidTakeScreenShot,setUserDidTakeScreenShot] = useState(null);
    const [userDidChangeCaptured,setUserDidChangeCaptured] = useState(false);
    const [showModelNoticeDialog,setShowModelNoticeDialog] = useState(false);
    let consumePagFile;

    useEffect(()=>{
        const profile = JSON.parse(localStorage.getItem('profile'));
        if(profile&&profile.userType==1){
            console.log('%c [DEBUG]show profile:','color:blue',profile);
            setShowModelNoticeDialog(true);
        }
        if(props.lastRecharge){
            console.log('%c show last recharge:','color:green',props.lastRecharge);

            document.getElementById('page_show_recharge_container').style.display='block';
            const llCanvas = document.getElementById('pag_show_recharge');

            llCanvas.width=358;
            llCanvas.height=51;
            const llpagUrl = '/static/pag/show_recharge.pag';
            if(!rechargePag){
                // Fetch pag file.
                fetch(llpagUrl).then((response) => response.arrayBuffer()).then((llbuffer)=>{
                    console.log('pag fetched................');
                    window.PAG.PAGFile.load(llbuffer).then(llpagFile=>{
                        setRechargePag(llpagFile);
                        const nameDoc = llpagFile.getTextData(0);
                        // console.log('%c textDoc:%o','color:green',textDoc.text);
                        nameDoc.text = props.lastRecharge.uname;
                        llpagFile.replaceText(0,nameDoc);
                        const amountDoc = llpagFile.getTextData(1);
                        // console.log('%c textDoc:%o','color:green',textDoc.text);
                        amountDoc.text = '充值了'+props.lastRecharge.amount+'金币';
                        llpagFile.replaceText(1,amountDoc);

                        return window.PAG.PAGView.init(llpagFile, llCanvas);
                    }).then(async llpagView=>{
                        llpagView.addListener('onAnimationEnd',()=>{
                            const div=document.getElementById('page_show_recharge_container');
                            if(div)div.style.display='none';
                            props?.dispatch&&props.dispatch({type:'clear_ad'}) 
                        })
                        await llpagView.setRepeatCount(1);
                        return llpagView.play();
                    }).catch(err=>{
                        console.log(err);
                    });
                }).catch(err=>{
                    console.log('fetch error:',err);
                });
            }
            else {
                const nameDoc = rechargePag.getTextData(0);
                // console.log('%c textDoc:%o','color:green',textDoc.text);
                nameDoc.text = props.lastRecharge.uname;
                rechargePag.replaceText(0,nameDoc);
                const amountDoc = rechargePag.getTextData(1);
                // console.log('%c textDoc:%o','color:green',textDoc.text);
                amountDoc.text = '充值了'+props.lastRecharge.amount+'金币';
                rechargePag.replaceText(1,amountDoc);
                window.PAG.PAGView.init(rechargePag, llCanvas).then(async llpagView=>{
                    llpagView.addListener('onAnimationEnd',()=>{
                        const div=document.getElementById('page_show_recharge_container');
                        if(div)div.style.display='none';
                        props?.dispatch&&props.dispatch({type:'clear_ad'}) 
                    })
                    await llpagView.setRepeatCount(1);
                    return llpagView.play();
                }).catch(err=>{
                    console.error(err);
                })
            }
        }

        if(props.lastConsume){
            document.getElementById('page_show_consume_container').style.display='block';

            const llCanvas = document.getElementById('pag_show_consume');
            llCanvas.width=358;
            llCanvas.height=51;
            let llpagUrl;
            if(props.lastConsume.style==1){
                consumePagFile = pagFile1;
                llpagUrl = '/static/pag/show_gift.pag';
            }
            else {
                consumePagFile = pagFile2;
                llpagUrl = '/static/pag/show_gift_2.pag';
            }
                
            // Fetch pag file.
            if(!consumePagFile){
                fetch(llpagUrl).then((response) => response.arrayBuffer()).then((llbuffer)=>{
                    window.PAG.PAGFile.load(llbuffer).then(llpagFile=>{
                        if(props.lastConsume.style==1)setPagFile1(llpagFile);
                        else setPagFile2(llpagFile);

                        // setConsumePagFile(llpagFile);
                        // console.log('.......1',props.lastConsume);
                        const nameDoc = llpagFile.getTextData(0);
                        // console.log('%c textDoc:%o','color:green',textDoc.text);
                        nameDoc.text = props.lastConsume.uname;
                        llpagFile.replaceText(0,nameDoc);
    
                        const name2Doc = llpagFile.getTextData(2);
                        // console.log('%c textDoc:%o','color:green',textDoc.text);
                        name2Doc.text = props.lastConsume.toUname;
                        llpagFile.replaceText(2,name2Doc);
    
                        const descDoc = llpagFile.getTextData(3);
                        descDoc.text = '送出'+props.lastConsume.product+'X'+props.lastConsume.num;
                        llpagFile.replaceText(3,descDoc);
    
                        return window.PAG.PAGView.init(llpagFile, llCanvas);
                    }).then(async llpagView=>{
                        console.log('....play');
                        llpagView.addListener('onAnimationEnd',()=>{
                            console.log('%c page play end.','color:gray');
                            const div = document.getElementById('page_show_consume_container');
                            
                            if(div&&div.style)div.style.display='none';
                            props?.dispatch&&props.dispatch({type:'clear_ad'})
                        })
                        await llpagView.setRepeatCount(1);
                        return llpagView.play();
                    }).then(()=>{
                        // setTimeout(()=>{
                        //     const div = document.getElementById('page_show_consume_container');
                        //     if(div&&div.style)div.style.display='none';
                        //     props?.dispatch&&props.dispatch({type:'clear_ad'})
                        // },3000);
                    }).catch(err=>{
                        console.error(err)
                    });
                }).catch(err=>{
                    console.log('fetch error:',err);
                });
            }
            else {
                console.log('.....exits......consume file');

                const nameDoc = consumePagFile.getTextData(0);
                // console.log('%c textDoc:%o','color:green',textDoc.text);
                nameDoc.text = props.lastConsume.uname;
                consumePagFile.replaceText(0,nameDoc);

                const name2Doc = consumePagFile.getTextData(2);
                // console.log('%c textDoc:%o','color:green',textDoc.text);
                name2Doc.text = props.lastConsume.toUname;
                consumePagFile.replaceText(2,name2Doc);

                const descDoc = consumePagFile.getTextData(3);
                descDoc.text = '送出'+props.lastConsume.product+'X'+props.lastConsume.num;
                consumePagFile.replaceText(3,descDoc);

                window.PAG.PAGView.init(consumePagFile, llCanvas).then(async llpagView=>{
                    console.log('.......play');
                    llpagView.addListener('onAnimationEnd',()=>{
                        console.log('%c page play end.','color:gray');
                        const div = document.getElementById('page_show_consume_container');
                        if(div&&div.style)div.style.display='none';
                        props?.dispatch&&props.dispatch({type:'clear_ad'})
                    })
                    await llpagView.setRepeatCount(1);
                    return llpagView.play();
                }).catch(err=>{
                    console.log('')
                });
            }
            
        }

    },[rechargePag,setRechargePag,consumePagFile,setPagFile1,setPagFile2,props])
    



    return (
        <StarContext.Provider value={{state, setState}}>
            <PersonContext.Provider value={{review,setReview, live,setLive}}>
                <ChargeContext.Provider value={{pay,setPay}}>
                    <GlobalContext.Provider value={{deposit,setDeposit}}>
            <Router>
                <Routes>
                    <Route path="/" element={<FUBase/>}>
                        <Route path="/" index element={<Launch/>}>
                        </Route>
                        <Route  path="/app"  element={<HomeLayout/>}>
                            <Route  path="" element={<Home/>}></Route>
                            <Route  path="mine" element={<Mine/>}></Route>
                            <Route  path="msg" element={<Msg/>}></Route>
                            <Route  path="activity" element={<Activity/>}></Route>
                            
                            <Route  path="live" element={<Live/>}></Route>
                            <Route  path="circle" element = {<Circle/>}></Route>
                            {/* <Route  path="mycircle" element = {<Circle/>}></Route> */}
                        </Route>
                        <Route  path ="/activity/:id" element = {<ModelActivity/>}></Route>
                        
                        <Route path="/login" element={<Login/>}></Route>
                        <Route path="/one2one" element={<One2one/>}></Route>
                        <Route path='/person/:userId' element={<Person/>}></Route>
                        <Route path='/personedit' element={<PersonEditor/>}></Route> 
                        
                        <Route path = '/live' element={<Live2/>}></Route>

                        <Route path='/userrates' element={<UserRate/>}></Route>
                        <Route path='/chat/:id' element={<Chat/>}/>
                        <Route path='/ranking' element={<Ranking/>}></Route>

                        <Route path='/cashout' element={<Cashout/>}></Route>

                        <Route path='/wallet' element={<Wallet/>}></Route>

                        <Route path='/feedback' element={<Feedback/>}/>

                        <Route path='/setting' element={<Setting/>}/>

                        <Route path='/aboutus' element={<AboutUs/>}/>
                        <Route path = '/flows' element = {<AccountList/>}/>

                        <Route path='/kyc' element = {<KYC/>}/>
                        <Route path='/album' element = {<Album/>}/>

                        <Route path='/blacklist' element={<BlackList/>}/>
                        <Route path='/share' element={<Share/>}/>
                        <Route path ='/pay' element={<Pay/>}/>
                        <Route path = '/search' element = {<Search/>}/>
                        <Route path ='/comment/:id' element= {<Comment/>}/>
                        <Route path = '/hint' element = {<Hint/>}/>
                        <Route path ='/hint/:id' element={<HintOne/>}/>
                        <Route path = '/notice' element = {<Notice/>}/>
                        <Route path = '/notice/:id' element={<NoticeOne/>}/>
                        <Route path = '/record' element = {<Record/>}/>
                        <Route path = '/withdrawlist' element={<WithdrawList/>}/> 
                        <Route path = '/address' element={<AddressBook/>}/> 
                        <Route path = '/fans/:id' element= {<Fans/>}/>
                        <Route path = '/gifts/:id' element = {<Gifts/>}/>
                        <Route path = '/fupurchase/:id' element = {<ModelCirclePurchase/>}/>
                        <Route path = '/fu/:id' element={<ModelCircleHome/>}/>
                        <Route path = '/fuopus/:id' element={<OpusDetail/>}/>
                        <Route path = '/mycircle' element={<MyCircle/>}/>
                        <Route path = '/test' element={<TestPage/>}/>
                        <Route path = '/web' element={<WebView/>}/>
                        <Route path = '/authman' element={<AuthManager/>}/>

                        <Route path="/star-certificate" element={<StarCertificate/>}/>
                        <Route
                            path="*"
                            element={<Launch/>}
                            />
                    </Route>
                    

                </Routes>
                
                

            </Router>
            <div id='page_show_recharge_container' style={{
                display:'none',
                position:'fixed',
                top:112,
                zIndex:10000
            }}><canvas id='pag_show_recharge'></canvas></div>

            <div id='page_show_consume_container' style={{
                display:'none',
                position:'fixed',
                top:112,
                zIndex:10000
            }}><canvas id='pag_show_consume'></canvas>
            </div>
            <Popup visible={showModelNoticeDialog} style={{borderRadius:12}}>
                <div className='model-notice-dialog'>
                    <div>
                        <div className='title'>温馨提示</div>
                    </div>
                    <div>
                        <div className='desc'>已认证主播暂不支持使用简略版，请使用“Fu”进行直播</div>
                    </div>
                    <div>
                        <div className='fx-box fx-ai-c fx-jc-c'>
                            <a href='wanttopays://fu8090.net'><div className='btn-ok font-size15'>去下载</div></a>
                        </div>
                    </div>
                </div>
            </Popup>
            </GlobalContext.Provider>
            </ChargeContext.Provider>
            </PersonContext.Provider>
       </StarContext.Provider>
    )
  
}


export default 
connect((state)=>{return {
    
    profile:state.app.profile,
    one2one:state.app.one2one,
    initialized:state.app.initialized,
    initializeFail:state.app.initializeFail,
    lastRecharge:state.app.lastRecharge,
    lastConsume:state.app.lastConsume,
}},(dispatch)=>({dispatch}))(Layout);
