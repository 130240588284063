const initialState ={
    loading:false,
    error:null,
    list:null,
    banners:[{bgUrl:'/static/images/banner.jpg'}],
    tabs:[
        {
              title:'推荐',
              isActive:true,
              id:'1'
        },
        {
            title:'同城',
            isActive:false,
            id:'2'
        },
        {
              title:'新人',
              isActive:false,
              id:'3'
        },
    ],
    currentTab:0,
    status:{scrollTop:0,currentTab:0}
}
export default function(state=initialState,action){
    switch(action.type){
        case 'RELOAD_LIVE_LIST_END':
            return Object.assign({},state,{loading:false});
        case 'RELOAD_LIVE_LIST_START':
            return Object.assign({},state,{loading:true,list:[]});
        case 'LIVE_SCROLL_POSITION':
            state.status.scrollTop=action.payload;
            return state;
        case 'LIVE_RESET_LIST':
            return Object.assign({},state,{list:[]});
        case 'LIVE_RELOAD_FAIL':
            return Object.assign({},state,{loading:false,list:[],error:action.payload});
        case 'LIVE_RELOAD':
            return Object.assign({},state,{loading:true,list:[],error:null});
        case 'LIVE_LOADED':
            // console.log('%c REDUX LIVE_LOAD:%o','color:blue',action);
            state.status.scrollTop=0;
            if(action.banners){
                return Object.assign({},state,{loading:false,list:action.list,banners:action.banners,currentTab:action.tab,error:null});
            }
            else return Object.assign({},state,{loading:false,list:action.list,currentTab:action.tab,error:null});
            
        case 'LIVE_LOAD_MORE':
            // console.log('%c REDUX LIVE_LOAD_MORE:%o','color:blue',action);
            return Object.assign({},state,{list:[...state.list,...action.payload]});
        case 'LIVE_OTO_TAB':
            if(action.payload)
                return Object.assign({},state,{tabs:[...initialState.tabs,...action.payload]});
            else 
                return state;
        default:
            return state;
    }
}