import Navigation from "../../components/navigation/navigation";
import './Cashout.css';
import React, {useState,useEffect} from 'react';
import * as service from '../../service';
import { Toast } from "react-vant";
import { Link } from "react-router-dom";

const Cashout=()=>{

    const [amount,setAmount] = useState();
    const [mobileChkCode,setMobileChkCode] = useState();
    const [realName,setRealName] = useState();
    const [zfbAccount,setZfbAccount] = useState();

    const [myInfo,setMyInfo] = useState(null);
    useEffect(()=>{
        service.queryMyWealth().then(data=>{
            /*
            cash: 0
code: 0
errMsg: "success"
incomeSummary: {totalIncome: 0, interactiveIncome: 0, inviteIncome: 0, circleIncome: 0}
mobile: "18610732252"
modfiyTime: 1657614563443
realName: ""
userId: 138069
wealthXp: "0"
wealthXz: "999969252"
zfbAccount: ""
             */
            setMyInfo(data);
        })
    },[setMyInfo])
    return (
     <div className="full-r-w cash-page hide-x theme-font">
          <Navigation 
          theme={{color:'#000',bg:'#fff'}} 
          style={{paddingTop:30}}
          height={'0.5rem'} title={'提现'} right={
            <Link to='/withdrawlist'><div className="font-size10" >
                    <span>明细</span>
                </div>
            </Link>
          }>

          </Navigation>

          <div className="full-r-w fx-box fx-fd-c fx-ai-c layout-mt79">
              <div className="cash-card fx-box font-w4 fx-jc-c fx-fd-c fx-ai-c">
                  <div className="font-size15 font-color3">当前钻石</div>
                  <div className="font-size32 font-color3">{myInfo?.wealthXp}</div>
              </div>

              <div className="cash-form-item">
                 <div className="font-size15">提现金额<span className="font-color5">可提现{(myInfo&&myInfo.userInfo?.wealthXp)/10}元</span></div>
                 <div className="cash-form-input fx-box fx-ai-c fx-jc-sb">
                    <input placeholder="¥ 请输入提现金额" className="cash-input-small font-size13" 
                    value={amount}
                    onChange = {(e)=>{
                        setAmount(e.target.value);
                    }}
                    />
                    <div  className="font-size13 font-color3 cash-button btn-bg1">全部提现</div>
                 </div>
              </div>
              <div className="cash-form-item">
                 <div className="font-size15">支付宝账号</div>
                 <div className="cash-form-input fx-box fx-ai-c fx-jc-c">
                    <input placeholder="请输入支付宝账号" className="cash-input-large font-size13"
                    value={zfbAccount} onChange={(e)=>{
                        setZfbAccount(e.target.value);
                    }}
                    />
                   
                 </div>
              </div>
              <div className="cash-form-item">
                 <div className="font-size15">收款人姓名</div>
                 <div className="cash-form-input fx-box fx-ai-c fx-jc-c">
                    <input placeholder="请输入收款人姓名" className="cash-input-large font-size13"
                    value={realName}
                    onChange={(e)=>{
                        setRealName(e.target.value);
                    }}
                    />
                    
                 </div>
              </div>


              <div className="cash-form-item">
                 <div className="font-size15">手机号<span className="font-color5">{myInfo?.mobile}</span></div>
                 <div className="cash-form-input fx-box fx-ai-c fx-jc-sb">
                    <input placeholder="请输入验证码" className="cash-input-small font-size13" 
                    value={mobileChkCode}
                    onChange={(e)=>{
                        setMobileChkCode(e.target.value);
                    }}
                    />
                    <div  className="font-size13 font-color3 cash-button cash-button-send btn-bg1" 
                    onClick = {()=>{
                        service.getWealthMobileCheckCode().then(ret=>{
                            if(ret.code==0)Toast.info('验证码发送成功');
                            else Toast.info('验证码发送失败:',ret.errMsg);
                        });
                        
                    }}
                    >发送验证码</div>
                 </div>
              </div>

              <div className="cash-form-item">
                 <div className="font-size13 intimacy-tip">注意事项</div>
                 <div className="font-size10 intimacy-tip">请输入正确真是的信息，以防无法收到提现资金
钻石额度小于 10 无法提现，提现时平台会收取3%提现手续费</div>
              </div>

              <div className="cash-submit btn-bg1 font-size15 font-color3" onClick={()=>{
                const param={
                    
                    rmb:amount,
                    mobileChkCode,
                    realName,
                    zfbAccountNum:zfbAccount
                }
                service.cashVerify(param).then(ret=>{
                    if(ret.code==0)Toast.info('申请提交成功');
                    else Toast.info('申请提交失败');
                });
              }}>
                  提交申请
              </div>

          </div>
    </div>)
}
export default Cashout;