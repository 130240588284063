import React,{useState,useEffect, useContext,useRef} from 'react';
import { useNavigate,useLocation,useParams, Link} from 'react-router-dom'
import { UNSAFE_NavigationContext } from 'react-router-dom';


import { Swiper,Image,Toast,Overlay,Popup} from 'react-vant';
import UserMsgItem from '../../components/user-msg-item/user-msg-item';
import * as service from '../../service';
import {kefuID} from '../../service/config';
import {DefaultHeadUrl} from '../../service/images';
import IntimacyDialog from '../../components/gift-dialog/intimacy-dialog';
import GiftDialog from '../../components/gift-dialog/gift-dialog';

import  './person.css';
import { unfollow,follow } from '../../service';
import {GiftDict} from '../../service/config';
import Review from './review';
import { PersonContext } from './PersonContext';
import { ChargeContext } from './ChargeContext';
import { ModelLevelBadge } from '../../components/level-badge';
import { DepositDialog } from '../../components/deposit-dialg';
import { giftSend } from '../../service/im';
import { connect } from 'react-redux';
import GiftTip from '../../components/gift-dialog/gift-tip';
import VideoPlayBtnImage from '../../assets/images/video-play.png';
import MediaAuthDialog from '../../components/meida-auth-dialog/MediaAuthDialog';




const intimacy=(user)=>{
    if(!user)return '0%';
    const {intimacy,userIntimacy} = user;
    return Math.min(Math.trunc(userIntimacy*100/intimacy),100);
}

const scrollTopBase = 250;

const colorOpactityByScroll =(scrollTop)=>{

    const opacity=Math.round(Math.min(scrollTop/scrollTopBase,1)*255);
    // console.log('%c color opacity:%d %d','color:green',scrollTop,opacity);
    const result=opacity.toString(16);
    if(result.length==1)return '0'+result;
    else return result;
}

const colorInvertByScroll = (scrollTop)=>{
    const opacity=Math.min(scrollTop/scrollTopBase,1)*100;
    return `invert(${opacity}%)`;
}


const ModelStatusBadge = (props)=>{
    return (
        props.onlineStatus==1?
        <div className='personOnlineState font-size13 font-color3 layout-abs OnlineState1'>
            在线
        </div>:
        //detail?.modelInfo?.onlineStatus==0?
        props.onlineStatus==0?
        <div className='personOnlineState font-size13 font-color3 layout-abs OnlineState0'>
            离线
        </div>:
        props.onlineStatus==3?
        <div className='personOnlineState font-size13 font-color3 layout-abs OnlineState2'>
            活跃
        </div>:
        props.onlineStatus==2?
        <div className='personOnlineState font-size13 font-color3 layout-abs OnlineState3'>
            在聊
        </div>:null
    )
    
}

const Person = (props)=>{

    const profile = JSON.parse(localStorage.getItem('profile'));
    //是否打开亲密弹框
    const [showdialog,setshowdialog]=useState(false);

    const [showGift,setShowGift] = useState(false);
    const navigate = useNavigate();
    
    const {userId} = useParams();
    const [person,setPerson] = useState(null);

    const [detail,setDetail] = useState(null);
    const [detail2,setDetail2] = useState(null);
    const [detail3,setDetail3] = useState(null);
    const [commentList,setCommentList] = useState(null);
    const [swipers,setSwipers] = useState([]);
    const [showDealMenu, setShowDealMenu] = useState(null);

    const reviewContext = useContext(PersonContext);
    const chargeContext = useContext(ChargeContext);
    

    const [showReview,setShowReview] = useState(reviewContext.review);
    const location = useLocation();
    const [needDeposit,setNeedDeposit]= useState(false);

    const [currentScrollHeight,setCurrentScorllHeight] = useState(0);
    

    const listRef = useRef();
    const videoRef = useRef();
    const [myInfo,setMyInfo] =useState(null);
    const [userBalance,setUserBalance] = useState(null);
    const [videoHeadUrl,setVideoHeadUrl] = useState(null);
    const [showTip,setShowTip] = useState(false);
    const [tipAction,setTipAction] = useState('');
    const [tip,setTip] = useState('')
    const [showMediaDialog,setShowMAD] = useState(false);
    const [showDeposit,setShowDeposit] = useState(false);

    let tipTimer=null;
    

    const navi = useContext(UNSAFE_NavigationContext); 
    const loca = useLocation();

    useEffect(()=>{
        if(!userBalance){
            service.queryMyselfInfo().then(data=>{
                if(data.code==21){
                  navigate('/login');
                }
                if(data.code==0)setUserBalance(data?.userInfo?.wealthXz);
          });
          
        }


        if(!person){
            Promise.resolve().then(()=>{
                return service.getPersonDetail(userId);
            }).then(data=>{
                
                if(data.code==99||data?.user?.userType==0){
                    return service.queryUserInfo(userId);
                }
                else return data;
                
            }).then(data=>{
                console.log('%c person:%o','color:blue',data);
                if(data.code==0){
                    setPerson(data.user);
                    setDetail(data);

                    if(data?.user?.vedioHeadImgUrl&&data?.user?.vedioHeadImgUrl.length>0){
                        setSwipers(data.user.vedioHeadImgUrl);
                    }
                    else setSwipers([data.user.headUrl||DefaultHeadUrl]);
                    setVideoHeadUrl(data?.user?.vedioHeadVdioUrl);
                }
                else if(data.code==21){
                    throw new Error(data.errMsg);    
                }
            }).catch(err=>{
                console.error(err);
                navigate('/login');
            })
            
        }
        // if(person&&!detail){
            
        //     Promise.resolve().then(()=>{
        //         return service.getPersonDetail(person.userId)
        //     })
        //     .then(data=>{
        //         console.log('[debug]detail:',data);
        //         if(data.code=='21')throw new Error('login');
        //         setDetail(data);

        //     }).catch(err=>{
        //         navigate('/login');
        //     })
        // }
        if(person&&!detail2){
            Promise.resolve().then(()=>{
                return service.getModelDetail(person.userId)
            })
            .then(data=>{
                // console.log('%c [debug]ModelDetail:%o','color:blue',data);
                if(data.code=='21')throw new Error('login');
                setDetail2(data);
            }).catch(err=>{
                console.error(err)
                navigate('/login');
            });
            
        }
        if(person&&!detail3){
            Promise.resolve().then(()=>{
                return service.getModelPost(person.userId)
            })
            .then(data=>{
                // console.log('%c [debug]ModelPost:%o','color:blue',data);
                if(data.code=='21')throw new Error('login');
                console.log('%c detail3:%o','color:green',data);
                setDetail3(data);
            }).catch(err=>{
                console.error(err);
                navigate('/login');
            })
        }
        if(!commentList&&person){
            Promise.resolve().then(()=>{
                return service.queryModelComment(person?.userId)
            })
            .then(data=>{
                // console.log('%c [debug]commentList:%o','color:blue',data);
                if(data.code=='21')throw new Error('login');
                setCommentList(data);
            }).catch(err=>{
                console.error(err);
                navigate('/login');
            })
            
        }
        // console.log(detail);
        const list = listRef.current;
        const handleScroll=()=>{   
            // const scrollTop = document.getElementById('root').scrollTop;
            const scrollTop = document.getElementById('person-page').scrollTop;
            // const scrollTop = list.scrollTop;
            const newScrollHeight = Math.ceil(scrollTop / 50) * 50;
            setCurrentScorllHeight( newScrollHeight );
            // console.log('%c newScrollHeight:%d','color:green',newScrollHeight);
        }
        //list.addEventListener('touchmove', handleScroll);
        list.addEventListener('scroll', handleScroll);
        return () => {
            // list.removeEventListener('touchmove', handleScroll);
            list.removeEventListener('scroll', handleScroll);
        };

        
        
    },[detail,setDetail,person,setDetail2,setDetail3,detail2,detail3,commentList,setCommentList,person,setPerson,swipers,setSwipers,setNeedDeposit,setCurrentScorllHeight])
    //banner数据配置
    
    //let swipers=['/static/images/list-01.png','/static/images/list-01.png','/static/images/list-01.png'];
    //console.log('[debug]model:',state.person);
    //个人中心-认证信息数据配置
    let auths=[
        {
            icon:'/static/images/auth-01.png',
            notice:'对方已通过手机号认证，可放心交友～',
            title:'号码认证'
        },
        {
            icon:'/static/images/auth-02.png',
            notice:'对方已通过身份证认证，可放心交友～',
            title:'实名认证'
        },
        {
            icon:'/static/images/auth-06.png',
            notice:'对方已通过人脸认证，可放心交友～',
            title:'真人认证'
        },

    ];
    //个人中心-礼物柜 数据配置
    // let gifts=[
    //     {
    //         icon:'/static/images/gift-06.png',
    //         title:'x10',
    //         width:'0.48rem',height:'0.48rem'
    //     },
    //     {
    //         icon:'/static/images/gift-07.png',
    //         title:'x10',
    //         width:'0.54rem',height:'0.50rem'
    //     },
    //     {
    //         icon:'/static/images/gift-08.png',
    //         title:'x10',
    //         width:'0.5rem',height:'0.5rem'
    //     },
    //     {
    //         icon:'/static/images/gift-09.png',
    //         title:'x10',
    //         width:'0.44rem',height:'0.44rem'
    //     },
    //     {
    //         icon:'/static/images/gift-09.png',
    //         title:'x10',
    //         width:'0.44rem',height:'0.44rem'
    //     }
    // ];
    //个人中心--个人信息数据配置
    let tags=[
        {
            label:'身高',
            fieldName:'height'
        },
        {
            label:'体重',
            fieldName:'weight'
        },
        {
            label:'年龄',
            fieldName:'age'
        },
        {
            label:'星座',
            fieldName:'starSign'
        },
        {
            label:'三围',
            fieldName:'threeDimensional'
        },
        {
            label:'城市',
            fieldName:'city'
        }
    ]
    
    //个人中心 用户评价数据配置
    let usermsgs=[
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-02.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-03.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放','身材好'],
            userName:'唐长老',
            level:23
        }
    ];


    let dynamics=['/static/images/user-01.png','/static/images/user-02.png','/static/images/user-03.png','/static/images/user-01.png']
    
    
    const createPoint=(total,current)=>{
        let size=[...(new Array(total)).keys()]
        let arr=size.map((item,idx)=>{
            
            if(idx===current){
                return (<div key={idx+'-GWT'} className='swiper-point-active swiper-point-item'></div>)
            }else{
                return (<div key={idx+'-GWT'} className='swiper-point swiper-point-item'></div>)
            }
        })
        return arr;
    }
    return (
        <div className='person-page' ref={listRef} id='person-page'>
             {/**1.个人中心swiper**/}
            <div className='person-header full-r-w layout-rel'>
                 <Swiper 
                  indicator={(total, current) => (
                    <div className="custom-indicator layout-abs full-r-w fx-box fx-jc-c fx-ai-c">
                      <div className='fx-box'>
                         {
                            createPoint(total,current)
                         }
                      </div>
                    </div>
                  )}>
                    
                    {swipers&&swipers.map&&swipers.map((image,idx) => {
                        if(idx==0&&!!videoHeadUrl){
                            return <Swiper.Item key='s_img_video_k_0'>
                                    <div style={{position:'relative',height:'3.3rem',width:'100%'}}>
                                        <video controls ref={videoRef} poster={image}  height="3.3rem" style={{objectFit:'cover',height:'3.3rem',width:'100%'}}>
                                            <source src={videoHeadUrl.replace('http://','https://')} type="video/mp4"/>
                                        </video>
                                        <div style={{position:'absolute',left:0,right:0,top:0,bottom:0}} className='fx-box fx-ai-c fx-jc-c'>
                                            <img src={image} style={{width:'100%'}}></img>
                                            <div className='fx-box fx-jc-c fx-ai-c video-cover' style={{position:'absolute',left:0,right:0,top:0,bottom:0}}>
                                                <img  className='video-play-btn' style={{width:60,height:60}} src={VideoPlayBtnImage} 
                                                onClick = {()=>{
                                                    if(videoRef.current.requestFullscreen){
                                                        videoRef.current.requestFullscreen({navigationUI:'show'})    
                                                    }
                                                    else if (videoRef.current.mozRequestFullScreen) {
                                                        videoRef.current.mozRequestFullScreen();
                                                    } else if (videoRef.current.webkitRequestFullscreen) {
                                                        videoRef.current.webkitRequestFullscreen();
                                                    } else if (videoRef.current.msRequestFullscreen) {
                                                        videoRef.current.msRequestFullscreen();
                                                    } else console.log('no native request full screen'); 

                                                    //videoRef.current.webkitRequestFullscreen({navigationUI:'show'});
                                                    videoRef.current.play();
                                                }}></img>
                                            </div>
                                        </div>
                                    </div>
                                    </Swiper.Item>
                        }
                        
                        return (
                        <Swiper.Item key={image+'img'+idx}>
                            <Image fit={'cover'} height="3.3rem" lazyload src={image} />
                        </Swiper.Item>
                    )}
                    )
                }
                </Swiper>
               
                <ModelStatusBadge onlineStatus={detail?.modelInfo?.onlineStatus}/>
 
            </div>
            
             {/**2.个人中心全部模块**/}
            <div className='person-info fx-box fx-fd-c fx-ai-c'>
                {/**2.1 个人中心-基础信息模块**/}
               <div className='person-info-base'>
                    <div className='full-r-w fx-box fx-jc-sb fx-ai-c'>
                      
                      <div className='theme-font font-w4'>
                         <div className='fx-box fx-ai-c'>
                            <span className='font-size18 layout-mr8'>{detail?.user?.nickName}</span>
                            <div className='layout-mr8'>
                               <Image width="0.18rem" height="0.18rem" round src='/static/images/level-icon.png'/>
                            </div>
                            <ModelLevelBadge level={detail?.user?.playerLeven}></ModelLevelBadge>
                         </div>
                         <div className='font-size13 font-color5 person-base-signtext'>{detail?.user?.personalSign}</div>
                      </div>

                      {detail?.user?.userType==1?<div onClick={()=>{
                        setshowdialog(true);
                      }}>
                         <Image width="0.44rem" height="0.44rem" round src='/static/images/wx-icon.png'/>
                      </div>:null}

                    </div>

                    <div className='fx-box theme-font person-base-fans'>
                       <div className='font-size11'  style={{marginRight:'0.1rem'}}>
                          粉丝<span className='font-size15' style={{marginLeft:'0.03rem'}}>{detail&&detail.fansNum}</span>
                       </div>
                       <div className='font-size11'>
                          关注<span className='font-size15' style={{marginLeft:'0.03rem'}}>{detail&&detail.myAttentions}</span>
                       </div>
                    </div>
               </div>
                {/**2.2 个人中心-认证信息模块**/}
               {detail?.user?.userType==1?<div className='person-info-auth theme-font'>
                  <div className='font-size15 font-w5'>认证信息</div>
                  <div className='fx-box'>
                      
                     {
                        auths.map((item,index)=>{
                            return (<div key={index+'-pinfo'} className='person-auth-item fx-box fx-fd-c fx-jc-c fx-ai-c'
                            onClick={()=>{
                                Toast.info(item.notice);
                            }}
                            >
                                <Image width="0.46rem" fit={'cover'} height="0.46rem" round src={item.icon}/>
                                <span className='font-size13 font-color'>{item.title}</span>
                            </div>)
                        })
                     }

                  </div>
               </div>:null
                  }
               {
                   detail&&detail?.user&&detail.user.userType==1&&detail.circleInfo&&(
                    <div className='person-info-circle fx-box fx-jc-sb fx-ai-c theme-font'  onClick={()=>{
                        if(!detail?.circleInfo?.isPurchase)
                            navigate('/fupurchase/'+detail?.user?.userId,{state:{circleInfo:detail?.circleInfo}})
                        else 
                            navigate('/fu/'+detail?.user?.userId,{state:{circleInfo:detail?.circleInfo}})
                    }}>
                        <div className='fx-box'>
                        <Image width="0.45rem" radius={'0.12rem'} height="0.45rem" fit="cover" src={detail?.circleInfo?.headUrl||'/static/images/quan-icon.png'}/>
                        <div className="person-info-summery">
                            <div className='font-size15'>Fu圈</div>
                            <div className='font-size13 font-color8'>精选：{detail&&detail.circleInfo&&detail.circleInfo.contentCount}</div>
                        </div>
                        </div>
                        <div className='fx-box fx-jc-sb fx-ai-c'>
                            <span className='circle-info font-size11 font-color3'>有更新</span>
                            <i className='iconfont icon-weibiaoti--'></i>
                        </div>
                    </div>
                   )
               }
               
               {(detail&&detail?.user&&detail.user.userType==1&&(detail3?.opusList&&detail3.opusList.length>0))?<div className='person-info-dynamic theme-font' onClick={()=>{
                navigate('/activity/'+person?.userId,{state:{headUrl:detail?.user?.headUrl}})
               }}>
                     <div className='font-size15'>动态（{detail3?.totalCount}）</div>
                     <div className='person-dynamic-slider full-r-w fx-box fx-jc-sb fx-ai-c'>
                         <div className='dynamic-slider-albums fx-box'>
                             
                             {
                                detail3?.opusList?.map((item,index)=>{
                                    return (<div key={'image-'+index} className='slider-albums-item'>
                                        {
                                            item?.photos?.length?<Image width="0.72rem" height="0.72rem" fit="cover" radius={'0.12rem'} src={item.photos[0]}/>
                                            :
                                            // <video width="0.72rem" height="0.72rem" fit="cover" radius={'0.12rem'} src={item.videoUrl}/>
                                            <>
                                            <video style={{width:'0.72rem',height:'0.72rem',display:'block',borderRadius:'0.12rem'}} fit="cover">
                                                <source src={item.videoUrl.replace('http://','https://')}
                                                        type="video/mp4"/>
                                            </video>
                                            <div className='p-a-video-cover-container'>
                                                <img src={item.imgUrl} className='p-a-video-cover'/>
                                                <img src={VideoPlayBtnImage} className='p-a-video-cover-btn'/>
                                            </div>
                                            </>
                                        }
                                    
                                 </div>)
                                })
                             }
                         </div>
                         <div className='fx-box fx-ai-c'>
                            <i className='iconfont icon-weibiaoti--'></i>
                         </div>
                     </div>
               </div>:null
               }
               {detail?.user?.userType==1?<div className='person-info-sortlist'>
                         <div className='font-size15'>亲密榜</div>
                         <div className='sortlist-slider fx-box fx-jc-sb fx-ai-c' onClick={()=>{
                            navigate('/fans/'+person?.userId)
                         }}>
                            <div className='sortlist-slider-albums fx-box'>
                                {
                                   detail&& detail.fansList.map((e,idx)=>{
                                        return (<div key={'fl_k_'+idx} className='sortlist-albums-item'>
                                                <Image width="0.35rem" height="0.35rem" round fit="cover"  src={e.headUrl||DefaultHeadUrl}/>
                                        </div>)
                                    })
                                }
                                
                            </div>
                            <div className='fx-box fx-ai-c'>
                                <i className='iconfont icon-weibiaoti--'></i>
                            </div>
                         </div>
               </div>:null}


               {detail?.user?.userType==1?<div className='person-info-dynamic theme-font'>
                     <div className='font-size15'>礼物柜</div>
                     <div className='person-dynamic-slider full-r-w fx-box fx-jc-sb fx-ai-c' onClick={()=>{
                        navigate('/gifts/'+person?.userId);
                     }}>
                         <div className='dynamic-slider-albums fx-box'>
                             
                             {
                                detail&&detail.giftList.map((item,index)=>{
                                     return (<div key={'image-'+index} className='slider-albums-item fx-box fx-fd-c fx-ai-c fx-jc-sb'>
                                     <Image width={'0.44rem'} height={'0.44rem'}  fit="cover"  src={GiftDict[item.giftId]?.icon}/>
                                     <span className='font-size10 font-color5 text-center'>x{item.giftNums}</span>
                                  </div>)
                                })
                             }
                            
                         </div>
                         <div className='fx-box fx-ai-c'>
                            <i className='iconfont icon-weibiaoti--'></i>
                         </div>
                     </div>
               </div>:null}

               <div className='person-info-personals theme-font'>
                       <div className='font-size15'>个人信息</div>
                       <div className='personal-tag-container fx-box fx-wp'>
                       <div key={'image-id'} className='personal-tag font-size12'>
                                <span className='peronal-tag-label'>ID: </span>
                                <span>{detail?.stealth?'******':person?.userId}</span>
                            </div>
                         {
                            tags.map((i,idx)=>{
                                return(<div key={'image-'+idx} className='personal-tag font-size12'>
                                <span className='peronal-tag-label'>{i.label}: </span>
                                <span>{detail?.modelInfo&&detail?.modelInfo[i.fieldName]}</span>
                            </div>)
                            })
                        }
                       </div>
                    
               </div>
               {detail?.user?.userType==1?<div className='person-info-commands'>
                   <div className='commands-header theme-font fx-box fx-ai-c fx-jc-sb'>
                      <div className='font-size15'>用户评价（{commentList?.evaluationSum}）</div>
                      <div className='fx-box fx-ai-c font-size13 font-color6'>
                         <div className='commands-header-span'>喜欢 {commentList?.evalutationLikeNum}</div>
                         <div className='commands-header-span'>无感 {commentList?.evalutationDisLikeNum}</div>
                         <i className='iconfont icon-weibiaoti--'></i>
                      </div>
                   </div>

                   <div className='full-r-w'>
                     <UserMsgItem msgs={commentList?.evaluationList??[]}/>
                   </div>

               </div>:null}
            </div>

             {/**3.个人中心底部tab**/}
            <div className='person-bar full-r-w fx-box fx-jc-sb'>
                   <div className='fx-box fx-ai-c  fx-1'>

                            <div className='fx-box fx-fd-c fx-ai-s fx-jc-sb personBarItem' onClick={()=>{
                                if(detail2.attentionFlag==0){
                                    follow(person.userId).then(ret=>{
                                        // console.log('follow:',ret);
                                        if(ret.code==0){
                                            Toast.info('关注成功！')
                                            setDetail2(Object.assign({},detail2,{attentionFlag:1}));
                                        }
                                    }).catch(err=>{
                                        console.error(err);
                                    })
                                }
                                else {
                                    unfollow(person.userId).then(ret=>{
                                        // console.log('unfollow:',ret);
                                        if(ret.code==0){
                                            Toast.info('已取消关注！')
                                            setDetail2(Object.assign({},detail2,{attentionFlag:0}));
                                        }
                                    }).catch(err=>{
                                        console.error(err);
                                    })
                                }
                            }}>
                                <Image height="0.18rem" width="0.18rem" src={detail2?.attentionFlag?'/static/images/loved.png':'/static/images/love.png'}/>
                                <span className='font-size13 person-bar-text'>关注</span>
                            </div>
                            <div className='fx-box fx-fd-c fx-ai-s  fx-jc-sb personBarItem' onClick={()=>{
                                navigate('/chat/socialfu_'+person.userId)
                            }}>
                                <Image height="0.2rem" width="0.2rem" src='/static/images/msg-icon.png'/>
                                <span className='font-size13 person-bar-text'>私聊</span>
                            </div>

                            <div className='fx-box fx-fd-c fx-ai-s  fx-jc-sb personBarItem' onClick={()=>{
                                setShowGift(!showGift);
                            }}>
                                <Image height="0.2rem" width="0.2rem" src='/static/images/gift-icon.png'/>
                                <span className='font-size13 person-bar-text'>礼物</span>
                            </div>

                  </div>

                  <div className={'personBarButton  fx-box fx-ai-c '+(detail?.modelInfo?.onlineStatus==1?'personBarButtonEnabled':'personBarButtonDisabled')}>
                      <div className='fx-box fx-jc-sb fx-ai-c'>
                            <Image src='/static/images/video-icon.png' width="0.21rem" height="0.13rem"/>
                            <span className='theme-font font-color3 personBarButtonText fx-box fx-fd-c fx-jc-c' onClick={async ()=>{
                                const allowed = await service.checkWebMediaPermission().then(()=>{return true;}).catch(()=>{
                                    setShowMAD(true);
                                    return false;
                                });
                                if(!allowed)return;

                                if(detail?.modelInfo?.onlineStatus==1){
                                    const liveData = await service.checkLive(person?.userId+'',profile?.userId+'');
                                    console.log('%c [DEBUG]check live return:%o','color:orange',liveData);
                                    
                                    if(liveData.code=='707'||liveData.code=='709'){
                                        //setNeedDeposit(true);
                                        chargeContext.setPay(true);
                                    }
                                    else if(!liveData.conversationId){
                                        // setFailed(true);
                                        Toast({
                                            message: liveData?.errMsg||'主播正在忙，请稍后尝试',
                                            icon: <Image width={50} src="/static/images/toast-icon.png" />,
                                        });
                                    }
                                    else 
                                        navigate('/one2one',{replace:true,state:{person,liveData}})
                                }
                                
                            }}>
                                <span className='font-size13 personBarTitle'>与Ta视频</span>
                                <span className='font-size11 personBarPrice'><span className='font-w6 font-color3 font-size13'>{person?.oneToOneVedioPrice}</span>金币/分钟</span>
                            </span>
                      </div>
                  </div>
            </div>

            <div className='person-nav' style={{background:'#ffffff'+colorOpactityByScroll(currentScrollHeight)}}>
                <div onClick={()=>{
                        console.log('\n[DEBUG]window.history:',window.history,window.history.length);
                        if(location?.state?.from=='one2one')
                            navigate('/app');
                        else 
                        navigate(-1);
                    }} 
                    className='person-head-back layout-abs'>
                    <Image width="0.14rem" height="0.14rem" src='/static/images/white-back.png' style={{filter:colorInvertByScroll(currentScrollHeight)}}/>
                </div>
                <div onClick={()=>{
                    setShowDealMenu(!showDealMenu)
                    }} className='person-head-menu layout-abs'>
                    <Image width="0.24rem" height="0.24rem" src='/static/images/white-more.png' style={{transform:'rotate(90deg)',filter:colorInvertByScroll(currentScrollHeight)}}/>
                </div>
            </div>
            {
                showdialog?<IntimacyDialog 
                intimacy={detail?.user?.userIntimacy}
                intimacyLine={detail?.user?.intimacy}
                modelHead = {detail?.user?.headUrl}
                myHead={profile.headUrl||DefaultHeadUrl}
                wx = {detail?.user?.wechat}
                onClose={()=>{
                     setshowdialog(false)
                }}></IntimacyDialog>:null
            }
            {
                showGift?<GiftDialog balance={userBalance} onGiveGift={(giftId,nmbs)=>{
                    // console.log('onGiveGift');
                    service.giveGift(person.userId,giftId,nmbs).then(ret=>{
                        console.log('[DEBUG]gift ret:',ret);
                        
                        if(ret.code==0){
                            setUserBalance(ret.userBalance);
                            service.addMessageByUser(userId,'一条礼物消息').then(data=>{
                                console.log('>>>data:',data);
                                if(data.code==0)giftSend(
                                    {
                                        messageId:data.messageId,
                                        giftId,
                                        nmbs,
                                        postHeader:detail?.user?.headUrl,
                                        postName:detail?.user?.nickName,
                                        sendHeader:profile?.headUrl,
                                        sendNickname:profile?.nickname,
                                        to:'socialfu_'+userId,
                                        from:profile?.hxUserName,
                                        dispatch:props.dispatch
                                    }
                                    )
                            });
                            const giftName=GiftDict[giftId].name||'礼物'
                            setTip({
                                name:giftName,
                                count:(nmbs||0)*1+(tip.count||0)*1
                            });
                            setShowTip(true);
                            setTipAction('open');
                            
                            tipTimer=setTimeout(()=>{
                                setTipAction('close');
                            },3500)
                        }
                        else if(ret.code==701){
                            setShowDeposit(true);
                            setShowGift(false);
                        }
                        else Toast.info(ret.errMsg);
                    });

                }} onClose={()=>{
                    setShowGift(false);
                }}/>:<div></div>
            }    
            {(detail?.user?.userType==1&&detail?.user?.wechatShowFlag)?<div className='personGiftIcon layout-abs fx-box fx-fd-c fx-ai-c' style={{position:'fixed'}} onClick={()=>{
                        setshowdialog(true);
                      }}>
                {/* <div className='personGiftIconImg'></div> */}
                <img className='personGiftIconImg' src={'/static/images/intimacy.png'}/>
                <div className='font-size15 font-w4 font-color3 personGiftIconText'>{
                 intimacy(detail?.user)+'%'
                }</div>
            </div>:null}

            {
                detail?(detail?.modelInfo?.onlineStatus==1?
                <div className='model-status'>
                    <div>
                        <div className='model-status-back'>
                            <img className='model-status-backimg' src='/static/images/model-not-available-hint.png' />
                        </div>
                        <div className='model-status-container'>
                            <img src={person?.headUrl||DefaultHeadUrl}/>
                            <div className='font-size13 font-color3'>
                            我现在在线，点击这里跟我视频哦！
                            </div>
                        </div>
                    </div>
                </div>:
                <div className='model-status'>
                    <div>
                        <div className='model-status-back'>
                            <img className='model-status-backimg' src='/static/images/model-not-available-hint.png' />
                        </div>
                        <div className='model-status-container'>
                            <img src={person?.headUrl||DefaultHeadUrl}/>
                            <div className='font-size13 font-color3'>
                                亲，我现在不方便视频，可以给我发私信聊天哦！
                            </div>
                        </div>
                    </div>
                </div>):null
            }
            
            <MediaAuthDialog title='必须开启相机/麦克风权限才能进行通话' visible={showMediaDialog} onClose={()=>{
                window?.ReactNativeWebView?.postMessage('openAudio');
                window?.ReactNativeWebView?.postMessage('openCamera');
                setShowMAD(false)
            }}/>
            <Overlay zIndex={10000} visible={showDealMenu} onClick={() => setShowDealMenu(false)}>
                <div className="person-menu-wrapper">
                  <div className="person-menu-block" >
                    <div className='person-menu-block-item' onClick={()=>{
                        service.banUser(person.userId).then(data=>{
                            // console.log(data);
                            if(data.code==0)
                            Toast.info('拉黑成功!');
                            else Toast.info(data.errMsg);
                        })
                    }}>拉黑</div>
                     <div className='person-menu-block-item' onClick={()=>{
                        navigate('/chat/'+kefuID)
                    }}>举报投诉</div>
                    {/* <div className='person-menu-block-item'  onClick={()=>{
                        console.log('举报')
                    }}>举报</div> */}
                    </div>
                </div>
            </Overlay>
            <Popup visible={showReview}
                style={{height:412,width:320,paddingTop:40,backgroundColor:'transparent'}}
            onClose={()=>{
                reviewContext.setReview(false);
                setShowReview(false);
            }}>
                    <Review
                    liveContext = {reviewContext.live}
                    modelHeadUrl={person?.headUrl||DefaultHeadUrl}
                    userHeadUrl={profile?.headUrl||DefaultHeadUrl}
                    modelName={person?.nickName}
                    // timeStr={'0分12秒'}
                    // timeConsume={200}
                    // giftConsume={300}
                    onSubmit={(type,review)=>{
                        const modelId = person?.userId;
                        
                        // console.log(modelId,type,review);
                        if(!modelId)return;
                        service.submitReview(modelId,type,review,reviewContext.live.liveId).then(ret=>{
                            // console.log(ret);
                            reviewContext.setReview(false);
                            setShowReview(false);
                        })
                        
                    }}/>
            </Popup>

            <Popup visible={chargeContext.pay} style={{borderRadius:10,padding:'0.2rem'}} onClose={()=>{
                chargeContext.setPay(false);
            }}>
                    <div className='font-size13'>
                        <div style={{padding:'0.1rem'}} className='font-size15'>
                            余额不足2分钟
                        </div>
                        <div style={{padding:'0.1rem'}}>
                            <Link to='/pay' onClick={()=>{
                                chargeContext.setPay(false);
                            }}><span>去充值</span></Link>
                        </div>
                    </div>
            </Popup>
            <DepositDialog visible={chargeContext.pay} onClose={()=>{
                chargeContext.setPay(false);
            }}>
            
            </DepositDialog>
            
            <DepositDialog visible={showDeposit} onClose={()=>{
                setShowDeposit(false);
            }}></DepositDialog>

            <GiftTip
                openTip={tipAction}
                tip={tip}
            />
            
        </div>
    )
}

export default connect(null,(dispatch)=>{return {dispatch}})(Person);