import * as service from '../../service';
import './user-badge.css';
export const UserLevelBadge = (props)=>{
    return (
        <div className='person-level btn-bg1 font-color3' 
        style={{background:`url(${service.getModelLevelBG(props.level)})`}}>
            {/* <img src={service.getUserLevelBG(detail?.user?.playerLeven)} /> */}
            <span>Lv{props.level}</span>
        </div>
    )
}
export const ModelLevelBadge = (props)=>{
    return (
        <div className='person-level btn-bg1 font-color3' 
        style={{background:`url(${service.getModelLevelBG(props.level)})`}}>
            {/* <img src={service.getUserLevelBG(detail?.user?.playerLeven)} /> */}
            <span>Lv{props.level}</span>
        </div>
    )
}