import React,{useEffect, useState} from 'react';

import * as service from '../../service';
import { ImagePreview } from 'react-vant';
import {Link, useNavigate} from 'react-router-dom';
import { CircleVideo } from './circle-video';


const CircleListItem=(props)=>{
    // console.log(props.headUrl);
    const navigate = useNavigate();
    const [updateTimestamp, setUpdateTimestamp] = useState(new Date().valueOf());
    useEffect(()=>{
        // console.log('new update :',updateTimestamp);
    },[updateTimestamp])
    return (<div className='circleList' style={{...props.style}}>
        {
            props.circleList?.map((item,idx)=>{
                
                    return (
                    <div key={'clk_'+idx} className='circleListItem'>
                        <div className='circleListUser fx-box fx-ai-c fx-jc'>
                            <div className={item.online===1?'circleListUserAvatar '+'circleListUserOnline':'circleListUserAvatar'}
                            onClick={()=>{
                                navigate('/person/'+item.modelId,{})
                            }}
                            >
                                <img src={item.headUrl||props.headUrl}/>
                                {
                                    item.online?<div className='circleListUserOnlineTip'>{service.statusText(item.status)}</div>:<span></span>
                                }
                            </div>

                            <div className='theme-font userItemLevel fx-1 fx-box fx-jc-sb fx-ai-s'>
                                

                                <div className='fx-box fx-fd-c  fx-jc-sb'>
                                    <div className='fx-box fx-ai-c'>
                                        <div className='font-size17 font-w5'>{item.modelName}</div>
                                    </div>
                                    <div className='font-size13 font-color4'>{item.time}</div>
                                </div>

                                
                                <div className='userItemMore fx-box fx-fd-c fx-ai-s'>
                                    <i className='iconfont icon-more'></i>
                                </div>
                                
                            </div>

                        </div>

                        <div className='userItemMsg theme-font font-size13'>{item.description}</div>

                        <div className='circleItemAlbums'>
                            {item.videoUrl&&<CircleVideo 
                                videoUrl={item.videoUrl} 
                                imgUrl={item.imgUrl}
                                canPlay = {item.isReward>0||props.isVip}
                                onRequestPlay ={ ()=>{
                                    props.onShouldPay(item);
                                }}
                            />
                            }
                            {
                                item.photos&&item.photos.slice(0,9).map((src,index)=>{
                                    return  (
                                    <div key={'clki_'+index} className={'circleItemBack'}>
                                        {
                                            (item.isReward<=0&&!props.isVip)?
                                            <img className={'circleItemImg '+(index>=(item.freeCount*1)?'nopay':'free')} key={index} src={index>=(item.freeCount*1)?'/static/images/fu-fog.jpg':src} 
                                            onClick={
                                                ()=>{
                                                    if(item.isReward<=0&&!props.isVip){
                                                        if(index>=item.freeCount){
                                                            props.onShouldPay(item);
                                                        }
                                                        else {
                                                            var destroy=null;
                                                            const photos = item.photos.slice(0,item.freeCount);
                                                            photos.push('/static/images/fu-fog.jpg');
                                                            destroy=ImagePreview.open({images:photos,startPosition:index,onChange:(a)=>{
        
                                                                if(a>=item.freeCount){
                                                                    destroy&&destroy();
                                                                    props.onShouldPay(item);
                                                                }
                                                            }});
                                                        }
                                                    }
                                                    else {
                                                        ImagePreview.open({images:item.photos,startPosition:index});
                                                    }
                                                }
                                            }
                                            />
                                            :
                                            <img className={'circleItemImg free'} key={index} src={src} 
                                            onClick={
                                                ()=>{
                                                    if(item.isReward<=0&&!props.isVip){
                                                        if(index>=item.freeCount){
                                                            props.onShouldPay(item);
                                                        }
                                                        else {
                                                            var destroy=null;
                                                            const photos = item.photos.slice(0,item.freeCount);
                                                            photos.push('/static/images/fu-fog.jpg');
                                                            destroy=ImagePreview.open({images:photos,startPosition:index,onChange:(a)=>{
        
                                                                if(a>=item.freeCount){
                                                                    destroy&&destroy();
                                                                    props.onShouldPay(item);
                                                                }
                                                            }});
                                                        }
                                                    }
                                                    else {
                                                        ImagePreview.open({images:item.photos,startPosition:index});
                                                    }
                                                }
                                            }
                                            />
                                        }
                                        
                                        
                                    </div>)
                                })
                                
                            }
                            {
                                item?.photos?.length>9?<div className='circlie-more-num'>
                                            +{item?.photos?.length-9}
                                </div>:null
                            }
                        </div>

                        <div className='userItemPreview theme-font fx-box fx-ai-c fx-jc-sb'>
                            <div className='font-size15 font-color2 '>
                                {item.price>0?
                                ((!props.isVip&&item.isReward<=0)?
                                    <div className='circle-item-lock  fx-box fx-jc-c fx-ai-c' onClick={()=>{
                                        console.log(item);
                                        props.onShouldPay(item);
                                    }}>
                                        <span className='circle-item-unlock-amount'>{item.price}金币 </span>
                                        <span className='circle-item-gold'>解锁</span>
                                    </div>
                                    :
                                    <div className='circle-item-lock'>
                                        <span className='circle-item-gold'>已解锁</span>
                                        <span className='circle-item-gold'>{item.price}金币</span>
                                    </div>
                                )
                                :null}
                            </div>

                            <div className='userItemP fx-box fx-ai-c'>
                            
                                <Link to={'/fuopus/'+item.id}><div className='fx-box fx-ai-c'>
                                    <i className='iconfont icon-xiaoxi3'></i>
                                    <span className='font-size13 font-color4'>{item.commentNum}</span>
                                </div></Link>

                                <div className='fx-box fx-ai-c' >
                                    {item.isCollect==1?
                                    <img style={{ width:24}} src={'/static/images/icon/like_active.png'}
                                    onClick={()=>{
                                        service.uncollectActivity(item.id).then(ret=>{
                                            // console.log(ret);
                                            item.isCollect=0;
                                            item.collectNum=ret.collectNum;
                                            setUpdateTimestamp(new Date().valueOf());
                                        });
                                    }}
                                    />:<img style={{width:24}} src={'/static/images/icon/like_inactive.png'}
                                    onClick={()=>{
                                        service.collectActivity(item.id+'').then(ret=>{
                                            console.log('collect:',item.id+'',ret);
                                            item.isCollect=1;
                                            item.collectNum=ret.collectNum;
                                            setUpdateTimestamp(new Date().valueOf());
                                        });
                                    }}
                                    />
                                    
                                    }
                                    <span className='font-size13 font-color4'>{item.collectNum}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
            })
        }
    
    </div>);
}

export default CircleListItem;