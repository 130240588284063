import React,{useEffect,useState,useRef}from 'react';
import NavBar from '../../components/nav-bar/nav-bar';
import './Activity.css';
import ActiveListItem from '../../components/active-list-item/active-list-item';
import * as service from '../../service';
import { ImagePreview } from 'react-vant';
import { Link, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';





const Activity=(props)=>{
    // let loading = false;
    
    // const params = useParams();
    const location =useLocation();
    
    const [loading,setLoading] = useState(false);
    
    const listRef = useRef(null);
    useEffect(() => {
        if(!props.list){
            // props.dispatch({type:'ACTIVITY_LIST_RELOAD_START'})
            Promise.resolve().then(()=>{
                if(props.currentTab==0){
                    return service.loadFollowOpusList().then(data=>{
                        console.log('OPUS DATA:',data.opusList);
                        props.dispatch({type:'ACTIVITY_LIST_LOAD',payload:{list:data.opusList,totalCount:data.totalCount}})
                    })
                }
                else{
                    console.log('%c load anoter tab:','color:')
                    return service.loadOpusList().then(data=>{
                        console.log('OPUS DATA:',data.opusList);
                        props.dispatch({type:'ACTIVITY_LIST_LOAD',payload:{list:data.opusList,totalCount:data.totalCount}})
                    })
                }
                    
            }).finally(()=>{
                props.dispatch({type:'ACTIVITY_LIST_RELOAD_END'})
            })
            
        }
        
        
        (async ()=>{
            
            const llpagUrl = '/static/pag/home_live.pag';
            // Fetch pag file.
            const llbuffer = await fetch(llpagUrl).then((response) => response.arrayBuffer());
            // Load the PAGFile from file.
            const llpagFile = await window.PAG.PAGFile.load(llbuffer);
            const llCanvas = document.getElementById('livelink');
            if(llCanvas){
                llCanvas.width=150;
                llCanvas.height=45;
                    
                window.PAG.PAGView.init(llpagFile, llCanvas).then((v)=>{
                    v.setRepeatCount(0);    
                    v.play()
                });
            }
        })();
        
        const list = listRef.current;
        
        if(props.status.scrollTop)
        {
            try{
                list.scrollTop=props.status.scrollTop;
            }
            catch(err){
                console.error(err);
            }
            
        }


        
        let timer;

        const handleScroll = ev => {
        
            const scrollTop = document.getElementById('active-page').scrollTop;
            //const screenHeight = window.screen.height;
            const screenHeight = list.scrollHeight;
            const offsetHeight = list.offsetHeight;
            // console.log('%c scrolled:%d','color:green',scrollTop);
            props.dispatch({type:'ACTIVITY_SCROLL_POSITION',payload:scrollTop});
            
            if (scrollTop >= (screenHeight -  offsetHeight* 1.5)) {
                clearTimeout(timer);
                if(loading){
                    //is loading more, do not repeat.
                    return;
                }

                timer = setTimeout(() => {
                    // props.dispatch({type:'ACTIVITY_LIST_RELOAD_START'});
                    setLoading(true);
                    let maxTime=(new Date().valueOf())*2;
                    if(props.list&&props.list.length>1){
                        maxTime=props.list[props.list.length-1].maxTime
                    }

                    if(props.currentTab==0)
                        service.loadFollowOpusListMore(props.list?.length).then(data=>{
                            // console.log(data);
                            if(data.opusList&&data.opusList.length){
                                
                            props.dispatch({type:'ACTIVITY_LOAD_MORE',payload:data.opusList});
                            }
                        }).finally(()=>{
                            console.log('done');
                            // loading=false;
                            setLoading(false)
                        })
                    else
                        service.loadOpusListMore(props.list?.length).then(data=>{
                            // console.log(data);
                            if(data.opusList&&data.opusList.length){
                                
                                props.dispatch({type:'ACTIVITY_LOAD_MORE',payload:data.opusList});
                            }
                        }).finally(()=>{
                            console.log('done');
                            setLoading(false);
                            // loading=false;
                            // props.dispatch({type:'ACTIVITY_LIST_RELOAD_END'});
                        })
                }, 100);
            }
        };

        list.addEventListener('scroll', handleScroll);
        // list.addEventListener('touchmove', handleScroll);
        return () => {
          clearTimeout(timer);
          list.removeEventListener('scroll', handleScroll);
        //   list.removeEventListener('touchmove', handleScroll);
        };
      }, [listRef,loading,setLoading,props]);

    const navList=[
        {
                title:'关注',
                isActive:false,
                id:'1'
        },
        {
                title:'动态',
                isActive:true,
                id:'2'
        }
    ];
    
    const o2ohome = localStorage.getItem('o2ohome');
      
    return (<div className='active-page' id="active-page" ref={listRef}>
                <NavBar activity={navList} activeTab={props.currentTab} style={{
                    position: 'fixed',
                    top: 0,
                    backgroundColor: 'white',
                    left: 0,
                    right: 0,
                    zIndex: 100,
                    paddingBottom: 15,
                    paddingTop:'0.45rem'
                }}
                showSearch={o2ohome=='true'}
                right={
                    o2ohome=='false'?<div className='active-o2obtn' >
                        <Link to='/live'><canvas id='livelink'></canvas></Link>
                    </div>:null
                }
                reload = {
                    (idx)=>{
                        props.dispatch({type:'ACTIVITY_SET_TAB',payload:idx})
                    }
                }
                />
                {
                    props.list?
                    <>
                    <ActiveListItem   activeList={props.list} headUrl = {location?.state?.headUrl}/>
                    {props.list?.length<props.totalCount?<div style={{height:'1rem'}} className='fx-box fx-ai-s fx-jc-c font-size13 font-color7'>加载中...</div>:
                    <div style={{height:'1rem'}} className='fx-box fx-ai-s fx-jc-c font-size13 font-color7'>到底了</div>
                    }
                    </>
                    :null
                   
                }
                {/* {
                    props.loading? <div className='font-size13 fx-box fx-ai-c fx-jc-c'>
                    <div style={{marginTop:20}}>加载中...</div>
                </div>:null
                } */}
                
        </div>);
}



export default connect((state)=>{
    return {...state.Activity}
},
(dispatch)=>({dispatch})
)(Activity);