import WebIM from "../components/easemob/WebIM";
import AppDB from "../components/easemob/db";


export const giftSend=(options)=>{
    var id = WebIM.conn.getUniqueId();
    console.log('%c webIM id:%d','color:green',id);
    console.log('%c webIM id:%o','color:green',options);
    var msg = new WebIM.message('txt', id);
    const ext = {
        time: new Date(),
        postHeader:options.postHeader,
        postName:options.postName,
        sendHeader:options.sendHeader,
        sendName:options.sendNickname,
        messageId:options.messageId,
        nmbs:options.nmbs,
        giftId:options.giftId
    };
    console.log('%c ===========EXT:','color:purple',ext)
    console.log('purple.dispatch',options.dispatch);
    msg.set({
        msg:'一条礼物消息',
        data:'一条礼物消息',
        from: options.from,
        to: options.from,
        ext,
        success: function (id, serverMsgId) {
            console.log('%c [DEBUG]msg send success serverMsgId:','color:blue',serverMsgId);
            const msgData = Object.assign({},msg.body,{isUnread:0,id:serverMsgId,fail:0,success:0,type:'chat',to:options.to});
            
            AppDB.addMessage(msgData).then((ret)=>{
                console.log('%c ADD TO DB RETUERN:','color:green',ret);
            }).catch(err=>{
                console.error(err);
            });
            console.log('%c [DEBUG]give gift dispatch:%o','color:purple',msgData);
            msgData.type='txt';
            options.dispatch({type:'sendMessage',payload:{body:msgData,meta:{
                type:'text',
                name:options.to,
                msg:'一条礼物消息', 
                isUnread:0,
                serverMsgId
            }}});
        },
        fail: function (err) {
            console.log("%c [DEBUG]发送文本消息失败:",'color:blue', err);
            if(err.type==503){
                const msgData = Object.assign({},msg.body,{isUnread:0,id:err.data.mid,fail:0,success:0,type:'chat',to:options.to});
                console.log("%c [DEBUG]发送文本消息失败,msg.body:",'color:blue',msg.body);
                // Object.assign({},msg.body,{isUnread:0,id:err.data.mid,fail:0,success:0,type:'chat'})
                msgData.type='txt';
                options.dispatch({type:'sendMessage',payload:{body:msgData,meta:{
                    type:'text',
                    name:options.to,
                    
                    msg:'一条礼物消息', 
                    isUnread:0,
                    serverMsgId:err.data.mid
                }}})
            }
        }
    });

    console.log('%c send:%o','color:green',msg.body)
    
    WebIM.conn.send(msg.body);
}
