import {Image} from 'react-vant';
import RankListItem from '../../components/rank-list-item/rank-list-item';
const RankingList=(props)=>{
    return (<div className='full-r-w theme-font rank-list-container fx-box fx-fd-c fx-ai-c'>
              <div className='rank-list-title fx-box fx-ai-c fx-jc-sb'>
                 <div className='fx-box fx-ai-e'>
                    <Image width={'0.19rem'} height={'0.24rem'} src='/static/images/fav-icon.png'/>
                    <span className='font-size10 list-title-hot'>本{props.period||'周'}热TOP10</span>
                 </div>
                 <div className='font-size10'>
                     <span className='list-title-day'>距离本期结束仅剩</span> {props.remain||'2天'}
                 </div>
              </div>

              <RankListItem list={props.list} indicatorIcon={props.indicatorIcon}/>
              
    </div>);
}
export default RankingList;