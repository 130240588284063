import React,{PureComponent, useEffect, useRef} from 'react';
import './msg-dialog.css';

export default (props)=>{    
    
   // 将滚动的高度重新计算赋值
//    componentDidUpdate(prevProps, prevState, prevScrollHeight) {
//     console.log('prevScrollHeight===>',prevScrollHeight);
//     let scrollHeight=this.rootNode.scrollHeight;
//     let clientHeight=this.rootNode.scrollHeight;
//     // 将滚动高度加上一个变化后的页面高度
//     if(prevScrollHeight){
//         this.rootNode.scrollTop = prevScrollHeight+scrollHeight+clientHeight;
//     }else{
//         this.rootNode.scrollTop = scrollHeight+clientHeight;
//     }
    
//    }
    const msgRef=useRef();
    useEffect(()=>{
        
        setTimeout(()=>{
            msgRef.current&&(msgRef.current.scrollTop=msgRef.current.scrollHeight);
        },30)

    },[props.messages])

    return (
        <>
        {props.show?(<div  className='layout-fx full-r-w full-r-h z-index2 msg-dialog-container'>
            
            <div className='full-r-h layout-rel z-index2' >
                <div className='msg-dialog-close' onClick = {
                        ()=>{
                            console.log('close btn is closed');        
                            props.onClose()
                    }
                    }>
                        <img src='/static/images/msg-dialog-close.png'/>
                </div>
                <div className="msg-dialog-mask font-size13 "
                ref={msgRef}
                    onClick ={()=>{
                    console.log('008 is clicked.')
                }}>
                    <div className='v-msg-item'>
                        {
                            props.messages.map((item,index)=>{
                                return (<div 
                                    key={'k_'+index}  
                                    className='v-msg-item-cxt full-r-w font-size13 font-color3'>{item.message}</div>)
                            })
                        }
                    </div>
                </div>
                
            </div>

        </div>):<div></div>}
    </>
    );

}