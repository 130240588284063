import React, { useEffect ,useRef,useState} from 'react';
import { useParams } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import { DefaultHeadUrl } from '../../service/images';
import './Fans.css';


const FanRank = (props)=>{
    const images = ['/static/images/fun1.png','/static/images/fun2.png','/static/images/fun3.png']
    if(props.index>=0&&props.index<3){
       return <img src={images[props.index]}></img> 
    }
    else 
        return <span>{props.index+1}</span>
}

const Fans=()=>{
    const profile = JSON.parse(localStorage.getItem('profile'));
    const listRef = useRef();

    const [blist,setList] = useState(null);
    let loading = false;
    const params = useParams();
    useEffect(()=>{
        if(!blist&&params){
            service.customerList(params.id,0).then(data=>{
                if(data.code==0)
                {
                    console.log('%c data:%o','color:green',data);
                    setList(data.userList);
                }
                
            })
        }

        const list = listRef.current;
        let timer;

        // const handleScroll = ev => {
        
        //     const scrollTop = document.getElementById('root').scrollTop;
        //     //const screenHeight = window.screen.height;
        //     const screenHeight = list.scrollHeight;
        //     const offsetHeight = list.offsetHeight;
            
        //     if (scrollTop >= (screenHeight -  offsetHeight* 1.5)) {
        //         clearTimeout(timer);
        //         if(loading){
        //             //is loading more, do not repeat.
        //             return;
        //         }

        //         timer = setTimeout(() => {
        //             loading = true;    
                    
        //             service.customerList(params.id,blist.length).then(data=>{
        //                 console.log(data);
        //                 if(data.userList&&data.userList.length){
        //                     setList(old => [ ...old,
        //                         ...data.userList
        //                     ]);
        //                 }
        //             }).finally(()=>{
        //                 console.log('done');
        //                 loading=false;
        //             })
        //         }, 10);
        //     }
        // };

        // list.addEventListener('wheel', handleScroll);
        // list.addEventListener('touchmove', handleScroll);
        // return () => {
        //   clearTimeout(timer);
        //   list.removeEventListener('wheel', handleScroll);
        //   list.removeEventListener('touchmove', handleScroll);
        // };


    },[blist,setList])
      return (
        <div className="black-page hide-x">
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'亲密榜'}></Navigation>
           <div className='fan-list full-r-w fx-box fx-fd-c fx-ai-c layout-mt60' ref={listRef}>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='fanItem fx-box fx-jc-sb fx-ai-c'>
                        {/* <div className='fx-box fx-fd-r'> */}
                            <div className='font-size15 fanIndex'>
                               <FanRank index={index}></FanRank>
                            </div>
                            {/* <Image width={'0.41rem'} height={'0.41rem'} round src={item.headUrl}/> */}
                            <div className='fan-head'>
                                <img src={item.headUrl||DefaultHeadUrl}/>
                            </div>
  
                           <div className='black-userid fx-box fx-fd-c  fx-jc-sb fx-1'>
                              <div className='font-size15'>{item.nickname}</div>
                              <div className='font-size12 fx-box fx-jc-s fx-ai-c'>
                                <img src='/static/images/fun-consume.png' style={{width:17}}></img>
                                <span>{item.consume}</span>
                                </div>
                           </div>

                            <div className='withdraw-record-right fx-box fx-fd-c' >
                                {/* <div className='font-size12 font-color11 fx-box fx-jc-e'>{item.statusDesp}</div>
                                <div className='font-size13 font-color9 fx-box fx-jc-e'>{item.withdrawXp}钻石</div> */}
                            </div>
                        {/* </div> */}
  
                       
                    </div>)
                    })
                 }
           </div>
          
        </div>
      );
}

export default Fans;