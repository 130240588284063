import React, { useEffect ,useState} from 'react';
import { useParams } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import './Comment.css';
const Comment=()=>{
    const profile = JSON.parse(localStorage.getItem('profile'));
    const [blist,setList] = useState([]);
    const [comment,setComment] = useState('');
    const pageParam = useParams();
    useEffect(()=>{
        if(!blist.length&&pageParam.id){
            service.commentsByID(pageParam.id).then(data=>{
                console.log('comment:',data);
                if(data.code==0)
                setList(data.commentList);
            })
            return;
        }
    },[setList])
      return (
        <div className="all-comment-page hide-x fx-box fx-fd-c">
           <Navigation style={{paddingTop:20}} theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'全部评论'}></Navigation>
           <div className='full-r-w fx-box fx-1 fx-fd-c' style={{paddingTop:'0.5rem'}}>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='commentItem fx-box fx-jc-sb fx-ai-c'>
                        <div className='fx-box fx-1'>
                            <Image width={'0.41rem'} height={'0.41rem'} round src={item.headUrl}/>
                            <div className='fx-box fx-1 fx-fd-c'>
                                <div className='comment-userid fx-box fx-fd-r '>
                                    <div className='font-size15'>{item.nickName}</div>
                                    
                                    <div className='font-size13' style={{marginLeft:'auto'}}>{item.timeStr}</div>
                                </div>
                                
                                <div className='comment-content font-size13'>
                                        {item.content}
                                </div>
                            </div>
                           
                        </div>
                       
                    </div>)
                    })
                 }
           </div>
           <div className='font-size13' style={{padding:'8px 8px',paddingBottom:40}}>
                 <input
                 className='comment-input'
                  placeholder='输入评论内容'
                  value={comment} onChange={(e)=>{setComment(e.target.value)}}></input>
                 <button 
                 className='comment-submit'
                 onClick = {()=>{
                    service.addComment(pageParam.id,comment).then(()=>{
                        Toast.info('评论已经提交,等待审核.');
                    });
                    blist.push({
                        nickName:profile.nickname,
                        headUrl:profile.headUrl,
                        content:comment,
                        timeStr:'刚刚'
                    })
                    setList(blist.slice());
                    setComment('');
                    
                 }}>评论</button>
           </div>
        </div>
      );
}

export default Comment;