import React, { useContext, useEffect,useState } from 'react';
import {Image, Overlay,Toast,Picker,Field,Popup,DatetimePicker,Uploader,Dialog} from 'react-vant';
import { useNavigate, useLocation } from 'react-router-dom';
import Navigation from "../../components/navigation/navigation";
import './StarCertificate.css';
import * as service from '../../service';
import moment from 'moment';
import AreaData from '../../assets/area.json';
import { StarContext } from './StarContext';

const columns = (()=>{
    const c = [];
    for(let i = 155;i<200;i++){
        c.push(i+'cm');
    }
    return c;
})();

const weightColumns = (()=>{
    const c=[];
    for(let i = 40;i<70;i++){
        c.push(i+'kg');
    }
    return c;
})();

const threeColumns = (()=>{
    const c = [];
    for (let i=20;i<50;i++){
        c.push(i+'');
    }
    return [{values:c,defaultIndex:1},{values:c,defaultIndex:1},{values:c,defaultIndex:1}];
})();

const starSignColumns = ["白羊座","金牛座","双子座","巨蟹座","狮子座","处女座","天秤座","天蝎座","射手座","摩羯座","水瓶座","双鱼座"];
const areaToString = (areaObj)=>{
    console.log(areaObj);
    if(!areaObj||!areaObj.length||areaObj.length!=3) return '';
    
    if(areaObj.length==1||!areaObj[1])return areaObj[0].text;

    if(areaObj[0].text==areaObj[1].text)
        return areaObj[1].text+','+areaObj[2].text;
    else return areaObj[0].text+','+areaObj[1].text+','+areaObj[2].text;
    
}

const StarCertificate=()=>{
    const navigate = useNavigate();
    const data = useContext(StarContext);

    const [showSubmitConfirmDialog,setShowSubmitConfirmDialog] = useState(false);
    const [showVideoCertDialog,setShowVideoCertDialog] =useState(false);

    const [myInfo, setMyInfo] = useState(null);
    const [showHeightPicker, setShowHeightPicker] = useState(false);
    const [showSexPicker, setShowSexPicker] = useState(false);
    const [showWeightPicker, setShowWeightPicker] = useState(false);
    const [showStarSignPicker,setShowStarSignPicker] = useState(false);
    const [show3Picker, setShow3Picker] = useState(false);
    const [showBirthPicker, setShowBirthPicker] = useState(false);
    const [showAreaPicker, setShowAreaPicker] = useState(false);
    
    

    const [imgHead,setImgHead]  = useState(null);
    const [imgBack,setImgBack]  = useState(null);
    const [videoCert, setVideoCert]  = useState(null);
    const [videoHead,setVideoHead]  = useState(null);
    const [nickName, setNickName] = useState('');
    const [personalSign, setPersonalSign]  = useState('');
    const [jobLabel, setJobLabel] = useState('');
    const [height, setHeight]  = useState('');
    const [weight,setWeight] = useState('');
    const [starSign, setStarSign]  = useState('');
    const [threeDimension, setThreeDimension]  = useState('');
    const [birth, setBirth] = useState('');
    const [sex,setSex] = useState('女');
    const [area,setArea] = useState('');
    const [wx,setWx] = useState('');
    const [kyc,setKyc] =useState(null);
    const [checkCode] = useState((Math.random()*10000+10000+'').slice(1,5))

    const location = useLocation();


    const collectState = ()=>{
        return {
            imgHead,
            imgBack,
            videoCert,
            videoHead,
            nickName,
            personalSign,
            jobLabel,
            height,
            weight,
            starSign,
            threeDimension,
            birth,
            sex,
            area,
            wx,
            kyc
        }
    }

    useEffect(()=>{
        if(!myInfo){
            service.queryMyselfInfo().then(data=>{
                setMyInfo(data);
                setNickName(data.user.nickname);
            })
        }

        const oldState = data.state;
        if(location?.state?.kyc){
            setKyc(location.state.kyc);
        }
        if(oldState){
            console.log()
            oldState.imgHead&&setImgHead(oldState.imgHead);
            oldState.nickName&&setNickName(oldState.nickName);
            oldState.personalSign&&setPersonalSign(oldState.personalSign);
            oldState.jobLabel&&setJobLabel(oldState.jobLabel);
            oldState.imgBack&&setImgBack(oldState.imgBack);
            oldState.videoCert&&setVideoCert(oldState.videoCert);
            oldState.videoHead&&setVideoHead(oldState.videoHead);
            oldState.birth&&setBirth(oldState.birth);
            oldState.wx&&setWx(oldState.wx);
            oldState.threeDimension&&setThreeDimension(oldState.threeDimension);
            oldState.height&&setHeight(oldState.height);
            oldState.sex&&setSex(oldState.sex);
            oldState.weight&&setWeight(oldState.weight);
            oldState.starSign&&setStarSign(oldState.starSign);
            oldState.area&&setArea(oldState.area);
        }
      
    },[setMyInfo,setNickName,location])
      return (
        
        <div className="star-page hide-x theme-font">
            <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'女神认证'}></Navigation>
            <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt60' style={{paddingBottom:80}}>
                {/**fx-box fx-jc-sb fx-ai-c**/}
                <div className='star-form-item font-size17 font-w6'>
                    必填资料
                </div>
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        label="头像"
                    >
                        <div style={{flex:1}}></div>
                        <Uploader afterRead={(obj) => {
                            setImgHead(obj);
                        }
                        }>
                            {/* <img src={imgFront?.content??img_id_front} style={{width:'1rem'}}/> */}
                            <Image src={imgHead?.content??'/static/images/avatar-01.png'} width={'0.28rem'} height={'0.28rem'} round/>
                        </Uploader> 
                    </Field>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        label="昵称"
                        value={nickName}
                        placeholder="请输入昵称"
                        onChange={(v)=>{
                            setNickName(v);
                        }}
                    />
                </div>
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                <Field
                        label="个性签名"
                        value={personalSign}
                        placeholder="请输入您的个人签名"
                        onChange={(v)=>{
                            setPersonalSign(v);
                        }}
                    />
                </div>
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        label="职业标签"
                        value={jobLabel}
                        placeholder="请输入职业标签"
                        onChange={(v)=>{
                            setJobLabel(v);
                        }}
                    />
                </div>

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        clickable
                        label="上传背景图"
                    >
                        <div style={{flex:1}}></div>
                        <Uploader afterRead={(obj) => {
                            setImgBack(obj);
                        }
                        }>
                            {/* <img src={imgFront?.content??img_id_front} style={{width:'1rem'}}/> */}
                            <Image src={imgBack?.content??'/static/images/user-03.png'} width={'0.38rem'} height={'0.28rem'}/>
                            {/* <Image src='/static/images/user-03.png' width={'0.38rem'} height={'0.28rem'}/> */}
                        </Uploader> 
                       
                    </Field>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>   
                </div>
                
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        clickable
                        label="身份认证"
                        onClick={
                            () =>{
                                const star_1 = collectState();
                                data.setState(star_1);
                                navigate('/kyc')
                            }
                        }
                    >
                        <div style={{flex:1}}></div>
                       
                    </Field>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        clickable
                        label="上传认证视频"
                        onClick={()=>{
                            
                            setShowVideoCertDialog(true);
                        }}
                    >
                        <div style={{flex:1}}></div>
                            {/* <img src={imgFront?.content??img_id_front} style={{width:'1rem'}}/> */}
                        <Image src={imgBack?.content??'/static/images/user-03.png'} width={'0.38rem'} height={'0.28rem'}/>
                    </Field>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>
                

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                <Field
                    readonly
                    clickable
                    label="生日"
                    value={birth?moment(birth).format('YYYY-MM-DD'):birth}
                    placeholder="选择生日"
                    onClick={() => setShowBirthPicker(true)}
                />
                <Popup
                    round
                    title="请选择日期"
                    closeable
                    visible={showBirthPicker}
                    position="bottom"
                    onClose={() => setShowBirthPicker(false)}
                >
                    <div style={{ padding: '0 8px' }}>
                    <DatetimePicker
                        type="date"
                        onConfirm={(value) => {
                        setBirth(value);
                        setShowBirthPicker(false);
                        }}
                        filter={(type, options) => {
                        if (type === 'minute') {
                            return options.filter((option) => +option % 5 === 0);
                        }
                        return options;
                        }}
                        minDate={new Date(1970, 0, 1)}
                        maxDate={new Date(2004, 2, 1)}
                        value={birth}
                    />
                    </div>
                </Popup>
                </div>
                

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                            label="微信号"
                            value={wx}
                            placeholder="请输入您对外公开的微信号"
                            onChange={(v)=>{
                                setWx(v);
                            }}
                    />
                    
                </div>
                

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        clickable
                        label="三围"
                        value={threeDimension}
                        placeholder="选择三围"
                        onClick={() => setShow3Picker(true)}
                    />
                    <Popup round visible={show3Picker} position="bottom" onClose={() => setShow3Picker(false)}>
                        <Picker
                        title="三围"
                        columns={threeColumns}
                        onCancel={()=>{setShow3Picker(false)}}
                        onConfirm={(value) => {
                            setThreeDimension(value);
                            setShow3Picker(false);
                        }}
                        />
                    </Popup>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>


                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    
                    <Field
                        readonly
                        clickable
                        label="相册"
                        onClick={() =>{
                            const star_1 = collectState();
                            console.log('%c star data:%o','color:green',star_1);
                            data.setState(star_1);
                            navigate('/album')}}
                        >
                        <div style={{flex:1}}></div>
                        <Image src='/static/images/user-03.png' width={'0.38rem'} height={'0.28rem'}/>
                        
                    </Field>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>
                
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        clickable
                        label="视频头像"
                        onClick={() => setShowBirthPicker(true)}
                    >
                        <div style={{flex:1}}></div>
                        <Uploader afterRead={(obj) => {
                            setVideoHead(obj);
                        }
                        }>
                            {/* <img src={imgFront?.content??img_id_front} style={{width:'1rem'}}/> */}
                            <Image src={imgBack?.content??'/static/images/user-03.png'}
                             width={'0.38rem'} height={'0.28rem'}/>
                            {/* <Image src='/static/images/user-03.png' width={'0.38rem'} height={'0.28rem'}/> */}
                        </Uploader> 
                       
                    </Field>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>
                {/**选填资料**/}

                <div className='star-form-item font-size17 font-w6 layout-mt24'>
                    选填资料
                </div>

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                            readonly
                            clickable
                            label="性别"
                            value={sex}
                            placeholder="选择性别"
                            onClick={() => setShowSexPicker(true)}
                        />
                        <Popup round visible={showSexPicker} position="bottom" 
                            onClose={() => setShowSexPicker(false)}>
                            <Picker
                            title="性别"
                            columns={['女','男','其他']}
                            onCancel={()=>{setShowSexPicker(false)}}
                            onConfirm={(value) => {
                                setSex(value);
                                setShowSexPicker(false);
                            }}
                            />
                        </Popup>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>


                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    {/* <div className='font-size15 font-color9'>身高</div> */}
                    
                    {/* <span className='font-w5 font-size15 font-color9 star-text'>162cm</span> */}
                    <Field
                            readonly
                            clickable
                            label="身高"
                            value={height}
                            placeholder="选择身高"
                            onClick={() => setShowHeightPicker(true)}
                        />
                        <Popup round visible={showHeightPicker} position="bottom" onClose={() => setShowHeightPicker(false)}>
                            <Picker
                            title="身高"
                            columns={columns}
                            onCancel={()=>{setShowHeightPicker(false)}}
                            onConfirm={(value) => {
                                setHeight(value);
                                setShowHeightPicker(false);
                            }}
                            />
                        </Popup>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                    
                </div>

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                <Field
                            readonly
                            clickable
                            label="体重"
                            value={weight}
                            placeholder="选择体重"
                            onClick={() => setShowWeightPicker(true)}
                        />
                        <Popup round visible={showWeightPicker} position="bottom" onClose={() => setShowWeightPicker(false)}>
                            <Picker
                            title="体重"
                            columns={weightColumns}
                            onCancel={()=>{setShowWeightPicker(false)}}
                            onConfirm={(value) => {
                                setWeight(value);
                                setShowWeightPicker(false);
                            }}
                            />
                        </Popup>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                            readonly
                            clickable
                            label="星座"
                            value={starSign}
                            placeholder="选择星座"
                            onClick={() => setShowStarSignPicker(true)}
                        />
                        <Popup round visible={showStarSignPicker} position="bottom" onClose={() => setShowStarSignPicker(false)}>
                            <Picker
                            title="星座"
                            columns={starSignColumns}
                            onCancel={()=>{setShowStarSignPicker(false)}}
                            onConfirm={(value) => {
                                setStarSign(value);
                                setShowStarSignPicker(false);
                            }}
                            />
                        </Popup>
                    <i className='star-right-menu iconfont icon-weibiaoti--'></i>
                </div>

                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        readonly
                        clickable
                        label="所在地区"
                        value={areaToString(area)}
                        placeholder="所在地区"
                        onClick={() => setShowAreaPicker(true)}
                    />
                    <Popup round visible={showAreaPicker} position="bottom" onClose={() => setShowAreaPicker(false)}>
                            <Picker
                            title="所在地区"
                            columns={AreaData}
                            onCancel={()=>{setShowAreaPicker(false)}}
                            onConfirm={(value) => {
                                // console.log('selected:',value[0].text,value[1].text,value[2].text);
                                setArea(value);
                                setShowAreaPicker(false);
                            }}
                            />
                    </Popup>
                </div>

                <div className="fx-btn layout-fx full-r-w">
                    <div className="fx-submit btn-bg1 font-size15 font-w6 font-color3" onClick = {()=>{
                        setShowSubmitConfirmDialog(true);
                        /*
                        params[@"threeDimensional"] = self.modaModel.sanwei.length > 0 ? self.modaModel.sanwei : @"";
        params[@"starSign"] = self.modaModel.xingzuo.length > 0 ? self.modaModel.xingzuo : @"";
        params[@"birthDay"] = self.modaModel.shengri.length > 0 ? self.modaModel.shengri : @"";
        params[@"city"] = self.cityCode.length > 0 ? self.cityCode : @"";
        params[@"province"] = self.modaModel.city.length > 0 ? self.modaModel.city : @"";
        params[@"cityName"] = self.modaModel.cityName.length > 0 ? self.modaModel.cityName : @"";
        params[@"favoriteBody"] = self.modaModel.body.length > 0 ? self.modaModel.body : @"";
        params[@"sexOpinions"] = self.modaModel.sexlook.length > 0 ? self.modaModel.sexlook : @"";
        params[@"idNum"] = self.idCard;
        params[@"realName"] = self.realName;
        params[@"idPicture"] = [self.photos subarrayWithRange:NSMakeRange(0, 2)];
        params[@"idPicture2"] = [self.photos lastObject];
        params[@"authenticationVedio"] = self.videoPath.length > 0 ? self.videoPath : @"";
        params[@"checkCode"] = self.checkCode;
        
        params[@"authenticationType"] = @(1);
        params[@"userIdApply"] = @(model.userId);
        
        params[@"personalPageImgUrl"] = self.backImageUrl.length > 0 ? self.backImageUrl : @"";
        params[@"vedioHeadImgUrl"] = self.ontToOneImageUrls.count > 0 ? self.ontToOneImageUrls : @"";
        params[@"vedioHeadVdioUrl"] = self.videoUrl.length > 0 ? self.videoUrl : @"";
        params[@"wechat"] = self.wxString.length > 0 ? self.wxString : @"";
        params[@"wechatPrice"] = @"0";
                        */
                    }}>提交审核</div>
                </div>
            </div>
            <Dialog
                visible={showSubmitConfirmDialog}
                title='提交'
                showCancelButton
                onCancel={()=>{
                    setShowSubmitConfirmDialog(false);
                }}
                onConfirm={async ()=>{
                    // service.updateUser({personalSign});
                    const param = {
                        threeDimensional:threeDimension+'',
                        starSign,
                        birthDay:birth,
                        city:area[0].code,
                        province:area[0].name,
                        cityName:area[1].name,
                        favoriteBody:'',
                        sexOpinions:'',
                        idNum:kyc.ID,
                        realName:kyc.realName,
                        idPicture:kyc.imgs[0],
                        idPicture2:kyc.imgs[1],
                        authenticationVedio:videoCert,
                        checkCode:checkCode,
                        authenticationType:'1',
                        userIdApply:myInfo.user.userId,
                        personalPageImgUrl:imgBack,
                        vedioHeadImgUrl:imgHead,
                        vedioHeadVdioUrl:videoHead,
                        wechat:wx,
                        wechatPrice:'0'

                    }
                    await service.applyHost(param);

                    Toast.info('已提交审核')
                    // getUserDetail();
                    setShowSubmitConfirmDialog(false);
                }}
                >
                    <div className='font-size13' style={{padding:'0.1rem'}}>
                        确定要提交资料?
                    </div>
                </Dialog>
                <Dialog
                    visible={showVideoCertDialog}
                    title='验证'
                    showCancelButton
                    onCancel={()=>{
                        setShowVideoCertDialog(false);
                    }}
                    onConfirm={async ()=>{
                        // service.updateUser({personalSign});
                        
                        // getUserDetail();
                        setShowVideoCertDialog(false);
                    }}
                    >
                    <div className='font-size13 fx-box fx-fd-c fx-ai-c' style={{padding:'0.1rem'}}>
                        请录制视频,并且在视频中清晰读出:{checkCode}. 完成之后请点击下方按钮上传视频.
                        <Uploader afterRead={(obj) => {
                            setVideoCert(obj);
                        }
                        }>
                            {/* <img src={imgFront?.content??img_id_front} style={{width:'1rem'}}/> */}
                            {/* <Image style={{margin:'0.2rem'}} src={} width={'1rem'} height={'1rem'}/> */}
                            {/* <Image src='/static/images/user-03.png' width={'0.38rem'} height={'0.28rem'}/> */}
                        </Uploader> 
                    </div>
                </Dialog>
        </div>
        
      );
}

export default StarCertificate;