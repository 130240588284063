import React, { useEffect,useState } from 'react';
import {Image, Overlay,Toast,Picker,Field,Popup,Uploader, Loading} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import './KYC.css';
import * as service from '../../../service';
import moment from 'moment';



import img_id_front from '../../../assets/images/id_front.png';
import img_id_back from '../../../assets/images/id_back.png';
import img_id_hold from '../../../assets/images/id_hold.png';
import { uploadS3 } from '../../../service/s3';
import { useLocation, useNavigate } from 'react-router-dom';

const KYC=()=>{
    const navigate = useNavigate();
    const [realName,setRealName] = useState('');
    const [ID,setID] = useState('');
    const [imgFront,setImgFront] = useState(null);
    const [imgBack,setImgBack] = useState(null);
    const [imgHold,setImgHold] = useState(null);
    const [uploading,setUploading] = useState(false);
    
      return (
        <div className="star-page hide-x theme-font">
            <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'身份认证'}></Navigation>
            <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt60'>
               
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        label="真实姓名"
                        value={realName}
                        placeholder="输入真实姓名"
                        onChange={(v)=>{
                            setRealName(v);
                        }}
                    />
                </div>
                
                <div className='star-form-item fx-box fx-jc-sb fx-ai-c star-form-h'>
                    <Field
                        label="身份证号"
                        value={ID}
                        placeholder="输入身份证号"
                        onChange={(v)=>{
                            setID(v);
                        }}
                    />
                </div>
                <div className='star-form-item fx-box fx-fd-r'>
                    <div className='fx-box fx-fd-c fx-1'>
                        <div className='font-size13'>
                            身份证正面
                        </div>
                        
                        <Uploader afterRead={(obj) => {
                            console.log('%c [DEBUG]upload selected:','color:green',obj)
                            setImgFront(obj);
                        }
                        }>
                            <img src={imgFront?.content??img_id_front}  style={{width:'1rem'}}/>    
                        </Uploader> 
                        
                        
                    </div>
                    <div className='fx-box fx-fd-c fx-1'>
                        <div  className='font-size13'>
                            身份证反面
                        </div>
                        
                        <Uploader afterRead={(obj) => {
                            setImgBack(obj);
                        }
                        }>
                        <img src={imgBack?.content??img_id_back}  style={{width:'1rem'}}/>
                        </Uploader> 
                    </div>
                </div>
                <div className='star-form-item fx-box fx-fd-r'>
                    <div className='fx-box fx-fd-c fx-1'>
                        <div className='font-size13'>
                            手持身份证正面
                        </div>
                        <div>
                            <Uploader afterRead={(obj) => {
                            setImgHold(obj);
                        }
                        }>
                            <img src={imgHold?.content??img_id_hold} style={{width:'1rem'}}/>
                    
                        </Uploader> 
                            
                        </div>
                    </div>
                </div>
                {
                    uploading?<div style={{marginTop:32}}>
                        <Loading size="24px">上传中...</Loading>
                    </div>:null
                }

                <div className="fx-btn layout-fx full-r-w">
                    <div className="fx-submit btn-bg1 font-size15 font-w6 font-color3" onClick = {
                        async ()=>{
                            
                            if(!realName||!ID||!imgFront||!imgBack||!imgHold){
                                Toast({
                                    message: '身份信息和照片不全',
                                    icon: <Image width={50} src="/static/images/toast-icon.png" />,
                                });
                                return;
                            }
                            setUploading(true);

                            const key1 = await uploadS3(imgFront.file);
                            console.log('%c uploadS3 key1:%o','color:blue',key1)
                            const key2 = await uploadS3(imgBack.file);
                            console.log('%c uploadS3 key1:%o','color:blue',key2)
                            const key3 = await uploadS3(imgHold.file);
                            console.log('%c uploadS3 key1:%o','color:blue',key3)
                            const kyc = {imgs:[key1,key2,key3],realName,ID,};
                            //navigate(-1,{state})
                            setUploading(false);
                            navigate('/star-certificate',{state:{kyc}});
                        }
                    }>确定</div>
                </div>
            </div>
        </div>
      );
}

export default KYC;