import React, { useEffect ,useRef,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import NavBar from '../../components/nav-bar/nav-bar';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import './AddressBook.css';
const AddressBook=()=>{
    const profile = JSON.parse(localStorage.getItem('profile'));
    const listRef = useRef();

    const [blist,setList] = useState(null);
    const [currentTab,setCurrentTab] = useState(1);
    const navigate = useNavigate();
    
    let loading = false;
    useEffect(()=>{
        if(!blist){
            //default:粉丝
            service.addressList(0,1).then(data=>{
                if(data.code==0)
                {
                    console.log('%c data:%o','color:green',data);
                    setList(data.userList);
                }
                
            })
        }

        const list = listRef.current;
        let timer;

        const handleScroll = ev => {
        
            const scrollTop = document.getElementById('root').scrollTop;
            //const screenHeight = window.screen.height;
            const screenHeight = list.scrollHeight;
            const offsetHeight = list.offsetHeight;
            
            if (scrollTop >= (screenHeight -  offsetHeight* 1.5)) {
                clearTimeout(timer);
                if(loading){
                    //is loading more, do not repeat.
                    return;
                }

                timer = setTimeout(() => {
                    loading = true;    
                    
                    service.addressList(blist.length,currentTab).then(data=>{
                        console.log(data);
                        if(data.userList&&data.userList.length){
                            setList(old => [ ...old,
                                ...data.userList
                            ]);
                        }
                    }).finally(()=>{
                        console.log('done');
                        loading=false;
                    })
                }, 10);
            }
        };

        list.addEventListener('wheel', handleScroll);
        list.addEventListener('touchmove', handleScroll);
        return () => {
          clearTimeout(timer);
          list.removeEventListener('wheel', handleScroll);
          list.removeEventListener('touchmove', handleScroll);
        };


    },[currentTab,blist,setList])
      return (
        <div className="addressbook-page hide-x" style={{height:'95vh',overflow:'scroll'}}>
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} style={{paddingTop:30}} title={'通讯录'}></Navigation>
           <NavBar style={{paddingTop:80}} 
           activeTab={1}
           showSearch={false} 
           activity = {[
            {title:'好友',id:'0'},{title:'关注',isActive:true,id:'1'},{title:'粉丝',id:'2'}
           ]}
           reload = {(idx)=>{
                setCurrentTab(idx);
                service.addressList(0,idx).then(data=>{
                    console.log(data);
                    setList(data.userList);
                })
           }}
           />
           <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt24' ref={listRef}>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='blackItem fx-box fx-jc-sb fx-ai-c' onClick={()=>{
                            navigate('/person/'+item.userId);
                       }}>
                        {/* <div className='fx-box fx-fd-r'> */}
                           <Image width={'0.41rem'} height={'0.41rem'} round src={item.headUrl}/>
  
                           <div className='black-userid fx-box fx-fd-c  fx-jc-sb fx-1' >
                              <div className='font-size15'>{item.nickname}</div>
                              <div className='font-size12'>{item.createTime}</div>
                           </div>

                            <div className='withdraw-record-right fx-box fx-fd-c' >
                               {
                                ((flag)=>{switch(flag){
                                    case 2:
                                        return (
                                            <div className='address-follow font-size13 font-color9 fx-box fx-jc-e'>已关注</div>
                                        )
                                    case 1:
                                        return (
                                            <div className='font-size13 font-color9 fx-box fx-jc-e'>相互关注</div>
                                        )
                                    case 0:
                                        return (
                                            <div className='font-size13 font-color9 fx-box fx-jc-e'>未关注</div>
                                        )
                                }
                               })(item.attentionFlag)
                            }
                                
                            </div>
                        {/* </div> */}
  
                       
                    </div>)
                    })
                 }
                 <div style={{display:'block',width:100,height:100}}/>
           </div>
          
        </div>
      );
}

export default AddressBook;