import React,{useEffect, useState} from 'react';

import * as service from '../../service';
import { ImagePreview } from 'react-vant';
import {useNavigate} from 'react-router-dom';
import {FU_LIKE_IMG} from '../../service/images'
import { FU_UNLIKE_IMG } from '../../service/images';
const ActiveListItem=(props)=>{
    // console.log(props.headUrl);
    const navigate = useNavigate();
    const [updateTimestamp, setUpdateTimestamp] = useState(new Date().valueOf());
    useEffect(()=>{
        console.log('new update :',updateTimestamp);
    },[updateTimestamp])

    const renderPhoto=(item,idx)=>{
        if(item.photos){
            return( item.photos.map((src,index)=>{
                    return  <img className='userItemImg' key={'upk_'+index} src={src} onClick={
                        ()=>{
                            ImagePreview.open({images:item.photos,startPosition:index});
                        }
                }/>
            }))
            // if(item.photos.length==1){
            //     return <img className='userItemImg-1' src={item.photos[0]} onClick={
            //         ()=>{
            //             ImagePreview.open({images:item.photos});
            //         }
            //     }></img>
            // }
            // else return( item.photos.map((src,index)=>{
            //         return  <img className='userItemImg' key={'upk_'+index} src={src} onClick={
            //             ()=>{
            //                 ImagePreview.open({images:[src]});
            //             }
            //     }/>
            // }))
        }
        return null;
    }

    return (<div className='activeList' style={{...props.style}}>
        {
            props.activeList.map((item,idx)=>{
                    return (
                    <div key={idx} className='activeListItem'>
                        <div className='activeListUser fx-box fx-ai-c fx-jc'>
                            <div className={item.online===1?'activeListUserAvatar '+'activeListUserOnline':'activeListUserAvatar'}
                            onClick={()=>{
                                navigate('/person/'+item.modelId,{})
                            }}
                            >
                                <img    src={item.headUrl||props.headUrl}/>
                                {
                                    item.online?<div className='activeListUserOnlineTip'>{service.statusText(item.status)}</div>:<span></span>
                                }
                            </div>

                            <div className='theme-font userItemLevel fx-1 fx-box fx-jc-sb fx-ai-s'>
                                

                                <div className='fx-box fx-fd-c  fx-jc-sb'>
                                    <div className='fx-box fx-ai-c'>
                                        <div className='font-size17 font-w5'>{item.modelName}</div>
                                        {/* <div className='activeListItemLevel font-size13'>lv{item.userLeven}</div>
                                        <i className='iconfont icon-weixin'></i> */}
                                    </div>
                                    <div className='font-size13 font-color4'>{item.timeStr}</div>
                                </div>

                                
                                <div className='userItemMore fx-box fx-fd-c fx-ai-s'>
                                    <i className='iconfont icon-more'></i>
                                </div>
                                
                            </div>

                        </div>

                        <div className='userItemMsg theme-font font-size13'>{item.description}</div>

                        <div className='userItemAlbums'>
                            {item.videoUrl&&(<video controls width="250" poster={item.imgUrl}>
                                <source src={item.videoUrl.replace('http://','https://')}
                                        type="video/mp4"/>
                                </video>)
                            }
                            {
                               renderPhoto(item)
                            }
                        </div>

                        <div className='userItemPreview theme-font fx-box fx-ai-c fx-jc-sb'>
                            <div className='font-size15 font-color2'>
                                {item.reward}
                            </div>

                            <div className='userItemP fx-box fx-ai-c'>
                                <div className='fx-box fx-ai-c' onClick={()=>{
                                    navigate('/comment/'+item.id)
                                }}>
                                <i className='iconfont icon-xiaoxi3'></i>
                                <span className='font-size13 font-color4'>{item.commentNum}</span>
                                </div>

                                <div className='fx-box fx-ai-c' >
                                    {item.isCollect==1?
                                    <img src={FU_LIKE_IMG} style={{width:24,heigh:24}} onClick={()=>{
                                        service.uncollectActivity(item.id).then(ret=>{
                                            // console.log(ret);
                                            item.isCollect=0;
                                            item.collectNum=ret.collectNum;
                                            setUpdateTimestamp(new Date().valueOf());
                                        });
                                    }}></img>:
                                    <img src={FU_UNLIKE_IMG} style={{width:24,heigh:24}} onClick={()=>{
                                        service.collectActivity(item.id+'').then(ret=>{
                                            console.log('collect:',item.id+'',ret);
                                            item.isCollect=1;
                                            item.collectNum=ret.collectNum;
                                            setUpdateTimestamp(new Date().valueOf());
                                        });
                                    }}></img>}
                                    <span className='font-size13 font-color4'>{item.collectNum}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
            })
        }
    
    </div>);
}

export default ActiveListItem;