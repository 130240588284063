import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "../../components/navigation/navigation";
import './web.css';

const Web= ()=>{
    // const appInfo = JSON.parse(localStorage.getItem('appStart'));
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const location = useLocation();
    const tmpUrl = location.state.url.replace('http:','https:');
    // const [url,setUrl] = useState(tmpUrl);
    // const [ payUrl,setPayUrl] = useState();

    // useEffect(()=>{
    
        
    //     //userId=%d&agent=%@&env=PROD&scene=IOS&version=%@&tag=%@&userLevel=%@"
    //     // let tmpUrl = appInfo.payUrl+'?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version=1.0.2&tag='+appInfo.tag+'&userLevel=50';
        
    //     //let tmpUrl = 'https://pssr-t01.douquwl88.cn/?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version=1.0.2&tag='+appInfo.tag+'&userLevel=50';
    //     //tmpUrl=tmpUrl.replace('http:','https:');
    //     setPayUrl(tmpUrl)
    // },[setPayUrl]);
    return (
        <div className="web">
            <div className="fx-1">
            <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} style={{top:'0.2rem'}} title={' '}>

            </Navigation>
                <iframe src={tmpUrl} className='web-container'>

                </iframe>
            </div>
        </div>
    )
}

export default Web;