import React,{useState} from 'react';
import './live-list-item.css';

import * as service from '../../service';

const cssStatus=(code)=>{
    const codeCss={
        '0':'off-line-rd',
        '1':'on-line-rd',
        '2':'chatting-rd',
        '3':'active-rd',
        '4':'dnd-rd'
    }
    return codeCss[code];
}

const LiveListItem=function(props){
      let {onClickItem}=props;
      return (<div className='liveList' id='live-list'>
               {
                 props.liveList?.map((item,idx)=>{
                     return (
                        <div key={idx} className='liveListItem' onClick={()=>{
                            onClickItem(item);
                        }}>
                            <img src={service.personHead(item)}/>
                            <div className='on-line fx-box fx-ai-c fx-jc-e'>
                               <span className={cssStatus(item.status)}></span>
                               <span className='font-size10 font-color3 live-status-text'>{service.statusText(item.status)}</span>
                            </div>
                            <div className='live-item-bottom'>
                                <div className='user-info theme-font'>
                                    <div className='font-w5 font-color3 font-size18'>{item.Name}{item?.distanceDesp?(" | "+item?.distanceDesp):""}</div>
                                    <div className='font-w4 font-color3 font-size13 live-sign'>{item.sign}</div>
                                </div>
                                <div className='chat-price theme-font fx-box fx-ai-c'>
                                <span className='font-color3 font-size17 font-w5'>{item.vedioPrice}</span>
                                <span  className='font-color3 font-size13 font-w4'>金币/分钟</span>
                                </div>
                            </div>
                            
                        </div>
                     )
                 })
               }
      </div>);
} 

export default LiveListItem;