import React from 'react';
import {Image} from 'react-vant';
import { useNavigate} from 'react-router-dom'
const RankingHead=(props)=>{
    const navigate=useNavigate();
      return (
        <div className='rank-nav full-r-w' style={{backgroundColor:(props.activeKey==1?'#FC66FF':'#000000')}}>
                 <div className='rank-header fx-box fx-ai-c'>
                     <div onClick={()=>{
                        navigate(-1)
                     }} className='fx-box fx-ai-c'>
                       <i className='font-size17 font-color3 iconfont icon-weibiaoti-- font-w6 '></i>
                     </div>
                     <div className='fx-box fx-ai-c'>
                     {
                        props.headers.map((item,idx)=>{
                            return (<div key={idx} onClick={()=>{
                                console.log('item.key',item);
                                props.setTheme(item)
                                
                            }} className='layout-rel'>
                                <div className={item.key===props.activeKey?'font-size20 font-color3 font-w5':'font-size15 font-w4  font-color3'}>{item.title}</div>
                                <div className='rank-line-box fx-box fx-jc-c'>
                                        {
                                        item.key===props.activeKey?<div className='rank-line'></div>:<div></div>
                                        }
                                </div>
                                </div>)
                        })
                     }
                     </div>
                 </div>
               </div>
      );
}

export default RankingHead;