import React,{useEffect,useState,useRef}from 'react';
import './Activity.css';
import ActiveListItem from '../../components/active-list-item/active-list-item';
import * as service from '../../service';
import { ImagePreview } from 'react-vant';
import { useLocation, useParams } from 'react-router-dom';
import Navigation from '../../components/navigation/navigation';
const Activity=()=>{
    let loading = false;
    const [dataList,setDataList] = useState(null);
    const params = useParams();
    const location =useLocation();
    console.log(location);
    if(!dataList){
        service.loadOpusListOfModel(params.id).then(data=>{
            console.log('OPUS DATA:',data.opusList);
            setDataList(data.opusList);
        })
    }
    const listRef = useRef(null);
    useEffect(() => {
        const list = listRef.current;
        let timer;

        const handleScroll = ev => {
        
            const scrollTop = document.getElementById('active-page').scrollTop;
            //const screenHeight = window.screen.height;
            const screenHeight = list.scrollHeight;
            const offsetHeight = list.offsetHeight;
            
            if (scrollTop >= (screenHeight -  offsetHeight* 1.5)) {
                clearTimeout(timer);
                if(loading){
                    //is loading more, do not repeat.
                    return;
                }

                timer = setTimeout(() => {
                    loading = true;    
                    let maxTime=(new Date().valueOf())*2;
                    if(dataList&&dataList.length>1){
                        maxTime=dataList[dataList.length-1].maxTime
                    }
                    
                    service.loadOpusListMoreOfModel(params.id,dataList.length).then(data=>{
                        console.log(data);
                        if(data.opusList&&data.opusList.length){
                            
                            setDataList(old => [ ...old,
                                ...data.opusList
                            ]);
                        }
                    }).finally(()=>{
                        console.log('done');
                        loading=false;
                    })
                }, 10);
            }
        };

        list.addEventListener('wheel', handleScroll);

        return () => {
          clearTimeout(timer);
          list.removeEventListener('wheel', handleScroll);
        };
      }, [listRef, dataList,setDataList,loading]);

    const navList=[
        {
                title:'关注',
                isActive:false,
                key:'1'
        },
        {
                title:'动态',
                isActive:true,
                key:'2'
        }
    ];
    
    
      
    return (<div className='active-page' id='active-page' ref={listRef}>
                <Navigation theme={{color:'#000',bg:'#fff'}} style={{paddingTop:30,top:0}} height={'0.5rem'}  title={'动态'}></Navigation>
                {
                    dataList?<ActiveListItem activeList={dataList} headUrl = {location.state.headUrl}/>:
                    <div className='font-size13 fx-box fx-ai-c fx-jc-c'>
                        <div style={{marginTop:20}}>加载中...</div>
                    </div>
                }
                
        </div>);
}

export default Activity;