import React,{useState,useEffect} from 'react';
import { useNavigate, Link} from 'react-router-dom'
import './Mine.css';
import { Switch,Dialog, Overlay, Popup } from 'react-vant';
import * as service from '../../service';
import { UserLevelBadge } from '../../components/level-badge';


const userMenuList=[
      {
            icon:'iconfont icon-icon',
            image:require('../../assets/images/mine/mine-icon-rank.png'),
            title:'排行榜',
            menuType:'icon',
            path:'/ranking'
      },
      {
            icon:'iconfont icon-biaoqing',
            image:require('../../assets/images/mine/mine-icon-dnd.png'),
            title:'勿扰模式',
            menuType:'switch'
      },
      {
            icon:'iconfont icon-biaoqing',
            image:require('../../assets/images/mine/mine-icon-hidden.png'),
            title:'隐身模式',
            menuType:'switch'
      },
      {
            icon:'iconfont icon-qianbao',
            image:require('../../assets/images/mine/mine-icon-wallet.png'),
            title:'我的钱包',
            menuType:'icon',
            path:'/wallet'
      },
      
    //   {
    //         icon:'iconfont icon-dongtai',
    //         title:'我的动态',
    //         menuType:'icon'
    //   },
      {
            icon:'iconfont icon-shenfenzheng',
            image:require('../../assets/images/mine/mine-icon-starcert.png'),
            title:'女神认证',
            menuType:'icon',
            path:'/star-certificate'
      },
      {
            icon:'iconfont icon-xitongquanxian',
            image:require('../../assets/images/mine/mine-icon-auth.png'),
            title:'权限管理',
            menuType:'icon',
            path:'/authman'
      },
      {
            icon:'iconfont icon-heimingdan',
            image:require('../../assets/images/mine/mine-icon-blacklist.png'),
            title:'黑名单',
            menuType:'icon',
            path:'/blacklist'
      },
    //   {
    //         icon:'iconfont icon-qinmifu',
    //         title:'我的亲密榜',
    //         menuType:'icon'
    //   },
    //   {
    //         icon:'iconfont icon-shengriliwu',
    //         title:'收入的礼物',
    //         menuType:'icon'
    //   },
    //   {
    //         icon:'iconfont icon-lianxiren',
    //         title:'重要联系人',
    //         menuType:'icon'
    //   },
    //   {
    //         icon:'iconfont icon-magic-',
    //         title:'美颜设置',
    //         menuType:'icon'
    //   },
      {
            icon:'iconfont icon-kefu',
            image:require('../../assets/images/mine/mine-icon-cs.png'),
            title:'客服中心',
            menuType:'icon',
            path: '/chat/socialfu_138002'
      },
      {
            icon:'iconfont icon-shezhi',
            image:require('../../assets/images/mine/mine-icon-setting.png'),
            title:'设置',
            menuType:'icon',
            path:'/setting'
      },
    //   {
    //         icon:'iconfont icon-shezhi',
    //         image:require('../../assets/images/mine/mine-icon-setting.png'),
    //         title:'测试',
    //         menuType:'icon',
    //         path:'/test'
        
    //   }
];

const Mine=()=>{
    const navigate=useNavigate();
    const profile = JSON.parse(localStorage.getItem('profile'));
    const [notDisturb,setNotDisturb] = useState(null);
    const [ hidden, setHidden] = useState(null);

    const [myInfo,setMyInfo] = useState(null);
    const [showHiddenDialog,setShowHiddenDialog] = useState(false);
    const hiddenModeNoticed = localStorage.getItem('hidden_mode_noticed')=='true';
    const appStart=JSON.parse(localStorage.getItem('appStart'));
    const [showCertDialog,setShowCertDialog] = useState(false);
    useEffect(()=>{
        // console.log('%c [debug] 勿扰模式:','color:green',notDisturb,appStart);
        if(notDisturb==null){
            setNotDisturb(appStart.appInfo.notDisturb);
        }
        if(!myInfo){
            service.queryMyselfInfo().then(data=>{
                //   console.log('....myInfo:',data);
                  if(data.code==21){
                    navigate('/login');
                  }
               setMyInfo(data);
               setHidden(data?.user?.isHidden);
            });
        }
    },[myInfo,setMyInfo,notDisturb]);

    

    const renderSwitch= (item)=>{
        if(item.title=='勿扰模式'){
            
            return <Switch defaultChecked checked={notDisturb!='0'} style={{marginRight:8}}
            activeColor="#FC66FF" inactiveColor="#ffffff" 
            onChange={(checked) => {
                Dialog.confirm({
                  title: '提醒',
                  message: checked?'是否开启勿扰模式？':'是否关闭勿扰模式?',
                }).then(() => {
                    service.switchDND(checked).then(ret=>{
                        console.log('%c[DEBUG]switch disturb appstart:%o','color:green',appStart,checked);
                        appStart.appInfo.notDisturb=checked?'1':'0';
                        localStorage.setItem('appStart',JSON.stringify(appStart));
                        setNotDisturb(appStart.appInfo.notDisturb);
                    })
                });
            }}
            />
        }
        if(item.title == '隐身模式'){
            return <Switch defaultChecked checked={hidden} style={{marginRight:8}}
            activeColor='#FC66FF' inactiveColor='#ffffff'
            onChange={(checked) => {
                if(localStorage.getItem('hidden_noticed')!='true'){
                    setShowHiddenDialog(true);
                }
                
                // Dialog.confirm({
                //   title: '提醒',
                //   message: '是否切换隐身状态？',
                // }).then(() => {
                    service.switchHiddenMode(checked?'ON':'OFF').then(ret=>{
                        
                        setHidden(checked);
                    })
                // });
            }}
            />
        }
    }

      return (
            <div className='mine-page'>
               <div className='mine-header'>
                    <div className='mine-top fx-box fx-ai-c'>
                        <div className='avatars layout-rel'>
                              <img alt="" className='user-avatar' src={profile.headUrl||'/static/images/head.png'}/>
                              <div className='layout-abs theme-font font-size10 font-color3 text-center'>主页</div>
                        </div>
                        <div className='fx-1 fx-box layout-ml16 fx-ai-c fx-jc-sb'>
                           <div>
                              <div className='nickname theme-font fx-box fx-ai-c' >{profile.nickname}
                              <UserLevelBadge level={myInfo?.userInfo?.userLeven??1}></UserLevelBadge>
                              </div>
                              <div className='user-id'>ID: {profile.userId}<span className='id-icon'><i className='iconfont icon-book'></i></span></div>
                           </div>
                           <div className='mine-edit fx-box fx-ai-c fx-jc-sb' onClick = {
                            ()=>{
                                navigate('/personedit');
                            }
                           }>
                             <span>编辑</span>
                             <i className='iconfont icon-weibiaoti--'></i>
                           </div>
                        </div>
                    </div>

                    <div className='mine-center theme-font fx-box'>
                         <div className='centerItem fx-1 fx-box fx-fd-c fx-ai-c' >
                            <Link to='/address'>
                              <div className='font-size18 font-color1'>{myInfo&&myInfo?.userInfo?.myAttentions}</div>
                              <div className='font-color4 font-size11'>关注</div>
                            </Link>
                         </div>
                         
                         <div className='centerItem fx-1 fx-box fx-fd-c fx-ai-c'>
                              <div className='font-size18'>{myInfo&&myInfo.userInfo.wealthXz}</div>
                              <div className='font-color4 font-size11'>金币</div>
                         </div>
                         <div className='centerItem fx-1 fx-box fx-fd-c fx-ai-c'>
                              <div className='font-size18'>{myInfo&&myInfo.userInfo.wealthXp}</div>
                              <div className='font-color4 font-size11'>钻石</div>
                         </div>
                    </div>
               </div>

               <div className='mine-menu fx-box fx-ai-c fx-jc-sa'>
                    <div className='fx-1 fx-box fx-fd-c fx-ai-c' onClick={()=>{
                        navigate('/pay');
                        //window.open('alipay://alipayclient/?%7B%22requestType%22%3A%22SafePay%22%2C%22fromAppUrlScheme%22%3A%22alipays%22%2C%22dataString%22%3A%22h5_route_token%3D%5C%22RZ546uagzVZ79D8uPcdXWuwC3cUXd0mobilecashierRZ54%5C%22%26is_h5_route%3D%5C%22true%5C%22%26h5_route_senior%3D%5C%22true%5C%22%22%7D');
                    }}>
                        <div className='menuItem menu-bg1 fx-box fx-ai-c fx-jc-c'>
                             {/* <i className='iconfont icon-chongzhi'></i> */}
                             <img src="/static/images/mine-deposit.png"/>
                        </div>
                        <div className='theme-font font-size13 font-w5 text-center'>充值</div>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c fx-ai-c' onClick={()=>{
                        navigate('/mycircle');
                    }}>
                        <div className='menuItem menu-bg2 fx-box fx-ai-c fx-jc-c'>
                            {/* <i className='iconfont icon-quanzi'></i> */}
                            <img src="/static/images/mine-fu.png"/>
                        </div>
                        <div className='theme-font font-size13 font-w5 text-center'>我的圈</div>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c fx-ai-c' onClick={()=>{
                        navigate('/live');
                    }}>
                        <div className='menuItem menu-bg3 fx-box fx-ai-c fx-jc-c'>
                            {/* <i className='iconfont icon-shipin'></i> */}
                            <img src="/static/images/mine-o2o.png"/>
                        </div>
                        <div className='theme-font font-size13 font-w5 text-center'>视频交友</div>
                    </div>
                    <div className='fx-1 fx-box fx-fd-c fx-ai-c' onClick={()=>{
                        navigate('/share');
                    }}>
                        <div className='menuItem menu-bg4 fx-box fx-ai-c fx-jc-c'>
                        {/* <i className='iconfont icon-shengriliwu'></i> */}
                        <img src="/static/images/mine-share.png"/>
                        </div>
                        <div className='theme-font font-size13 font-w5 text-center'>分享有礼</div>
                    </div>
                   
               </div>

               <div className='menu-list'>
                    {
                        userMenuList.map((item,idx)=>{
                              return (
                                    <div onClick={()=>{
                                          if(!item.path){
                                                return false
                                          }
                                          if(item.path=='/star-certificate'){
                                            setShowCertDialog(true);
                                            return;
                                          }
                                          navigate(item.path);

                                    }} className='menuListItem fx-box fx-jc-sb' key={'mine_'+idx}>
                                          <div className='listItemIcon fx-box fx-ai-c'>
                                                {/* <i className={item.icon}></i> */}
                                                <img src={item.image} className='mine-icon'/>
                                                <span className='font-size13'>{item.title}</span>
                                          </div>
                                          <div className='right-menu fx-box fx-ai-c'>
                                             {
                                                item.menuType==='icon'?<i  className='iconfont icon-weibiaoti--'></i>
                                                :
                                                renderSwitch(item)
                                             }
                                             
                                          </div>
                                    </div>
                              );
                        })
                    }
               </div>
               <Overlay visible={showHiddenDialog} style={{display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'}}>
                <div className='hidden-notice-dialog'>
                    <div className='hidden-title'>
                        隐身模式
                    </div>
                    <div className='hidden-desc'>
                    开启后，在公共区域你的信息将不会显示，主播将无法主动联系您
                    </div>
                    <div className='fx-box fx-jc-c fx-ai-c' onClick={
                        ()=>{
                            localStorage.setItem('hidden_noticed','true');
                            setShowHiddenDialog(false);
                        }
                    }>
                        <div className='hidden-confirm-btn'>
                            我知道了
                        </div>
                    </div>
                </div>
                </Overlay>

                <Popup visible={showCertDialog} style={{borderRadius:12,width:'75%',padding:20,backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div className='cert-dialog font-size13'>
                        <div className='font-size15 fx-box fx-jc-c fx-ai-c' style={{marginBottom:15}}>
                            温馨提示
                        </div>
                        <div style={{marginBottom:15,fontWeight:200}}>
                        女神认证请点击按钮去下载主播版Fu，如遇到问题，请联系客服
                        </div>
                        <div className="fx-box fx-fd-r fx-jc-sb" >
                            <div className='fx-1 fx-box fx-jc-c fx-ai-c' onClick={()=>{
                                setShowCertDialog(false);
                            }}>
                                <div className='btn-cancel'>考虑一下</div>
                            </div>
                            <div className='fx-1 fx-box fx-jc-c fx-ai-c'>
                                {/* <a href="wanttopays://fudw.douquwl88.top/static/ioswebversion.html"> */}
                                    <div className='btn-ok'
                                    onClick={()=>{
                                        window?.ReactNativeWebView?.postMessage('openURL:https://fudw.douquwl88.top/static/ioswebversion.html');
                                    }}
                                    >去下载</div>    
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
      );
}
export default Mine;