import React, { useEffect, useState } from 'react';
import './Msg.css';

import {connect} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as service from '../../service';
import ConversationList from '../../components/msg-list-item/msg-list-item';
import { Popup, Toast,Image } from 'react-vant';
import AppDB from '../../components/easemob/db';
const Msg=(props)=>{
    const navigate = useNavigate();
    const [messageCenter,setMessageCenter] = useState();
    const [showClearDialog,setShowClearDialog] = useState(false);
    useEffect(()=>{
        if(!messageCenter){
            service.queryMessageCenter().then(ret=>{
                // console.log('message center return:',ret);
                setMessageCenter(ret);
            })
        }
    })
    // console.log('======>Msg.js props.msgList',props.msgList);
      return (
      <div className='msg-page'>
                  <div className='msg-header theme-font fx-box fx-jc-sb fx-ai-c'>
                        <div className='font-size20'>消息</div>
                        <div className='fx-box fx-ai-c'>
                        <i className='iconfont icon-15qingkong-1' onClick={()=>{
                            setShowClearDialog(true);
                        }}></i>
                        <i className='iconfont icon-sousuo' onClick={()=>{
                            navigate('/search');
                        }}></i>
                        </div>
                  </div>
                  <div className='msg-menu fx-box'>
                        <div className='msgItem fx-box fx-1 fx-fd-c fx-ai-c' onClick={()=>{
                            navigate('/notice',{state:{list:messageCenter?.notification}})
                        }}>
                            <div className='msgMenuItem fx-box fx-ai-c fx-jc-c'>
                                {/* <i className='iconfont icon-gonggao'></i> */}
                                <img src={'/static/images/msg-article.png'}/>
                            </div>
                            <div className='theme-font font-size15 '>公告</div>
                            {messageCenter?.unReadNotificationNum>0?<div className='notice-no-read msg-no-read font-size13 font-color3'>{messageCenter?.unReadNotificationNum}</div>:null}
                        </div>

                        <div className='msgItem fx-box fx-1 fx-fd-c fx-ai-c' 
                         onClick={()=>{
                            navigate('/hint',{state:{list:messageCenter?.noticeInfo}})
                        }}
                        >
                            <div className='msgMenuItem fx-box fx-ai-c fx-jc-c'>
                                {/* <i className='iconfont icon-tongzhifill'></i> */}
                                <img src={'/static/images/msg-notice.png'}/>
                            </div>
                            <div className='theme-font font-size15 '>通知</div>
                            
                            {messageCenter?.unReadNotice>0?<div className='notice-no-read msg-no-read font-size13 font-color3'>{messageCenter?.unReadNotice}</div>:null}
                            
                        </div>

                        <div className='msgItem fx-box fx-1 fx-fd-c fx-ai-c'
                         onClick={()=>{
                            navigate('/record')
                        }}
                        >
                              <div className='msgMenuItem fx-box fx-ai-c fx-jc-c'>
                                {/* <i className='iconfont icon-shipin1'></i> */}
                                <img src={'/static/images/msg-record.png'}/>
                             </div>
                              <div className='theme-font font-size15 '>通话</div>
                              {messageCenter?.unReadConversation>0?<div className='notice-no-read msg-no-read font-size13 font-color3'>{messageCenter?.noReciveTelNum}</div>:null}
                        </div>
                        
                  </div>
                  <ConversationList conversations={props.conversations}/>
                  <div className='msg-kefu'>
                        <Link to = '/chat/socialfu_138002'><img src='/static/images/kefu-shortcut.png'></img></Link>
                  </div>
                  <Popup visible={showClearDialog} onClose={()=>{
                    setShowClearDialog(false);
                  }} style={{width:283,height:133,borderRadius:15}}>
                    <div className='fx-box fx-fd-c' style={{paddingTop:15}}>
                        <div className='fx-box fx-jc-c fx-ai-c font-size15'>
                        是否忽略所有未读提醒
                        </div>
                        <div className='fx-box fx-jc-c fx-ai-c font-size13' style={{paddingTop:8}}>
                        消息未读气泡会清除，不会删除聊天记录
                        </div>
                        <div className='fx-box ' style={{paddingTop:15}}>
                            <div className='fx-box fx-1 fx-jc-c fx-ai-c' onClick={()=>{
                                setShowClearDialog(false)
                            }}> 
                                <div className='msg-cancel-btn'>取消</div>
                            </div>
                            <div className='fx-box fx-1 fx-jc-c fx-ai-c' onClick={()=>{
                                AppDB.readAll().then(()=>{
                                    props.dispatch({type:'IM_READ_ALL'});
                                    setShowClearDialog(false);
                                    Toast({
                                        message: '完成',
                                        icon: <Image width={50} src="/static/images/toast-icon.png" />
                                    })
                                    
                                })
                            }}>
                                <div className='msg-ok-btn'>确定</div>
                            </div>
                        </div>
                    </div>
                  </Popup>
      </div>);
}
export default connect((state)=>{
    return {conversations:state.app.conversations}
},(dispatch)=>({dispatch}))(Msg);