import React, { useEffect, useState } from 'react';
import ModelMsgItem from '../../components/model-list-item/model-msg-item';
import Navigation from '../../components/navigation/navigation';
import './Search.css';
import * as service from '../../service';
import { useNavigate } from 'react-router-dom';
const Search=function(props){
    const navigate = useNavigate();
    const [modelList,setModelList] = useState([]);
    const [searchCriteria,setSearchCriteria] = useState('')
    return (
        <div className='full-r-w u-rate-page'>
             <Navigation height={'0.5rem'} style={{paddingTop:20}}>
                <div className='font-size13 fx-1 search-input' style={{paddingLeft:8}}>
                    <input type="text"  className='search-input-control' value={searchCriteria} placeholder='请输入要搜索的大V名字' width={20} onChange={(e)=>{
                        setSearchCriteria(e.target.value)
                        if(e.target.value)
                        {
                            service.search(e.target.value).then(data=>{
                                console.log('%c search result for'+e.target.value,'color:green');
                                console.log(data);
                                data.code==0&&setModelList(data.modelMapList);
                            })
                        }
                        else {
                            setModelList([]);
                        }
                    }}></input>
                    <img src='/static/images/clear.png' onClick={()=>{
                        setSearchCriteria('')
                    }}></img>
                </div>
             </Navigation>
             <div className='user-rates'>
             {modelList.length>0?<ModelMsgItem msgs={modelList} onClick = {
                    (item)=>{
                        navigate('/person/'+item.userId);
                    }
                }/>:
                <div className='fx-box fx-jc-c fx-ai-c fx-fd-c' style={{height:'70vh'}}>
                    <div className='font-size15' style={{color:'#ACACAC'}}>
                         暂时还没有什么内容
                    </div>
                </div>
                }
             </div>
        </div>
    );
}
export default Search;