import { useNavigate } from 'react-router-dom';
import {Image} from 'react-vant';
import { DefaultHeadUrl } from '../../service/images';
const RankingNav=(props)=>{
    const navigate=useNavigate();
    const createImageIcon=(props)=>{
        if(props.themeKey==='1'){
             return (<Image src='/static/images/money-love.png' width='0.11rem' height='0.09rem'/>)
        }
        if(props.themeKey==='2'){
            return (<img className='nav-gold-icon' src='/static/images/gold-icon.png'/>)
        }
        if(props.themeKey){
            return null;
        }
    }
    let n1={},n2={},n3={};
    if(props.last){
        const l = props.last.length;

        if(l){
            n1 = props.last[0];
            if(l>1) n2 = props.last[1];
            if(l>2) n3 = props.last[2];
        }
    }
    return (
        <div className='rank-nav-bg full-r-w layout-rel'>
        <Image width="100%" height="3.6rem" src={props.bgimg}/>
        <div className='full-r-w layout-abs rank-nav-cxt fx-box fx-fd-c fx-ai-c'>
            <div className='rank-nav-switch fx-box fx-ai-c'>
                 {
                    props.ranks.map((item,idx)=>{
                        return (
                            item.key===props.rankKey?
                            (<div key={'rank-nav-'+idx} onClick={()=>{
                                 props.setKey(item.key);
                            }} className='rank-nav-item fx-box fx-ai-c font-size13 fx-jc-c font-color2 rank-nav-item-active'>{item.title}</div>):
                            (<div key={'rank-nav-'+idx}  onClick={()=>{
                                  props.setKey(item.key);
                           }} className='rank-nav-item font-size13 font-color3 fx-box fx-ai-c fx-jc-c'>{item.title}</div>)
                        )
                    })
                 }
            </div>

            <div className='rank-nav-person fx-box' 
                
            >
               <div className='rank-person-2 fx-box fx-fd-c fx-ai-c fx-jc-s' onClick={()=>{
                    navigate('/person/'+n2.userId)
                   }}>
                   <div className='rank-person-item layout-rel'>
                        <div>
                          <Image width={'0.53rem'} height={'0.53rem'} round src={n2.headUrl||DefaultHeadUrl}/>
                        </div>
                        <div className='diadema-item layout-abs'>
                            <Image width={'0.27rem'} height={'0.2rem'} src='/static/images/diadema-02.png'/>
                        </div>
                   </div>
                   <div className='font-size13 font-w4 font-color3 mt8'>{n2.nickname}</div> 
                   {/* <div className='font-size13 font-w5 fx-box font-color3 fx-box fx-ai-c'>
                     {
                        createImageIcon(props)
                     }
                     <span>{n2.consume}</span>
                   </div>  */}
               </div>
               <div className='rank-person-1 fx-box fx-fd-c fx-ai-c fx-jc-c' onClick={()=>{
                navigate('/person/'+n1.userId)
               }}>
                   <div className='rank-person-item layout-rel'>
                        <div>
                          <Image width={'0.66rem'} height={'0.66rem'} round src={n1.headUrl||DefaultHeadUrl}/>
                        </div>
                        
                        <div className='diadema-item layout-abs'>
                            <Image width={'0.27rem'} height={'0.2rem'} src='/static/images/diadema-01.png'/>
                        </div>
                   </div>
                   <div className='font-size13 font-w4 font-color3 mt8'>{n1.nickname}</div> 
                   {/* <div className='font-size13 font-w5 fx-box font-color3 fx-box fx-ai-c'>
                     {
                        createImageIcon(props)
                     }
                     <span>{n1.consume}</span>
                   </div>  */}

               </div>
               <div className='rank-person-3 fx-box fx-fd-c fx-ai-c fx-jc-c'  onClick={()=>{
                navigate('/person/'+n3.userId)
               }}>
                   <div className='rank-person-item layout-rel'>
                        <div>
                          <Image width={'0.53rem'} height={'0.53rem'} round src={n3.headUrl||DefaultHeadUrl}/>
                        </div>
                        <div className='diadema-item layout-abs'>
                            <Image width={'0.27rem'} height={'0.2rem'} src='/static/images/diadema-03.png'/>
                        </div>
                   </div>
                   <div className='font-size13 font-w4 font-color3 mt8'>{n3.nickname}</div> 
                   {/* <div className='font-size13 font-w5 fx-box font-color3 fx-box fx-ai-c'>
                     {
                        createImageIcon(props)
                     } 
                     <span>{n3.consume}</span>
                    </div> */}
               </div> 
            </div>
        </div>
   </div>
    )
}



export default RankingNav;