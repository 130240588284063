import CryptoJS from 'crypto-js';

import {APP_VERSION_KEY} from './config';

export function encryptAES(data,key,iv){
    key  = CryptoJS.enc.Latin1.parse(key);
    iv   = CryptoJS.enc.Latin1.parse(iv);
    const result = CryptoJS.AES.encrypt(data,key,{iv});
    return result.toString();
}

const ENC = {
    '+': '-',
    '/': '_',
}

export const base64safe =(base64)=>{
    return base64.replace(/[+\/]/g, (m) => ENC[m])
}

const DEC = {
    '-': '+',
    '_': '/',
}

export const base64unsafe = (base64s)=>{
    return base64s.replace(/[-_]/g, (m) => DEC[m])
}

export const generateKeyIv = (seedstr)=>{
    const keysrc = ''+CryptoJS.MD5(seedstr);
    const key = keysrc.slice(0,16);
    const iv = keysrc.slice(-16);
    return {key,iv};
}

export const md5 = (key)=>{
    return CryptoJS.MD5(key).toString();
}

export const md5oldiv = (key)=>{
    return (''+CryptoJS.MD5(key)).slice(-16);
}

export const md5userkey = (accessToken)=>{
    const keysrc = ''+CryptoJS.MD5(accessToken);
    return keysrc.slice(0,24).toUpperCase();
}

export const sign = (VERSION,DEVICE_NO,TIME_STAMP)=>{
    const presignature=`${VERSION}_${DEVICE_NO}_${TIME_STAMP}_${TIME_STAMP}`;
    const sig2=encrypt3DES(presignature,APP_VERSION_KEY);
    
    return md5(sig2.toUpperCase()).toUpperCase();
}

export const userSign = (v,d,t,accessToken)=>{
    
    const key = md5userkey(accessToken);
    const presignature=`${v}_${d}_${t}_${t}`;
    const sig2=encrypt3DES(presignature,key);
    return md5(sig2.toUpperCase()).toUpperCase();
}

export function encrypt3DES(psw,edsKey) {
    const KeyHex = CryptoJS.enc.Utf8.parse(edsKey);
    const encrypted = CryptoJS.TripleDES.encrypt(psw,
        KeyHex,
        {
        mode: CryptoJS.mode.ECB,
        }
        );
    return encrypted.toString();
}
export function decryptAESPromise(data,key,iv ) { 
    return Promise.resolve().then(()=>{
        let payload = data;
        const decrypted = CryptoJS.AES.decrypt({
                ciphertext: CryptoJS.enc.Base64.parse(payload),
                salt: ""
            }, 
            CryptoJS.enc.Latin1.parse(key), 
            {
                iv: CryptoJS.enc.Latin1.parse(iv)
            });
        return CryptoJS.enc.Utf8.stringify(decrypted);
    }) 
}