import { Popup } from "react-vant"

import './deposit-dialog.css';
import { DepositIcon } from "../../service/images";
import { Link } from "react-router-dom";

export const DepositDialog = (props)=>{
    return (
        <Popup visible={props.visible} style={{borderRadius:12}}>
            <div className="deposit-dialog font-size13">
                <div className="fx-box fx-jc-c fx-ai-c">
                    <img className="deposit-dialog-icon" src={DepositIcon}></img>
                </div>
                <div className="fx-box fx-jc-c fx-ai-c">
                    您当前余额不足，请充值
                </div>
                <div className="deposit-dialog-footer">
                    <button className="close" onClick={()=>props.onClose()}>再看看</button>
                    <Link to='/pay' onClick={()=>{props.onClose()}}><button className="ok">去充值</button></Link>
                </div>
            </div>
        </Popup>
    )
}