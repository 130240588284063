import React, { useState,useEffect, useContext} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import GiftDialog from '../../components/gift-dialog/gift-dialog';
import './One2one.css';
import { checkLive,getLiveToken,CALLMODEL, getCallUserInfo,finishLive, liveGetTimes, checkVague } from '../../service';
import {rtm} from '../../components/agora/rtm';
import {AGORA_APP_ID} from '../../service/config';
import {DefaultHeadUrl} from '../../service/images';
import {giveGift,charge,queryMyselfInfo,spam} from '../../service';
import MsgDialog from "../../components/msg-dialog/msg-dialog";
import MsgInput from "../../components/msg-dialog/msg-input";

import AgoraRTC,{
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
    useClient,
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
  } from "../../components/agora/rtc.tsx";
import { PersonContext } from '../Person/PersonContext';
import { ChargeContext } from '../Person/ChargeContext';
import { Overlay, Toast } from 'react-vant';
import ChargeModal from '../Pay/PayModal';
import GiftTip from '../../components/gift-dialog/gift-tip';
import { DepositDialog } from '../../components/deposit-dialg';
import IntimacyDialog from '../../components/gift-dialog/intimacy-dialog';


// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();


//[PMTool sharedPMTool] pm:0 liveId:self.tokenModel.liveId 
// modelId:modelId opusId:0 
// type:5 success:^(id  _Nullable json) {
    
    
// var isChatting = false;
const statusStrings=['正在接入视频服务',
                    '已经接入视频服务，正在拨通对方',//1072
                    '已经拨通，等待响应', //1073
                    '对方拒绝接听，正在结束通话',
                    '对方接听，正在接入视频', //1074
                    '已挂断,正在断开视频服务',
                    '已挂断,正在断开视频服务'
                ];

const One2one=(props)=>{
    const reviewContext = useContext(PersonContext);
    const chargeContext = useContext(ChargeContext);
    const [history,setHistory]  = useState([]);    
    const [inCall,setInCall] = useState(true);
    // const [isFailed,setFailed ] = useState(false);
    const [needDeposit,setNeedDeposit] = useState(false);
    const navigate = useNavigate();
    const [callStatus,setCallStatus] = useState(0);

    const [conversationId, setConversationId ] = useState(null);
    const [channelName,setChannelName] = useState(null);
    const [liveToken,setLiveToken] = useState(null); 
    const [liveId,setLiveId] = useState(null);
    const [callHangup,setCallHangup]= useState(false);
    const [expTime,setExpTime] = useState(0);
    const [myInfo,setMyInfo] = useState(null);
    const [vague,setVague] = useState(true);
    const [renewToken,setRenewToken] = useState(null);


    // console.log('[DEBUG]conversationId:',conversationId);
    const profile = JSON.parse(localStorage.getItem('profile'));

    const location = useLocation();
    // console.warn('LOCATION:',location);
    // console.warn('call status:',callStatus,callHangup);
    // console.log('%c livetoken:','color:orange',liveToken);
    const targetId = location?.state?.invite?rtm.currentRemoteInvitation.callerId:location?.state?.person?.userId;

    AgoraRTC.getDevices(true);

    useEffect(()=>{
        let timer;
        if(!myInfo){
            queryMyselfInfo().then(data=>{
                // console.log('....myInfo:',data);
                if(data.code==0){
                    setMyInfo(data);
                }
            });
        }
        if(needDeposit){
            // console.log('需要充值');
            chargeContext.setPay(true);

            if(location?.state?.person?.userId)
            {
                console.log('[NAVIGATE]navigate to person line 94');
                navigate('/person/'+location?.state?.person?.userId,{replace:true,state:{from:'one2one'}});
            }
                
            else 
            {
                console.log('[NAVIGATE]navigate from one2one -1');
                navigate(-1);
            }
                

            return;
        }
       
        if(callStatus==3){
            clearTimeout(timer); 
            timer= setTimeout(()=>{
                console.log('[NAVIGATE]callStatus==3 navigate back -1 line 115');
                navigate(-1);
            },500)
        }
        else if(callStatus>=5&&!liveToken){
            clearTimeout(timer);
            const modelId = location?.state?.invite?location?.state?.caller?.userId:location?.state?.person?.userId;

            liveId&&finishLive(liveId,channelName,modelId,profile.userId).then(data=>{
                console.log('%c finish live got:%o','color:purple',data);
                data.liveId=liveId;
                reviewContext.setLive(data);
            })

            timer = setTimeout(()=>{
                console.log('callStatus>5 navigate back -1');
                navigate(-1);
            },1000)
        }
        else if(callStatus>=5&&callHangup==true){
            console.log('%c[GIFTTIMEDEBUG] callStatus>5 2 navigate back:%o','color:blue',new Date());

            console.log('%c callStatus==5, callHangup==true, try to end call','color:orange');

            const modelId = location?.state?.invite?location?.state?.caller?.userId:location?.state?.person?.userId;

            liveId&&finishLive(liveId,channelName,modelId,profile.userId).then(data=>{
                console.log('%c finish live got:%o','color:purple',data);
                data.liveId=liveId;
                reviewContext.setLive(data);
            })
            reviewContext.setReview(true);
            clearTimeout(timer);
            timer = setTimeout(()=>{
                console.log('[NAVIGATE]navigate to person');
                // navigate(-1);
                if(renewToken)
                    navigate('/person/'+modelId,{replace:true,state:{from:'one2one'}});
                else 
                    navigate('/person/'+modelId,{replace:true});
                    
            },100)
        }
        return ()=>{
            clearInterval(rtm.chargeTimer);        
            clearTimeout(timer);
        }
    },[callStatus,navigate,callHangup,rtm,needDeposit]);

    rtm.removeAllListeners('MessageFromPeer');
    rtm.on('MessageFromPeer',(args)=>{
        console.log('\n\n***[listener]MessageFromPeer:',args);
        if(args.messageType=='TEXT'){
            try{
                const obj = JSON.parse(args.text);
                if(obj.code==5){
                    
                    if(callStatus!=3){
                        setCallStatus(5);
                    }
                    // else 
                    //     setLiveToken(null);
                }
                else if (obj.code==10){
                    history.push(obj);
                    setHistory(history.slice())
                }
                else if(obj.code==4){
                    const msgData = obj.message;
                    
                    //liveToken=msgData.liveToken;
                    setRenewToken(msgData.liveToken);
                    
                    rtm.expTime=msgData.expTime
                    
                    setExpTime(rtm.expTime);

                }
            }catch(err){
                console.log('todo: error');
                console.error(err);
            }
        }
    })

    useEffect(()=>{
        (async ()=>{
            if(!location?.state?.invite){
                // console.log('isChecking:',isChatting);
                const userId = JSON.parse(localStorage.getItem('profile')).userId;
                const modelId = location?.state?.person?.userId;
                // console.log('%c current conversationId:%o,%o','color:green ',conversationId,isChatting);
                
                if(!conversationId){
                    // isChatting=true;
                    // const data = await checkLive(modelId+'',userId+'');
                    // console.log('[DEBUG]check live return:',data);
                    // isChatting=false;
                    // if(data.code=='707'||data.code=='709'){
                    //     setNeedDeposit(true);
                    //     chargeContext.setPay(true);
                    // }
                    // else if(!data.conversationId){
                    //     setFailed(true);
                    // }
                    const data = location?.state?.liveData;
                    if(data)
                    {
                        setConversationId(data.conversationId);
                        //setChannelName(`${profile.userId}-${location?.state?.person?.userId}-${new Date().valueOf()}`);
                        data.channelName?setChannelName(data.channelName):setChannelName(`${profile.userId}-${location?.state?.person?.userId}-${new Date().valueOf()}`);
                        setCallStatus(1);
                    }
                }
                console.log('%c current conversationId:%o','color:green ',conversationId);
                if(callStatus<2&&conversationId){
                    const param = {
                        userId:profile.userId,
                        modelId:''+location?.state?.person?.userId,
                        conversationId:conversationId,
                        channelName
                    }
                    console.log('%c current param:','color:green ',param);
                    rtm.sendLocalInvitation(param,{
                        refused:()=>{
                            console.log('%c invitation refused setCallStatus 3','color:red');
                            setCallStatus(3);
                        },
                        accepted:()=>{
                            setCallStatus(4);
                        },
                        received:()=>{
                            setCallStatus(2);
                        },
                        fail:(e)=>{
                            console.log('%c invitation failed setCallStatus 3','color:red',e);
                            setCallStatus(3);
                        }
                    })
                    
                    setCallStatus(2);
                }
                if(callStatus==4){
                    //send default mohu
                    const p1 = JSON.stringify({
                        code:3+'',
                        visiable:vague?'1':'0'   //跟当前vague是反的。
                    })
                    rtm.sendPeerMessage(p1,targetId)

                    console.log('%c VAGUE DEFAULT SET TO DAKAIMOHU:%o','color:green',p1)
                    //getLiveToken
                    console.log('========callout===========to connect video ');
                    console.log('channelName:',channelName);
                    console.log('conversationId:',conversationId);
                    console.log('userId:',profile.userId);
                    console.log('modelId:',location?.state?.person?.userId);
                    const tokenData = await getLiveToken(channelName,location?.state?.person?.userId+'',profile.userId+'',conversationId)
                    console.log('getLiveToken:',tokenData);
                    if(tokenData.code==0)
                    {
                        clearInterval(rtm.callExpTimer);
                        rtm.callExpTimer=null;
                        setLiveToken(tokenData.liveToken);
                        setLiveId(tokenData.liveId);
                        setExpTime(tokenData.expTime);
                        // if(tokenData.expTime<=120)startCountDownTimer(tokenData.expTime);
                        startCountDownTimer(tokenData.expTime);
                    }
                }
            }
            else {
                console.log('%c receive call:%o','color:green',callStatus);
                console.log('%c receive caller:%o','color:green',location.state.caller);
                if(callStatus==4&&!liveToken){
                    //getLiveToken
                    console.log('========receive call===========to connect video ');
                    console.log('channelName:',rtm.currentRemoteInvitation.content);
                    console.log('conversationId:',conversationId);
                    console.log('userId:',profile.userId);
                    console.log('modelId:',rtm.currentRemoteInvitation.callerId);
                    

                    getCallUserInfo(location?.state?.caller?.userId).then(async (cs)=>{
                        console.log('%c getCallStatus:%o','color:green',cs);

                        const tokenData = await getLiveToken(rtm.currentRemoteInvitation.content,location?.state?.caller.userId+'',profile.userId+'')
                        console.log('getLiveToken:',tokenData);
                        if(tokenData.code==0)
                        {
                            setLiveToken(tokenData.liveToken);
                            setLiveId(tokenData.liveId);
                            setExpTime(tokenData.expTime);
                            setChannelName(rtm.currentRemoteInvitation.content);
                            // if((tokenData.expTime*1)<=120)startCountDownTimer(tokenData.expTime);
                            startCountDownTimer(tokenData.expTime);
                        }
                    })
                    
                    
                }
            }
        })();
    },[conversationId,location,setConversationId,setCallStatus,channelName,callStatus,setLiveToken,vague])

    const onUserJoin = (user)=>{
        const model = Object.assign({},CALLMODEL,{code:6})
        rtm.sendPeerMessage(JSON.stringify(model),location?.state?.invite?rtm.currentRemoteInvitation.callerId:location?.state?.person?.userId);
        const modelId = location?.state?.invite?location?.state?.caller?.userId:location?.state?.person?.userId;
        startChargeTimer(liveId,modelId,profile.userId,channelName);
    }

    const cancelCall =async ()=>{
        // console.log('cancel call');
        setCallStatus(6)
        setCallHangup(true);
        try{
            if(location?.state?.invite)
                await rtm.currentRemoteInvitation.refuse();
            else
                await rtm.cancelLocalInvitation();
        }
        catch(err){
            console.error(err);
        }
    }

    const onCallTimeout = async (status)=>{
        console.log('%c onCallTimeout:','color:green',status);
        const modelId = location.state.invite?location.state.caller.userId:location.state.person.userId;
        const callUserId = location.state.invite?location.state.caller.userId:profile.userId;

        switch(status*1){
            case 1:
                console.log('%c onCallTimeout report:','color:green',1072);
                spam({operate:1072,type:2,liveId,modelId,callUserId,reCode:0,channelName:'声网在线'})
                break;
            case 2:
                console.log('%c onCallTimeout report:','color:green',1073);
                spam({operate:1073,type:2,liveId,modelId,callUserId,reCode:0,channelName:'模特没接听'})
                break;
            case 4:
                console.log('%c onCallTimeout report:','color:green',1074);
                spam({operate:1074,type:2,liveId,modelId,callUserId,reCode:0,channelName:'用户在接通状态'})
                break;
            default:
                console.log('%c [DEBUG]call status:%o','color:green',status);
        }


        setCallStatus(6)
        setCallHangup(true);
        try{
            if(location?.state?.invite)
                await rtm.currentRemoteInvitation.refuse();
            else
                await rtm.cancelLocalInvitation();
        }
        catch(err){
            console.error(err);
        }
    }


    const startCountDownTimer = (expTime)=>{
        console.log('%c [DEBUG]start count down timer','color:blue');
        //if(rtm.countDownTimer){
        clearInterval(rtm.countDownTimer);
        rtm.countDownTimer=null;
        //}
        rtm.expTime=expTime;
        rtm.countDownTimer = setInterval(()=>{
            // console.log('%c current expTime:%s','color:blue',rtm.expTime+'');
            setExpTime(rtm.expTime--);
            if(rtm.expTime<=0){
                console.log('\n=====>expTime run out.',rtm.expTime);
                clearInterval(rtm.countDownTimer);
                rtm.expTime=null;
            }
        },1000);
    }

    const startChargeTimer = (liveId,modelId,viewerId,channelName)=>{
        if(rtm.chargeTimer){
            clearInterval(rtm.chargeTimer);
            rtm.chargeTimer=null;
        }
        rtm.chargeTimer  = setInterval(()=>{

            charge({liveId:liveId+'',modelId:modelId+'',opusId:'0',type:'5'}).then(()=>{
                
                if((rtm.expTime%60)==0)rtm.expTime--;
                
                if(rtm.expTime>100)return {
                    expTime:rtm.expTime
                }

                setTimeout(()=>{
                    liveGetTimes(modelId,viewerId,channelName,'',rtm.expTime).then(data=>{
                        console.log('%c [DEBUG]liveGetTime:%o %o','color:orange', rtm.expTime, data);
                        setExpTime(data.expTime);
                        rtm.expTime = data.expTime * 1;
                    });
                },20000);
            })
        },1000*60)
    }

    
    if(!liveToken){
        return <Calling invite={location?.state?.invite} 
            model ={location?.state?.invite?(location?.state?.caller):(location?.state?.person)} 
            status={statusStrings[callStatus]}
            callStatus={callStatus}
            cancelCall={cancelCall} 
            onTimeout = {onCallTimeout}
            acceptCall={async ()=>{
                await rtm.currentRemoteInvitation&&rtm.currentRemoteInvitation.accept();
                setCallStatus(4);
                clearInterval(rtm.callExpTimer);
                rtm.callExpTimer=null;
            }}
            vague={vague}
            setVague = {setVague}
            autoPlay={callStatus<3}/>
    }
    else{
        //return <Chatting {...props}/>
        return (
            <VideoCall 
            updateTime = {()=>{
                const modelId = location?.state?.invite?location?.state?.caller?.userId:location?.state?.person?.userId;
                const viewerId = profile.userId;
                
                console.log('%c[GIFTTIMEDEBUG] update time %o %o %o %o ','color:blue',modelId,viewerId,channelName,expTime);
                liveGetTimes(modelId,viewerId,channelName,'',expTime==60?59:expTime).then(data=>{
                    console.log('%c[GIFTTIMEDEBUG] liveGetTime:%o %o','color:orange',data,new Date());
                    setExpTime(data.expTime);
                    rtm.expTime=data.expTime*1;
                    // if(data.expTime<=120){
                    //     // console.log('%c start count down timer:%s','color:blue',expTime+'',data.expTime);
                    //     startCountDownTimer(data.expTime);
                    // }
                });
            }}
            setInCall={setInCall} 
            onUserJoin = {onUserJoin}
            setCallHangup = {()=>{
                clearInterval(rtm.countDownTimer);
                rtm.countDownTimer=null;
                clearInterval(rtm.chargeTimer);
                rtm.chargeTimer=null;

                setCallHangup(true)
            }}
            setCallStatus = {setCallStatus}
            channelName={channelName} 
            token={liveToken}
            renewToken = {renewToken}
            model = {location?.state?.invite?location?.state?.caller:location?.state?.person}
            history = {history}
            onClear = {()=>{
                setHistory([]);
            }}
            expTime = {expTime}
            onSendMessage = {(obj)=>{
                history.push(obj);
                setHistory(history.slice());
            }}
            liveId={liveId}
            vague ={vague}
            setVague = {setVague}
            balance={myInfo?.userInfo?.wealthXz}
            uid={profile?.userId}/>
        )
    }
}

const Calling = (props)=>{
    const {model} = props;
    
    const navigate = useNavigate();
    const [timer, setTimer] = useState(null);


    useEffect(()=>{
        if(timer===null){
            clearInterval(rtm.callExpTimer);

            rtm.callExpTime = 20;
            rtm.callExpTimer = setInterval(()=>{
                console.log('[TIMER]start call timer',rtm.callExpTime);
                setTimer(rtm.callExpTime--);
                if(rtm.callExpTime<0){

                    clearInterval(rtm.callExpTimer);
                    rtm.callExpTimer=null;
                    console.log('%c props.callStatus:%o','color:green',props.callStatus)
                    props.onTimeout(props.callStatus);
                    // props.cancelCall();
                }
            },1000)
        }
    },[timer,setTimer,props.callStatus,props.onTimeout]);
    return (
        <div className='one2one'>
        
        <video className='main-video' poster={model?.headUrl} style={{objectFit:'cover'}}/>
        
        {/* <video className='other-video' poster='/static/images/live-02.png'></video> */}
        <div className='calling-video' >
            <div className='calling-home fx-box fx-ai-c fx-jc-c'>
                <div className='calling-home-avatar fx-box fx-jc-c fx-ai-c'>
                    <img src={model.headUrl}/>
                </div>
            </div>
            <div className='calling-name fx-box fx-jc-c fx-ai-c font-size13 font-color3'>
                    {model.Name}
            </div>

            <div className='calling-cost fx-box fx-ai-c fx-jc-c'>
                <div className='uptext theme-font font-size11 font-color3'>每分钟支出{model.oneToOneVedioPrice}金币</div>
            </div>

            <div className='calling-status fx-box fx-ai-c fx-jc-c'>
                <div className='uptext theme-font font-size11 font-color3'>{props.status} {timer===null?20:timer}秒</div>
            </div>
            
            <div className='main-menu-pause fx-box fx-fd-r fx-ai-c fx-jc-c theme-font'>
                {/* <div className='menu-pause-state font-size11 font-color3'>剩余01:30</div> */}
               
                <div className='pause-btn fx-box fx-jc-c fx-ai-c' onClick={()=>{
                    props.cancelCall();
                }}>
                    {/* <i className='iconfont icon-guaduan'></i> */}
                    <img src='/static/images/hangup.png' className='calling-hangup'/>
                </div>
                {props.invite?(
                    <div className='accept-btn fx-box fx-jc-c fx-ai-c' onClick={()=>{
                        props?.acceptCall&&(props.acceptCall());
                    }}>
                        {/* <img src='/static/images/hangup.png' className='calling-hangup'/> */}
                        {/* <i className='iconfont icon-guaduan'></i> */}
                        <img src='/static/images/call-accept.png' className='calling-hangup'/>
                    </div>
                ):null}
                
            </div>

            <div className='calling-menu-dim fx-box fx-ai-c fx-jc-c theme-font' onClick={()=>{
                checkVague(model.userId).then(data=>{
                    console.log(data);
                    
                    const p2 = JSON.stringify({
                        code:3+'',
                        visiable:props.vague?'0':'1'   //跟当前vague是反的。
                    });
                    rtm.sendPeerMessage(p2,props.model.userId)

                    props.setVague(!props.vague);
                    console.log('%c VAGUE SET TO DAKAIMOHU:%o','color:green',p2,!props.vague)
                })
            }}>
                <i className='iconfont icon-mohumopi'></i>
                <span className='font-size15 font-color3'>{props.vague?'关闭模糊':'打开模糊'}</span>
            </div>

        </div>
        
        <audio id='ring' src='/static/sounds/ring.mp3' autoPlay={props.autoPlay} loop={true}/>
    </div>
    )
}





const VideoCall = (props) => {
    const [showInput,setShowInput]= useState(false);
    const [showgift,setshowgift]=useState(false);
    const {setInCall, channelName, onUserJoin } = props;
    const [users, setUsers] = useState([]);
    const [start, setStart] = useState(false);
    const [currentCamera,setCurrentCamera]= useState(0);
    const [showSwitchCamera,setShowSwitchCamera] = useState(true);
    const [cameras,setCameras] = useState(null);
    const [showAll,setShowAll] = useState(true);
    const [showTip,setShowTip] = useState(false);
    const [tipAction,setTipAction] = useState(null);
    const [tip,setTip]  = useState({})
    const [showIndicator] = useState(props?.model?.wechatShowFlag!=0); 
    
    const [showIntimacyDialog,setShowIntimacyDialog] = useState(false);
    // const [showHistory,setShowHistory] = useState(props.history&&props.history.length);
    // console.log('----------------------------------------------------------');
    // console.log('one2one1:',props.model);
    // console.log('%c history:','color:green',props.history.length);
    

    // using the hook to get access to the client object
    const client = useClient();
    // ready is a state variable, which returns true 
    //when the local tracks are initialized, untill then tracks variable is null
    const { ready, tracks } = useMicrophoneAndCameraTracks();
    
    const profile = JSON.parse(localStorage.getItem('profile'));

    useEffect(() => {
        window.fucircle.onUserTakeScreenShotCallback = async (ret)=>{
            console.log('=====>USER DID TAKE SCREEN SHOT:',ret);
            try{
                rtm.sendPeerMessage(JSON.stringify({
                    code:8+'',
                    type:'1'
                }),props.model.userId)
            }catch(err){
                console.log(err);
            }            
        }

        window.fucircle.onUserChangeCapture=(ret)=>{
            console.log('=====>USER DID CHANGE CAPTURE:',ret);
            try{
                rtm.sendPeerMessage(JSON.stringify({
                    code:8+'',
                    type:'2'
                }),props.model.userId)
            }catch(err){
                console.log(err);
            }
        }

        // if(props.history&&props.history.length&&!showHistory){
        //     setShowHistory(true);
        // }
        
        // function to initialise the SDK
        let init = async (name) => {
            client.removeAllListeners();

            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);
                console.log("subscribe success:",user,mediaType);
                if (mediaType === "video") {
                    onUserJoin(user);
                    setUsers((prevUsers) => {
                        // return [...prevUsers, user];
                        return [user];
                    });
                }
                if (mediaType === "audio") {
                user.audioTrack?.play();
                }
            });

            client.on("connection-state-change",async (s)=>{
                console.log('%c connection-state-changed:%o','color:purple',s);
                if(s=='DISCONNECTED'){
                    await client.leave();
                    props.setCallHangup(true);
                    props.setCallStatus(6);
                }
            })

        

            client.on("user-unpublished", async (user, type) => {
                
                console.log("%c user-unpublished: %o %o",'color:orange', user,type);
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
                if (type === "video") {
                    setUsers((prevUsers) => {
                        return prevUsers.filter((User) => User.uid !== user.uid);
                    });
                }
                if(tracks){
                    if(tracks.length>0)tracks[0].close();
                    if(tracks.length>1)tracks[1].close();
                }
                // await client.leave();
                // props.setCallHangup(true);
                
            });

            client.on("user-left", async (user) => {
                console.log("%c user-left: %o",'color:orange', user);
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
                //all client leave.
                if(tracks){
                    if(tracks.length>0)tracks[0].close();
                    if(tracks.length>1)tracks[1].close();
                }
                await client.leave();
                props.setCallHangup(true);
                props.setCallStatus(6);
                //props.dispatch({type:'hangup'});

            });

            await client.join(AGORA_APP_ID, name, props.token, props.uid);
            if (tracks) await client.publish([tracks[0], tracks[1]]);
            setStart(true);

        };

        if (ready && tracks) {
            console.log("init ready");
            init(channelName);
        }

        !cameras&&AgoraRTC.getCameras(true).then(data=>{
            console.log('cameras:',data)
            if(data&&data.length>1){
                setShowSwitchCamera(true);
                // setCameras(data);
            }
            setCameras(data);
            // else setShowSwitchCamera(false);
        })

   
    return async ()=>{
        // we close the tracks to perform cleanup
        // try{
        //     await client.leave();
        //     client.removeAllListeners();
        //     tracks[0].close();
        //     tracks[1].close();
        // }
        // catch(err){
        //     console.error(err);
        // }
        
    }
  }, [channelName, client, ready, tracks, setShowSwitchCamera]);

  useEffect(()=>{
    if(props.renewToken){
        console.log('===>renew token');
        client.renewToken(props.renewToken);
    }
  },[props.renewToken])

  const [rtmMessage,setRtmMessage] = useState('');
  const [balance,setBalance] = useState(props.balance||0);
  const [showChargeModal,setShowChargeModal] = useState(false);
  const [showDeposit,setShowDeposit] = useState(false);

  let tipTimer=null;
  
  return (
    <div className='one2one'>
    {/* <video className='main-video' poster='/static/images/live-03.png'/> */}
    {/* <video className='other-video' poster='/static/images/live-02.png'></video> */}
    {start && tracks && <Videos vague={props.vague} users={users} tracks={tracks} />}

    <div className='main-home fx-box fx-ai-c fx-jc-sb'>
       <div className='main-home-avatar fx-box'>
          <img src={props?.model?.headUrl??DefaultHeadUrl}/>
       </div>
       <div className='one2one-user-info theme-font fx-box fx-fd-c fx-jc-c fx-ai-s'>
           <div className='font-size12 font-color3 one2one-nickname'>{props?.model?.nickName}</div>
           <div className='one2one-user-btn text-center font-size11 font-color3'>lv{props?.model?.playerLeven}</div>
       </div>
    </div>

    {/* <div className='main-coast theme-font font-size11 font-color3'>消费 23000</div> */}

    <div className='main-menu fx-box theme-font'>
       <div className='one2one-menuItem fx-box fx-ai-c fx-jc-c'>
           <i className='iconfont icon-more'></i>
       </div>
       <div className='one2one-menuItem font-size10 font-color3 text-center ' onClick={()=>{
        setShowAll(!showAll);
       }}>清屏</div>
    </div>
    {
        showAll?
        (
            <>
             <div className='main-menu-pause fx-box fx-fd-c fx-ai-c fx-jc-c theme-font'>
                {props.expTime<=120?(
                    <div className='menu-pause-state font-size11 font-color3 fx-box fx-jc-sb'>
                        <span>剩余{props.expTime}秒</span>
                        <div className='tocharge' onClick={()=>{
                            setShowChargeModal(true);
                        }}>去充值</div>
                    </div>):null}
                <div className='pause-btn fx-box fx-jc-c fx-ai-c' onClick={async ()=>{
                    props.setCallStatus(6);
                    
                    if(tracks){
                        if(tracks.length>0)tracks[0].close();
                        if(tracks.length>1)tracks[1].close();
                    }
                    await client.leave();
                    props.setCallHangup(true);

                }}>
                <img src='/static/images/hangup.png' className='calling-hangup'/>
                </div>
            </div>
            <div className='main-menu-bar'>
                {
                    showSwitchCamera?
                        <div className='theme-font fx-box fx-fd-c fx-ai-c' onClick={()=>{
                           
                            let newIdx;
                            for(newIdx=0;newIdx<cameras.length;newIdx++){
                                if(cameras[newIdx].label!=cameras[currentCamera*1].label)
                                {
                                    console.log('\n[CAMERA]found camera idx:',newIdx,cameras[newIdx]);
                                    setCurrentCamera(newIdx);
                                    tracks[1].setDevice(cameras[newIdx].deviceId);
                                    break;
                                }
                            }
                            
                        }}>
                        <div className='main-menu-barItem fx-box fx-jc-c fx-ai-c'> 
                            <i className='iconfont icon-2'></i>
                        </div>
                        <div className='font-size11 font-color3' >翻转镜头</div>
                        </div>:null
                    
                }

                {/* <div className='theme-font fx-box fx-fd-c fx-ai-c'>
                <div className='main-menu-barItem fx-box fx-jc-c fx-ai-c'> 
                    <i className='iconfont icon-meiyanxianxing'></i>
                </div>
                <div className='font-size11 font-color3'>美颜</div>
            </div> */}

                <div className='theme-font fx-box fx-fd-c fx-ai-c'  >
                    <div className='main-menu-barItem fx-box fx-jc-c fx-ai-c' onClick = {()=>{
                            console.log('try to show input');
                            setShowInput(!showInput);
                        }} > 
                        <i className='iconfont icon-jianpan' onClick = {()=>{
                            console.log('try to show input');
                            setShowInput(!showInput);
                        }}></i>
                    </div>
                    <div className='font-size11 font-color3' onClick = {()=>{
                            console.log('try to show input');
                            setShowInput(true);
                        }}>聊天
                    </div>
                </div>

            </div>
            <div className='main-menu-dim fx-box fx-ai-c fx-jc-c theme-font' onClick={()=>{
                // setVague(props.model.userId).then(data=>{
                //     if(data!=0)Toast.info(data.errMsg);
                // })
                checkVague(props.model.userId).then(data=>{
                    // console.log(data);
                    props.setVague(!props.vague);
                    rtm.sendPeerMessage(JSON.stringify({
                        code:3+'',
                        visiable:props.vague?'0':'1'   //跟当前vague是反的。
                    }),props.model.userId)
                })
            }}>
            <i className='iconfont icon-mohumopi'></i>
            <span className='font-size15 font-color3'>{props.vague?'关闭模糊':'打开模糊'}</span>
            </div>
            {showIndicator?

            <div onClick={()=>{
                setShowIntimacyDialog(true);
            }} className='one2one-intimacy'>
                    <div className='one2oneItem fx-box fx-fd-c  fx-jc-c fx-ai-c'> 
                        {/* <i className='iconfont icon-liwu1'></i> */}
                        <img src='/static/images/intimacy.png' className='calling-gift'></img>
                    </div>
            </div>

            :null
            }
            <div onClick={()=>{
                setshowgift(true);
            }} className='one2one-gift'>
                    <div className='one2oneItem fx-box fx-fd-c  fx-jc-c fx-ai-c'> 
                        {/* <i className='iconfont icon-liwu1'></i> */}
                        <img src='/static/images/o2ogift.png' className='calling-gift'></img>
                    </div>
            </div>

            {
                showgift?<GiftDialog 
                balance={balance||props.balance}
                onGiveGift={(giftId,nmbs,giftName)=>{
                    console.log('onSend...');
                    giveGift(props.model.userId,giftId,nmbs,props.liveId).then(ret=>{
                        console.log('give gift:',ret);
                        if(ret.code==0){
                            setBalance(ret.userBalance);

                            clearTimeout(tipTimer);
                                
                            setTip({
                                name:giftName,
                                count:(nmbs||0)*1+(tip.count||0)*1
                            });
                            
                            setShowTip(true);
                            setTipAction('open');
                            
                            tipTimer=setTimeout(()=>{
                                setTipAction('close');
                            },3500)
                            //
                            rtm.sendPeerMessage(JSON.stringify({
                                code:4+'',
                            }),props.model.userId);
                            
                            
                            //更新剩余聊天时间
                            props?.updateTime&&props?.updateTime();
                            //send rtm message
                            /*
                                    messageModel.code = 2;
                            messageModel.avatar = model.headUrl;
                            messageModel.giftId = (int) button.tag;
                            messageModel.nmbs = nmbs;
                            
                            //发送消息
                            AgoraRtmMessage *rtmKit = [[AgoraRtmMessage alloc] initWithText:[[NSDictJsonTool nSDictJsonTool] modelToJson:messageModel]];
                            AppDelegate *appDelegate = (AppDelegate*)[UIApplication sharedApplication].delegate;
                            [appDelegate.signalEngine sendMessage:rtmKit toPeer:self.remoteAccount completion:nil];
                                */
                                rtm.sendPeerMessage(JSON.stringify({
                                    code:2+'',
                                    avatar:profile.headUrl,
                                    giftId:giftId+'',
                                    nmbs:nmbs+''
                                }),props.model.userId);
                                
                        }
                        else if(ret.code==709||ret.code==701){
                            setShowDeposit(true);
                        }

                    }).catch(err=>{
                        console.error(err);
                    });
                }} 
                onCharge = {()=>{
                        setShowChargeModal(true);
                }}
                onClose={()=>{
                    setshowgift(false);
                }}/>:<div></div>
            }
            {
                showIntimacyDialog?<IntimacyDialog 
                intimacy={props?.model?.userIntimacy}
                intimacyLine={props?.model?.intimacy}
                modelHead = {props?.model?.headUrl}
                myHead={profile.headUrl||DefaultHeadUrl}
                wx = {props?.model?.wechat}
                onClose={()=>{
                    setShowIntimacyDialog(false)
                }}></IntimacyDialog>:null
            }
            </>
        ):null
    }
    {showTip?<GiftTip
                openTip={tipAction}
                tip={tip}
    />:null}
   
    <MsgDialog  
        show={props.history&&props.history.length>0}
        onClose={()=>{
            props.onClear&&props.onClear();
            
            }
        }
        messages={props.history}
    />

    <MsgInput
        show={showInput}
        onSend={(e)=>{
            let messageObj = {"avatar":null,"giftId":0,"visiable":0,"killId":0,"code":10,"message":e.title,"nmbs":0,"type":0};
            rtm.sendPeerMessage(JSON.stringify(messageObj),props.model.userId);
            props.onSendMessage(messageObj)
            // setShowHistory(true);
    }}
    />
    <Overlay zIndex={1002} visible={showChargeModal} onClick={() => setShowChargeModal(false)}>
        <ChargeModal  onClose = {()=>{
            console.log('im called.');
            setShowChargeModal(false)
        }}/>
    </Overlay>
    
    <DepositDialog onClose={()=>{setShowDeposit(false)}} visible = {showDeposit}/>
</div>
      
   
  );
};

// const Controls = (props)=>{
//     const {audioTrack,tracks} = props;
//     console.log('audioTrack:',audioTrack);
//     if(tracks&&tracks.map)tracks.map((track)=>{
//         console.log('%c [DEBUG]track:%o','color:blue',track.trackMediaType);
//     });
//     return(
//         <div style={{position:'absolute',top:100,fontSize:36}} > 
//             <button onClick={()=>{
//                 audioTrack?.setVolume(audioTrack._volume+50);
//                 console.log(audioTrack._volume);
//             }}>UP</button>
//             <button onClick={()=>{
//                 if(audioTrack?._volume<=0)return;
//                 audioTrack?.setVolume(audioTrack._volume-50);
//                 console.log(audioTrack._volume);
//             }}>DOWN</button>
//             <button>[{audioTrack?._volume??0}]</button>
            
//         </div>
//     )
// }

var playbackDeviceIndex=0;

const Videos = (props) => {
  const { users, tracks } = props;

  
//   console.log('%c is vague? %o','color:blue',props.vague);
  return (
    <div className="videos">
      <div id="videos" className="videos-1">
        {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}
       
        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
                if(user.audioTrack){
                    // user.audioTrack.setVolume(200);
                    //user.audioTrack.setPlaybackDevice()
                }
              return (
                <AgoraVideoPlayer className='vid-remote' videoTrack={user.videoTrack} key={user.uid} />
              );
            } else return null;
        })}
        <AgoraVideoPlayer className='vid-local' videoTrack={tracks[1]} onClick={()=>{
            console.log('=====>video player clicked');
        }}/>
        {props.vague?<div className='vid-local' style={{backgroundColor:'rgba(99, 99, 99, 0.98)'}} 
        onClick={()=>{
            AgoraRTC.getPlaybackDevices().then(data=>{
                console.log(data);
                if(playbackDeviceIndex>=data.length)playbackDeviceIndex=0;
                console.log(data[playbackDeviceIndex].deviceId);
                users[0].audioTrack.setPlaybackDevice(data[playbackDeviceIndex].deviceId);
                playbackDeviceIndex++;
            })
        }}
        ></div>:null}
        {/* <Controls tracks={tracks} audioTrack={users[0]?.audioTrack}/>    */}
      </div>
      
    </div>
  );
};


export default One2one;