
export const DefaultHeadUrl = '/static/images/head.png';
export const FU_LIKE_IMG = '/static/images/icon/like_active.png';
export const FU_UNLIKE_IMG = '/static/images/icon/like_inactive.png';
export const SEARCH_ICON ='/static/images/search.png';
export const DepositIcon = '/static/images/deposit-icon.png';
export const DefaultCircleBack = '/static/images/circle-default-back.png';

export const HEART_ALL_WHITE = '/static/images/heart-all-white.png';
export const HEART_BREAK_WHITE = '/static/images/heart-break-white.png';
export const HEART_ALL_GRAY = '/static/images/heart-all-gray.png';
export const HEART_BREAK_GRAY = '/static/images/heart-break-gray.png';