import moment from "moment";

const colorMap={
    '好漂亮':'#FD6995',
    '声音好听':'#FB88C2',
    '温柔':'#FF7EE8',
    '值得聊':'#BB88F9',
    '身材好':'#B2A5FF',
    '有女人味':'#9EBCFF',
    '多才多艺':'#42BBFA',
    '会聊天':'#68DAE0',
    '有亲和力':'#76DAB4',
    '很专业':'#64ED90',
    '真懂我':'#64DB61',
    '有感觉':'#ADD481',
    '就是喜欢':'#EFD647',
    '好可爱':'#FFBD7E',
    '很听话':'#EFA56E',
    '理想女友':'#FD9090',
}
export const getColorByTag =(tag)=>{
    const color = colorMap[tag];
    return color||'#A99499';
}


export const getUserLevelBG=(level)=>{
    const base = '/static/images/';
    if(level>42)
        return base+'lv_use_bg6@3x.png'
    else 
        return base+`lv_use_bg${Math.trunc(((level||1)-0.5)/7)+1}@3x.png`;
}
export const getModelLevelBG=(level)=>{
    const base = '/static/images/';
    if(level>42)
        return base+'lv_modle_bg6@3x.png'
    else 
        return base+`lv_modle_bg${Math.trunc(((level||1)-0.5)/7)+1}@3x.png`;
}

export const getFuTimeString=(timestamp)=>{
   
        const m= moment(timestamp);
        const a = m.valueOf();
        const b= new Date().valueOf();
    
        if(b-a<100000)return '刚刚';
        if(b-a<1000*600)return '几分钟前';
        if(b-a<1000*1200)return '十分钟前';
        if(b-a<1000*1800)return '半小时前';
        if(b-a<1000*3600)return '一小时前';

        if(m.year()==moment().year()){
            return m.format('M月D日 HH:mm');
        }
        else return m.format('YYYY年M月D日 ')

}

export const checkWebMediaPermission= ()=>{
    var constraints = {
        video : true,
        audio : true 
    }

    return Promise.resolve().then(()=>{
        return navigator.mediaDevices&&navigator.mediaDevices.getUserMedia(constraints);
    })
}