import { useState } from 'react';
import { DefaultHeadUrl, HEART_BREAK_GRAY, HEART_BREAK_WHITE,HEART_ALL_GRAY, HEART_ALL_WHITE } from '../../../service/images';
import './review.css';

const reviews = [    
["好漂亮","声音好听","温柔","值得聊","身材好","有女人味","多才多艺","会聊天","有亲和力","很专业","真懂我","有感觉","就是喜欢","好可爱","很听话","理想女友"],
["没礼貌","老要打赏","不好看","没露脸","网络卡","态度差","没有笑","没话题","像骗人","没兴趣","胸大无脑","心不在焉","照骗","坐姿不正","很低俗","不听话","不专业","就是不爽"],
];

const Review = (props)=>{
    
    const [reviewTab,setReviewTab] =useState(0);
    const [reviewSelect,setReviewSelect] = useState({});
    const [reviewQueue]=useState([]);
    return (
        <div className='review-container' style={{minHeight:300}}>
            <div className='fx-box fx-fd-r fx-jc-c review-icon-container'>
                <img src={props.modelHeadUrl} style={{marginRight:-10,height:54,width:54,borderRadius:27,marginTop:-20}}/>
                <img src={props.userHeadUrl} style={{marginLeft:-10,height:54,width:54,borderRadius:27,marginTop:-20}}/>
            </div>
            <div className='fx-box fx-fd-c fx-jc-c fx-ai-c font-size15 font-w5 review-head'>
                <div>与{props.modelName}的聊天</div>
                <div className='review-detail'>{props.timeStr||props.liveContext?.duration}</div>
                <div className='review-detail review-consume fx-box fx-jc-c fx-ai-c'>
                    <div>消费:{props.timeConsume||props.liveContext?.otoConsume}金币</div>
                    <div>送出:{props.giftConsume||props.liveContext?.giftConsume}金币</div>
                </div>
            </div>
            <div className='fx-box fx-ai-c fx-jc-c fx-fd-r review-tab-container'>
                
                <div className={'review-tab-left font-size15'+(reviewTab==0?' review-tab-active':'')}  onClick={()=>{
                    setReviewTab(0);
                    reviewQueue.length=0;
                    setReviewSelect({})
                }
                }>
                    <img className='review-icon' src={reviewTab==1?HEART_ALL_GRAY:HEART_ALL_WHITE} />
                    <span>喜欢</span>
                </div>
                
                <div className={'review-tab-right font-size15'+(reviewTab==1?' review-tab-active':'')} onClick={()=>{
                    setReviewTab(1);
                    reviewQueue.length=0;
                    setReviewSelect({})
                }
                }>
                    <img className='review-icon' src={reviewTab==1?HEART_BREAK_WHITE:HEART_BREAK_GRAY} />
                    <span>无感</span>
                </div>
            </div>

            <div className="review-list">

            {
                reviews[reviewTab].map((e,idx)=>{
                    return (
                        <div key={'i_'+idx} className={"font-size10 review-list-item "+(reviewSelect[idx]?'active':'inactive')}
                        onClick={()=>{
                            reviewSelect[idx]=!reviewSelect[idx];
                            if(reviewSelect[idx]==true){
                                reviewQueue.push(idx);
                                if(reviewQueue.length>3){
                                    const uidx=reviewQueue[0];
                                    reviewSelect[uidx]=false;
                                    reviewQueue.splice(0,1);
                                }
                            }
                            else {
                                const iidx=reviewQueue.indexOf(idx);
                                if(iidx>=0)reviewQueue.splice(iidx,1);
                            }
                            console.log(reviewQueue);
                            setReviewSelect(Object.assign({},reviewSelect));
                        }}
                        >
                            {e}
                        </div>
                    )
                })
            }
            </div>
            <div style={{marginTop:'auto'}} className='fx-box fx-fd-r fx-jc-c fx-ai-c'>
                <div className={'font-size15 review-submit fx-box fx-jc-c fx-ai-c font-color2'+(reviewQueue.length>0?' active':' inactive')} onClick = {
                    ()=>{
                        const r = [];
                        Object.keys(reviewSelect).forEach((k)=>{
                            if(reviewSelect[k]==true)r.push(reviews[reviewTab][k]);
                        })
                        props.onSubmit((reviewTab+1),r.join('|'));
                    }
                }>提交评价</div>
            </div>
        </div>
    )
}

export default Review