import React, { useEffect ,useState} from 'react';
import {Image, Toast} from 'react-vant';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import './BlackList.css';
const BlackList=()=>{
    //   let blist=[
    //     {
    //         nickName:'张张',
    //         userId:'1010011',
    //         avatar:'/static/images/avatar-01.png',
    //     },
    //     {
    //         nickName:'牛牛',
    //         userId:'1010022',
    //         avatar:'/static/images/avatar-02.png',
    //     },
    //     {
    //         nickName:'vivian',
    //         userId:'1010044',
    //         avatar:'/static/images/avatar-03.png',
    //     },
    //     {
    //         nickName:'GG',
    //         userId:'1012022',
    //         avatar:'/static/images/avatar-02.png',
    //     },
    //     {
    //         nickName:'lili',
    //         userId:'10104',
    //         avatar:'/static/images/avatar-01.png',
    //     }
    //   ]
    const [blist,setList] = useState(null);
    useEffect(()=>{
        if(!blist){
            service.banList(0).then(data=>{
                if(data.code==0)
                setList(data.modelMapList);
            })
            return;
        }
    },[setList])
      return (
        <div className="black-page hide-x">
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'黑名单'}></Navigation>
           <div className='full-r-w fx-box fx-fd-c fx-ai-c  layout-mt60'>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='blackItem fx-box fx-jc-sb fx-ai-c'>
                        <div className='fx-box'>
                           <Image width={'0.41rem'} height={'0.41rem'} round src={item.headUrl}/>
  
                           <div className='black-userid fx-box fx-fd-c  fx-jc-sb'>
                              <div className='font-size15'>{item.nickName}</div>
                              <div className='font-size12'>ID:{item.userId}</div>
                           </div>
                        </div>
  
                        <div className='black-remove btn-bg1 font-color3 font-size13' onClick={
                            ()=>{
                                service.unban(item.userId).then(data=>{
                                    if(data.code==0){
                                        Toast.info('移除 '+item.nickName+' 完成');
                                        service.banList(0).then(data=>{
                                            if(data.code==0)setList(data.modelMapList);
                                        })
                                    }
                                })
                            }
                        }>
                            移除
                        </div>
                    </div>)
                    })
                 }
           </div>
          
        </div>
      );
}

export default BlackList;