import React from 'react';
import UserMsgItem from '../../components/user-msg-item/user-msg-item';
import Navigation from '../../components/navigation/navigation';
import './UserRate.css';
const UserRate=function(props){
      let userrates=[
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-02.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-03.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放','身材好'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-02.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP',
            level:23
        },
        {
            avatar:'/static/images/avatar-03.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP-01',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放','身材好'],
            userName:'LSP-02',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-02.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP',
            level:23
        },
        {
            avatar:'/static/images/avatar-03.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP-01',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放','身材好'],
            userName:'LSP-02',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-02.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP',
            level:23
        },
        {
            avatar:'/static/images/avatar-03.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP-01',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放','身材好'],
            userName:'LSP-02',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放'],
            userName:'唐长老',
            level:23
        },
        {
            avatar:'/static/images/avatar-02.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP',
            level:23
        },
        {
            avatar:'/static/images/avatar-03.png',
            tags:['美丽动人','热情奔放'],
            userName:'LSP-01',
            level:23
        },
        {
            avatar:'/static/images/avatar-01.png',
            tags:['美丽动人','热情奔放','身材好'],
            userName:'LSP-02',
            level:23
        }
      ]
      return (
        <div className='full-r-w u-rate-page'>
             <Navigation height={'0.5rem'} title={'评价详情'}></Navigation>
             <div className='user-rates'>
                <UserMsgItem msgs={userrates}/>
             </div>
        </div>
      );
}
export default UserRate;