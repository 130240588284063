const InitialState = {
    tabIndex:0
}

export const tabReducer = (state=InitialState,action)=>{
    switch(action.type){
        case 'TAB_SET':
            return Object.assign({},state,{tabIndex:action.payload})
        default:
            return state;
    }
}