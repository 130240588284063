
import { useEffect, useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/nav-bar/nav-bar";

import * as service from '../../service';
import './Circle.css';
const CircleTabs = [
    {
        title:'FU圈',
        id:1
    },
    {
        title:'我的圈',
        id:2
    }
]

const CircleHome = (props)=>{
    const listRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    console.log('%c location is :%o','color:green',location);
    let defaultTab=0;
    if(location.pathname&&location.pathname.endsWith('mycircle')){
        defaultTab=1;
    }
    const [dataList , setDataList] = useState();
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const [nextPage,setNextPage] = useState(1);
    const [pageCount,setPageCount] = useState(1);
    
    useEffect(()=>{
        if(!dataList){
            service.circleQuery(currentTab+1,nextPage).then(data=>{
                console.log(data);
                if(data.code==21){
                    navigate('/login',{replace:true})
                }
                else {
                    setDataList(data.circleMap)
                    setPageCount(data.totalPage);
                    setNextPage(nextPage+1);
                }
                
            })
        }
        const list = listRef.current;
        let timer,loading;

        const handleScroll = ev => {
        
            const scrollTop = document.getElementById('list').scrollTop;
            const screenHeight = window.screen.height;
            const offsetHeight = list.offsetHeight;


            if (scrollTop >= (offsetHeight - screenHeight * 1.5)) {

                clearTimeout(timer);
                if(loading){
                    //is loading more, do not repeat.
                    return;
                }
                if(nextPage>pageCount)return;

                timer = setTimeout(() => {
                    loading = true;    
                    
                    service.circleQuery(currentTab+1,nextPage).then(data=>{

                        setDataList(old => [ ...old,
                            ...data?.circleMap
                        ]);
                        setNextPage(nextPage+1);
                    }).finally(()=>{
                        loading=false;
                    })
                    
                }, 10);
            }
        };

        list.addEventListener('touchmove', handleScroll);
        list.addEventListener('wheel', handleScroll);

        return () => {
          clearTimeout(timer);
          list.removeEventListener('touchmove', handleScroll);
          list.removeEventListener('wheel', handleScroll);
        };

    },[dataList,setDataList,currentTab,setCurrentTab,nextPage,setNextPage])
    return (
        <>
        <NavBar
            style={{backgroundColor: 'white',
                position: 'fixed',
                top:10,
                zIndex:1000
            }}
            activeTab={currentTab}
            activity={CircleTabs} reload = {(tab)=>{
            // console.log('%c current idx:%d','color:green',tab);
            setCurrentTab(tab);
            setDataList(null);
            setNextPage(1);
        }}/>
        <div className="circle-container fx-box fx-1 fx-fd-c" id='list' ref={listRef}>
            
            <div className="fx-1 fx-box fx-fd-c font-size13 fx-ai-c">
                {
                    dataList?.map((e,idx)=>{
                        return (
                            <div key={'k_'+idx} className="fx-box fx-ai-c circle-item" onClick={()=>{
                                if(!e?.isPurchase)
                                    navigate('/fupurchase/'+e?.id,{state:{circleInfo:e}})
                                else 
                                    navigate('/fu/'+e?.id,{state:{circleInfo:e}})
                            }}>
                                
                                <img src={e.headUrl} className='circle-item-head'/>
                                <div className="fx-box fx-fd-c fx-1">
                                    <div>
                                        {e.circlename}
                                    </div>
                                    <div className="font-size10">
                                        精选:{e.contentCount}
                                    </div>
                                </div>
                                <div className="fx-box fx-fd-c fx-1 fx-ai-e" style={{padding:'0.1rem'}}>
                                    {
                                        e.isUpdate?
                                        <div className="circle-is-update">
                                            有更新
                                        </div>:
                                        <div>
                                            {' '}
                                        </div>
                                    }
                                    
                                    <div className="font-size10">
                                        <strong>{e.joinedUserCount}</strong> 人加入
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        </>
    )
}

export default CircleHome;