import './emoji-dialog.css'
import {EmojiCodePoints} from '../../service/emoji';
export const EmojiDialog =(props)=>{
    return (
        <div className='emoji-mask'  onClick={()=>{
            props.onClose();
        }}>
            
            <div onClick={(e)=>{
                 e.nativeEvent.stopPropagation();
                 e.stopPropagation();
            }} className='emoji-container theme-font layout-rel' style={{...props.style}}>
                <div className='fx-box fx-fd-r fx-ai-c' style={{paddingTop:8,paddingBottom:8}}>
                    <div className='fx-1 emoji-input-container fx-box fx-jc-c fx-ai-c'>
                        <input className="theme-font font-size13" placeholder="输入新消息" value={props.message} onChange={(e)=>{
                            props.onChange(e.target.value);
                        }}/>
                    </div>
                    <div className='fx-box fx-jc-c fx-ai-c' onClick={
                        ()=>{
                            props.onSend();
                        }
                    }>
                        <img src='/static/images/send.png' className='emoji-send-btn'/>
                    </div>
                </div>
                <div className='fx-box fx-wp'>
                {
                    EmojiCodePoints.map((cp,idx)=>{
                        return (
                            <div key={'emoji_k_'+idx} className='font-color3 font-size28 emoji-icon' 
                            onClick={()=>{
                                props.onClick(cp);
                            }}
                            >
                                <span>{String.fromCodePoint(cp)}</span>
                            </div>
                        )
                    })
                }   
                </div>
                
            </div>
        </div>
    )
    
}